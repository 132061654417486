import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { InputControl, MultipleOptionsControl, Option, SelectControl, TextareaControl } from "../../components/formControls";
import { Icon } from "../../components/icon";
import ProgressBar from "../../components/progressBar";
import { HeaderTitle } from "../../components/titles";
import useAPI from "../../connection/useAPI";
import { useAuth2 } from "../../functions/auth2Context";
import useLocalStorage from "../../functions/useLocalStorage";
import AppStructure from "../../structures/appStructure";

const ChristianTestimonyForm = () => {
    const [ itemsCompleted, setItemsCompleted ] = useState(0);
    const [ loading, setLoading ] = useState(false);
    const [ formLoading, setFormLoading ] = useState(false);
    const [ christianTestimonyDataId, setChristianTestimonyDataId ] = useState(null);
    const [ inputDisabled, setInputDisabled ] = useState(false);
    const [ presidentData, setPresidentData ] = useLocalStorage("CHRISTIANTESTIMONYFORM_PRESIDENTDATA",{});
    const [ churchData, setChurchData ] = useLocalStorage("CHRISTIANTESTIMONYFORM_CHURCHDATA",{});
    const [ evangelismData, setEvangelismData ] = useLocalStorage("CHRISTIANTESTIMONYFORM_EVANGELISMDATA",{});
    const [ socialActionData, setSocialActionData ] = useLocalStorage("CHRISTIANTESTIMONYFORM_SOCIALACTIONDATA",{});
    const [ missionaryData, setMissionaryData ] = useLocalStorage("CHRISTIANTESTIMONYFORM_MISSIONARYDATA",{missionariesPosts:[]});
    const [ immigrationData, setImmigrationData ] = useLocalStorage("CHRISTIANTESTIMONYFORM_IMMIGRATIONDATA",{});
    const [ enviromentData, setEnviromentData ] = useLocalStorage("CHRISTIANTESTIMONYFORM_ENVIROMENTDATA",{actions:[]});
    const { currentUser } = useAuth2();
    const { christianTestimonyData } = useAPI();
    const { churchId } = useParams();

    const formObjects = [presidentData,churchData,evangelismData,socialActionData,missionaryData,immigrationData,enviromentData];
    const valueSetters = [setPresidentData,setChurchData,setEvangelismData,setSocialActionData,setMissionaryData,setImmigrationData,setEnviromentData];
    const formFields = ["presidentData","churchData","evangelismData","socialActionData","missionaryData","immigrationData","enviromentData"];

    useEffect(() => {
        setLoading(true);

        let cId = currentUser.uid;
        //setInputDisabled(true);
        if(churchId){
            cId = churchId;
            setInputDisabled(true);
        }
        const promises = [christianTestimonyData.get(cId)];

        Promise.all(promises).then((response) => {
            fillServerData(response[0]);

            setLoading(false);
        });
    },[]);

    function fillServerData(christianTestimonyObject){
        if(christianTestimonyObject.id){
            let serverItemsCompleted = 0;

            formFields.forEach((field,fieldIndex) => {
                const fieldObject = JSON.parse(christianTestimonyObject[field]);

                if(fieldObject){
                    valueSetters[fieldIndex](fieldObject);
                    serverItemsCompleted++;
                }
            });

            /*const presidentResponse = JSON.parse(christianTestimonyObject.presidentData);
            const adultStudyProgramResponse = JSON.parse(christianTestimonyObject.adultStudyProgram);
            const adminResponse = JSON.parse(christianTestimonyObject.adminData);
            

            if(presidentResponse){
                setPresidentData(presidentResponse);
                serverItemsCompleted++;
            }
            if(attendanceResponse){
                setAttendanceData(attendanceResponse);
                serverItemsCompleted++;
            }
            if(adminResponse){
                setAdminData(adminResponse);
                serverItemsCompleted++;
            }*/

            setItemsCompleted(serverItemsCompleted);
            setChristianTestimonyDataId(christianTestimonyObject.id);
        }
    }

    function handleChangeField(e){
        const formIndex = e.currentTarget.getAttribute("form-index");
        let fieldValue = e.currentTarget.value;
        if(typeof fieldValue === 'string'){
            fieldValue = fieldValue.replace(/(\r\n|\n|\r|")/gi,'');
        }
        const field = e.currentTarget.getAttribute("field");
        const object = JSON.parse(JSON.stringify(formObjects[formIndex]));
        object[field] = fieldValue;

        valueSetters[formIndex](object);
    }

    function handleSubmit(e){
        e.preventDefault();
        setFormLoading(true);

        const formIndex = e.currentTarget.getAttribute("form-index");

        // console.log(currentUser.uid,
        //     currentUser.uid,
        //     programDataId,
        //     formFields[formIndex],
        //     JSON.stringify(formObjects[formIndex]));
        christianTestimonyData.save(currentUser.uid,
                        currentUser.uid,
                        christianTestimonyDataId,
                        formFields[formIndex],
                        JSON.stringify(formObjects[formIndex])
        ).then((response) => {
            fillServerData(response);

            setFormLoading(false);
        });
    }

    return(
        <AppStructure>
            <HeaderTitle title="Formulario de Testimonio Cristiano" />
            <ProgressBar itemsToEvaluate={7} itemsCompleted={itemsCompleted} />

            {(loading) ? <p>Cargando Datos...</p> :
                <Fragment>
                    <form form-index="0" onSubmit={handleSubmit}>
                        <div className="box">
                            <div className="block">
                                <InputControl
                                    type="text"
                                    label="Nombre del presidente local del Área de Testimonio Cristiano"
                                    placeholder="Nombre completo"
                                    iconLeft="person"
                                    form-index="0"
                                    field="name"
                                    disabled={inputDisabled} value={presidentData.name}
                                    onChange={handleChangeField}
                                    required
                                />
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <InputControl
                                        type="text"
                                        label="Teléfono (o Celular)"
                                        placeholder="Número de Teléfono"
                                        iconLeft="smartphone"
                                        form-index="0"
                                        field="phone"
                                        disabled={inputDisabled} value={presidentData.phone}
                                        onChange={handleChangeField}
                                    />
                                </div>
                                <div className="column">
                                    <InputControl
                                        type="email"
                                        label="Correo Electrónico"
                                        placeholder="Correo electrónico"
                                        iconLeft="mail"
                                        form-index="0"
                                        field="email"
                                        disabled={inputDisabled} value={presidentData.email}
                                        onChange={handleChangeField}
                                    />
                                </div>
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <InputControl
                                        label="¿Cuántas células de personas nuevas preside este funcionario?"
                                        type="number"
                                        placeholder="Cantidad"
                                        form-index="0"
                                        field="newPeopleGroups"
                                        disabled={inputDisabled} value={presidentData.newPeopleGroups}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                <div className="column">
                                    <InputControl
                                        label="Total de personas que asisten"
                                        type="number"
                                        placeholder="Cantidad"
                                        form-index="0"
                                        field="newPeopleGroupsAttendance"
                                        disabled={inputDisabled} value={presidentData.newPeopleGroupsAttendance}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="block">
                                {!inputDisabled && <button type="submit" className="button is-link is-fullwidth" disabled={formLoading}>
                                    <Icon name="save" />
                                    <span>Guardar</span>
                                </button>}
                            </div>
                        </div>
                    </form>

                    <HeaderTitle subtitle="Mi Iglesia" />
                    <form form-index="1" onSubmit={handleSubmit}>
                        <div className="box">
                            <div className="block columns">
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        label="Número de células de Crecimiento"
                                        placeholder="Cantidad"
                                        form-index="1"
                                        field="cellAmount"
                                        disabled={inputDisabled} value={churchData.cellAmount}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                {(churchData.cellAmount === "0" || churchData.cellAmount === "") ?
                                    <div className="column">
                                        <TextareaControl
                                            placeholder="Si es 0 explique la razón:"
                                            form-index="1"
                                            field="noCellExplain"
                                            disabled={inputDisabled} value={churchData.noCellExplain}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                :
                                    <div className="column">
                                        <InputControl
                                            type="number"
                                            label="Total de asistentes"
                                            placeholder="Cantidad"
                                            form-index="1"
                                            field="cellAttendance"
                                            disabled={inputDisabled} value={churchData.cellAttendance}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                }
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        label="Número de puntos de predicación"
                                        placeholder="Cantidad"
                                        form-index="1"
                                        field="preachingPointAmount"
                                        disabled={inputDisabled} value={churchData.preachingPointAmount}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                {(churchData.preachingPointAmount === "0" || churchData.preachingPointAmount === "") ?
                                    <div className="column">
                                        <TextareaControl
                                            placeholder="Si es 0 explique la razón:"
                                            form-index="1"
                                            field="noPreachingPointExplain"
                                            disabled={inputDisabled} value={churchData.noPreachingPointExplain}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                :
                                    <div className="column">
                                        <InputControl
                                            type="number"
                                            label="Total de asistentes"
                                            placeholder="Cantidad"
                                            form-index="1"
                                            field="preachingPointAttendance"
                                            disabled={inputDisabled} value={churchData.preachingPointAttendance}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                }
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        label="Número de iglesias en formación"
                                        placeholder="Cantidad"
                                        form-index="1"
                                        field="growingChurchAmount"
                                        disabled={inputDisabled} value={churchData.growingChurchAmount}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                {(churchData.growingChurchAmount === "0" || churchData.growingChurchAmount === "") ?
                                    <div className="column">
                                        <TextareaControl
                                            placeholder="Si es 0 explique la razón:"
                                            form-index="1"
                                            field="noGrowingChurchExplain"
                                            disabled={inputDisabled} value={churchData.noGrowingChurchExplain}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                :
                                    <div className="column">
                                        <InputControl
                                            type="number"
                                            label="Total de asistentes"
                                            placeholder="Cantidad"
                                            form-index="1"
                                            field="growingChurchAttendance"
                                            disabled={inputDisabled} value={churchData.growingChurchAttendance}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                }
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <SelectControl
                                        label="¿Cómo se clasifica a su Iglesia dentro de la IMMAR?"
                                        placeholder="Seleccionar"
                                        options={[{value:"1",label:"Punto de Predicación (PP)"},{value:"2",label:"Iglesia en Formación (IF)"},{value:"3",label:"Iglesia Establecida (IE)"}]}
                                        form-index="1"
                                        field="type"
                                        disabled={inputDisabled} value={churchData.type}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                {(churchData.type === "1") &&
                                    <div className="column">
                                        <TextareaControl
                                            placeholder="Mencione las causas que le impiden convertirse en Iglesia en Formación (IF)"
                                            form-index="1"
                                            field="typeExplain"
                                            disabled={inputDisabled} value={churchData.typeExplain}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                }
                                {(churchData.type === "2") &&
                                    <div className="column">
                                        <TextareaControl
                                            placeholder="Mencione las causas que le impiden convertirse en Iglesia Establecida (IE)"
                                            form-index="1"
                                            field="typeExplain"
                                            disabled={inputDisabled} value={churchData.typeExplain}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                }
                                {(churchData.type === "3") &&
                                    <div className="column">
                                        <TextareaControl
                                            placeholder="Mencione cuáles son sus planes para abrir nuevos puntos de predicación o misiones"
                                            form-index="1"
                                            field="typeExplain"
                                            disabled={inputDisabled} value={churchData.typeExplain}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                }
                            </div>
                            <div className="block">
                                <InputControl
                                    type="number"
                                    label="De acuerdo con su organigrama, sin incluirlo a usted ¿cuántos funcionarios del Área de Testimonio Cristiano hay en su iglesia?"
                                    placeholder="Cantidad"
                                    form-index="1"
                                    field="officials"
                                    disabled={inputDisabled} value={churchData.officials}
                                    onChange={handleChangeField}
                                    required
                                />
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        label="Sin incluir al presidente de área ¿Cuántos funcionarios de ESTA ÁREA abrieron su Célula de Crecimiento durante el año?"
                                        placeholder="Cantidad"
                                        form-index="1"
                                        field="officialsCells"
                                        disabled={inputDisabled} value={churchData.officialsCells}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                {(churchData.officialsCells !== "0" && churchData.officialsCells) &&
                                    <div className="column">
                                        <InputControl
                                            type="number"
                                            label="En total ¿Cuántas personas asistieron a éstas células nuevas?"
                                            placeholder="Cantidad"
                                            form-index="1"
                                            field="officialsCellsAttendance"
                                            disabled={inputDisabled} value={churchData.officialsCellsAttendance}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                }
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        label="¿Cuántas células tiene en total su iglesia, incluyendo las nuevas?"
                                        placeholder="Cantidad"
                                        form-index="1"
                                        field="totalCells"
                                        disabled={inputDisabled} value={churchData.totalCells}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                {(churchData.totalCells !== "0" && churchData.totalCells) &&
                                    <div className="column">
                                        <InputControl
                                            type="number"
                                            label="Cantidad de personas en todas las células de la Iglesia"
                                            placeholder="Cantidad"
                                            form-index="1"
                                            field="totalCellsAttendance"
                                            disabled={inputDisabled} value={churchData.totalCellsAttendance}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                }
                            </div>
                            <div className="block">
                                <label className="label">¿Cuántos funcionarios de su iglesia local fueron capacitados mediante los cursos del Área de Testimonio Cristiano en Canvas?</label>
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        label="En “La Mayordomía del Metodista en el cuidado del Medio ambiente”"
                                        placeholder="Cantidad"
                                        form-index="1"
                                        field="stewardshipTraining"
                                        disabled={inputDisabled} value={churchData.stewardshipTraining}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        label="En el Curso de Capacitación PEPPIL"
                                        placeholder="Cantidad"
                                        form-index="1"
                                        field="pepilTraining"
                                        disabled={inputDisabled} value={churchData.pepilTraining}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        label="En el Curso de capacitación Programa de Impulso Misionero"
                                        placeholder="Cantidad"
                                        form-index="1"
                                        field="missionaryTraining"
                                        disabled={inputDisabled} value={churchData.missionaryTraining}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="block">
                                {!inputDisabled && <button type="submit" className="button is-link is-fullwidth" disabled={formLoading}>
                                    <Icon name="save" />
                                    <span>Guardar</span>
                                </button>}
                            </div>
                        </div>
                    </form>

                    <HeaderTitle subtitle="Evangelización y discipulado" />
                    <form form-index="2" onSubmit={handleSubmit}>
                        <div className="box">
                            <div className="block">
                                <InputControl
                                    type="text"
                                    label="Nombre del presidente local de Evangelizacion y Discipulado"
                                    placeholder="Nombre completo"
                                    iconLeft="person"
                                    form-index="2"
                                    field="presidentName"
                                    disabled={inputDisabled} value={evangelismData.presidentName}
                                    onChange={handleChangeField}
                                    required
                                />
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <InputControl
                                        type="text"
                                        label="Teléfono (o Celular)"
                                        placeholder="Número de Teléfono"
                                        iconLeft="smartphone"
                                        form-index="2"
                                        field="presidentPhone"
                                        disabled={inputDisabled} value={evangelismData.presidentPhone}
                                        onChange={handleChangeField}
                                    />
                                </div>
                                <div className="column">
                                    <InputControl
                                        type="email"
                                        label="Correo Electrónico"
                                        placeholder="Correo electrónico"
                                        iconLeft="mail"
                                        form-index="2"
                                        field="presidentEmail"
                                        disabled={inputDisabled} value={evangelismData.presidentEmail}
                                        onChange={handleChangeField}
                                    />
                                </div>
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <SelectControl
                                        label="¿Su iglesia cuenta con un programa de evangelización?"
                                        placeholder="Seleccionar"
                                        options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                        form-index="2"
                                        field="programExists"
                                        disabled={inputDisabled} value={evangelismData.programExists}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                {(evangelismData.programExists === "1") &&
                                    <div className="column">
                                        <TextareaControl
                                            placeholder="Describa"
                                            form-index="2"
                                            field="programExistsExplain"
                                            disabled={inputDisabled} value={evangelismData.programExistsExplain}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                }
                                {(evangelismData.programExists === "0") &&
                                    <div className="column">
                                        <TextareaControl
                                            placeholder="¿Por qué no?"
                                            form-index="2"
                                            field="programExistsExplain"
                                            disabled={inputDisabled} value={evangelismData.programExistsExplain}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                }
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <SelectControl
                                        label="¿Su iglesia cuenta con un curso de probandos para recepción de miembros en plena comunión?"
                                        placeholder="Seleccionar"
                                        options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                        form-index="2"
                                        field="fullMembersProgramExistis"
                                        disabled={inputDisabled} value={evangelismData.fullMembersProgramExistis}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                {(evangelismData.fullMembersProgramExistis === "0") &&
                                    <div className="column">
                                        <TextareaControl
                                            placeholder="¿Por qué no?"
                                            form-index="2"
                                            field="fullMembersProgramExistisExplain"
                                            disabled={inputDisabled} value={evangelismData.fullMembersProgramExistisExplain}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                }
                            </div>
                            {(evangelismData.fullMembersProgramExistis === "1") &&
                                <div className="block columns">
                                    <div className="column">
                                        <TextareaControl
                                            placeholder="Describa el procedimiento de implementación:"
                                            form-index="2"
                                            field="fullMembersProgramExistisExplain"
                                            disabled={inputDisabled} value={evangelismData.fullMembersProgramExistisExplain}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                    <div className="column">
                                        <InputControl
                                            type="text"
                                            label="¿Cuál es el material de estudio que usa?"
                                            placeholder="Material"
                                            form-index="2"
                                            field="fullMembersProgramMaterial"
                                            disabled={inputDisabled} value={evangelismData.fullMembersProgramMaterial}
                                            onChange={handleChangeField}
                                            required
                                        />
                                        <InputControl
                                            type="number"
                                            label="¿Cuántos miembros en Plena Comunión se dieron de alta este año?"
                                            placeholder="Cantidad"
                                            form-index="2"
                                            field="newFullMembers"
                                            disabled={inputDisabled} value={evangelismData.newFullMembers}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                </div>
                            }
                            <div className="block columns">
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        label="¿Cuántos bautismos de personas de 0 a 12 años se realizaron este año?"
                                        placeholder="Cantidad"
                                        form-index="2"
                                        field="newChildrenBaptisms"
                                        disabled={inputDisabled} value={evangelismData.newChildrenBaptisms}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        label="¿Cuántos bautismos de personas mayores de 12 años se realizaron este año?"
                                        placeholder="Cantidad"
                                        form-index="2"
                                        field="newAdultsBaptisms"
                                        disabled={inputDisabled} value={evangelismData.newAdultsBaptisms}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <SelectControl
                                        label="¿Su iglesia implementó el Programa de Evangelización Permanente para Iglesias Locales: PEPPIL?"
                                        placeholder="Seleccionar"
                                        options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                        form-index="2"
                                        field="peppilExistis"
                                        disabled={inputDisabled} value={evangelismData.peppilExistis}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                {(evangelismData.peppilExistis === "0") &&
                                    <div className="column">
                                        <TextareaControl
                                            placeholder="¿Por qué no?"
                                            form-index="2"
                                            field="peppilExistisExplain"
                                            disabled={inputDisabled} value={evangelismData.peppilExistisExplain}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                }
                            </div>
                            {(evangelismData.peppilExistis === "1") &&
                                <div className="block columns">
                                    <div className="column">
                                        <TextareaControl
                                            placeholder="Describa el procedimiento de implementación:"
                                            form-index="2"
                                            field="peppilExistisExplain"
                                            disabled={inputDisabled} value={evangelismData.peppilExistisExplain}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                    <div className="column">
                                        <InputControl
                                            type="text"
                                            label="¿Cuál es el material de estudio que usa?"
                                            placeholder="Material"
                                            form-index="2"
                                            field="peppilMaterial"
                                            disabled={inputDisabled} value={evangelismData.peppilMaterial}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                </div>
                            }
                            <div className="block columns">
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        label="¿Cuántas personas nuevas contactaron a través de las Acciones Semanales de Evangelización?"
                                        placeholder="Cantidad"
                                        form-index="2"
                                        field="newPeopleContacted"
                                        disabled={inputDisabled} value={evangelismData.newPeopleContacted}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        label="¿Cuántas nuevas decisiones por Cristo obtuvieron a través de estas acciones?"
                                        placeholder="Cantidad"
                                        form-index="2"
                                        field="newPeopleForChrist"
                                        disabled={inputDisabled} value={evangelismData.newPeopleForChrist}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="block">
                                <label className="label">En los siguentes cuadros anote cuántos de esos nuevos convertidos están en consolidación en los cursos de:</label>
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        label="Discipulado inmediato"
                                        placeholder="Cantidad"
                                        form-index="2"
                                        field="consolidationCourseI"
                                        disabled={inputDisabled} value={evangelismData.consolidationCourseI}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        label="Discipulado intensivo"
                                        placeholder="Cantidad"
                                        form-index="2"
                                        field="consolidationCourseII"
                                        disabled={inputDisabled} value={evangelismData.consolidationCourseII}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        label="Discipulado sistemático"
                                        placeholder="Cantidad"
                                        form-index="2"
                                        field="consolidationCourseIII"
                                        disabled={inputDisabled} value={evangelismData.consolidationCourseIII}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="block">
                                <SelectControl
                                    label="¿Efectuó el Retiro de Consolidación?"
                                    placeholder="Seleccionar"
                                    options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                    form-index="2"
                                    field="consolidationRetreat"
                                    disabled={inputDisabled} value={evangelismData.consolidationRetreat}
                                    onChange={handleChangeField}
                                    required
                                />
                            </div>
                            <div className="block">
                                {!inputDisabled && <button type="submit" className="button is-link is-fullwidth" disabled={formLoading}>
                                    <Icon name="save" />
                                    <span>Guardar</span>
                                </button>}
                            </div>
                        </div>
                    </form>

                    <HeaderTitle subtitle="Acción Social" />
                    <form form-index="3" onSubmit={handleSubmit}>
                        <div className="box">
                            <div className="block">
                                <InputControl
                                    type="text"
                                    label="Nombre del presidente local de Acción Social"
                                    placeholder="Nombre completo"
                                    iconLeft="person"
                                    form-index="3"
                                    field="presidentName"
                                    disabled={inputDisabled} value={socialActionData.presidentName}
                                    onChange={handleChangeField}
                                    required
                                />
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <InputControl
                                        type="text"
                                        label="Teléfono (o Celular)"
                                        placeholder="Número de Teléfono"
                                        iconLeft="smartphone"
                                        form-index="3"
                                        field="presidentPhone"
                                        disabled={inputDisabled} value={socialActionData.presidentPhone}
                                        onChange={handleChangeField}
                                    />
                                </div>
                                <div className="column">
                                    <InputControl
                                        type="email"
                                        label="Correo Electrónico"
                                        placeholder="Correo electrónico"
                                        iconLeft="mail"
                                        form-index="3"
                                        field="presidentEmail"
                                        disabled={inputDisabled} value={socialActionData.presidentEmail}
                                        onChange={handleChangeField}
                                    />
                                </div>
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <SelectControl
                                        label="¿Su Iglesia implementó el Programa Permanente de Acción Social (PPAS)?"
                                        placeholder="Seleccionar"
                                        options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                        form-index="3"
                                        field="ppasExists"
                                        disabled={inputDisabled} value={socialActionData.ppasExists}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                {(socialActionData.ppasExists === "1") &&
                                    <div className="column">
                                        <TextareaControl
                                            placeholder="Si es SI, describa el procedimiento de implementación:"
                                            form-index="3"
                                            field="ppasExplain"
                                            disabled={inputDisabled} value={socialActionData.ppasExplain}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>    
                                }
                                {(socialActionData.ppasExists === "0") &&
                                    <div className="column">
                                        <TextareaControl
                                            placeholder="No: ¿Por qué no?"
                                            form-index="3"
                                            field="ppasExplain"
                                            disabled={inputDisabled} value={socialActionData.ppasExplain}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>    
                                }
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <SelectControl
                                        label="¿Realiza en su Iglesia Programas y/o acciones de Acción Social?"
                                        placeholder="Seleccionar"
                                        options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                        form-index="3"
                                        field="programsExists"
                                        disabled={inputDisabled} value={socialActionData.programsExists}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                {(socialActionData.programsExists === "1") &&
                                    <div className="column">
                                        <TextareaControl
                                            placeholder="Si es SI, describa el procedimiento de implementación:"
                                            form-index="3"
                                            field="programsExplain"
                                            disabled={inputDisabled} value={socialActionData.programsExplain}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>    
                                }
                                {(socialActionData.programsExists === "0") &&
                                    <div className="column">
                                        <TextareaControl
                                            placeholder="No: ¿Por qué no?"
                                            form-index="3"
                                            field="programsExplain"
                                            disabled={inputDisabled} value={socialActionData.programsExplain}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>    
                                }
                            </div>
                            <div className="block">
                                <SelectControl
                                    label="¿Enfatiza el evangelio en sus actividades de Acción Social?"
                                    placeholder="Seleccionar"
                                    options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                    form-index="3"
                                    field="activityEmphasis"
                                    disabled={inputDisabled} value={socialActionData.activityEmphasis}
                                    onChange={handleChangeField}
                                    required
                                />
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        label="¿A cuántas personas se les presentó el evangelio?"
                                        placeholder="Cantidad"
                                        form-index="3"
                                        field="gospelPresentedPeople"
                                        disabled={inputDisabled} value={socialActionData.gospelPresentedPeople}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        label="¿Cuántas personas alcanzadas por medio de la Acción Social tienen un seguimiento discipular?"
                                        placeholder="Cantidad"
                                        form-index="3"
                                        field="savedPeopleFollowUp"
                                        disabled={inputDisabled} value={socialActionData.savedPeopleFollowUp}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        label="Cantidad de personas en las células"
                                        placeholder="Cantidad"
                                        form-index="3"
                                        field="peopleCellsAttendance"
                                        disabled={inputDisabled} value={socialActionData.peopleCellsAttendance}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="block">
                                {!inputDisabled && <button type="submit" className="button is-link is-fullwidth" disabled={formLoading}>
                                    <Icon name="save" />
                                    <span>Guardar</span>
                                </button>}
                            </div>
                        </div>
                    </form>

                    <HeaderTitle subtitle="Comisión Misionera" />
                    <form form-index="4" onSubmit={handleSubmit}>
                        <div className="box">
                            <div className="block">
                                <InputControl
                                    type="text"
                                    label="Nombre del presidente local de Comisión Misionera"
                                    placeholder="Nombre completo"
                                    iconLeft="person"
                                    form-index="4"
                                    field="presidentName"
                                    disabled={inputDisabled} value={missionaryData.presidentName}
                                    onChange={handleChangeField}
                                    required
                                />
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <InputControl
                                        type="text"
                                        label="Teléfono (o Celular)"
                                        placeholder="Número de Teléfono"
                                        iconLeft="smartphone"
                                        form-index="4"
                                        field="presidentPhone"
                                        disabled={inputDisabled} value={missionaryData.presidentPhone}
                                        onChange={handleChangeField}
                                    />
                                </div>
                                <div className="column">
                                    <InputControl
                                        type="email"
                                        label="Correo Electrónico"
                                        placeholder="Correo electrónico"
                                        iconLeft="mail"
                                        form-index="4"
                                        field="presidentEmail"
                                        disabled={inputDisabled} value={missionaryData.presidentEmail}
                                        onChange={handleChangeField}
                                    />
                                </div>
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <SelectControl
                                        label="¿Su iglesia implementó el Programa permanente de Impulso Misionero: PPIM?"
                                        placeholder="Seleccionar"
                                        options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                        form-index="4"
                                        field="ppimExists"
                                        disabled={inputDisabled} value={missionaryData.ppimExists}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                {(missionaryData.ppimExists === "1") &&
                                    <div className="column">
                                        <TextareaControl
                                            placeholder="Si es SI, describa el procedimiento de implementación:"
                                            form-index="4"
                                            field="ppimExplain"
                                            disabled={inputDisabled} value={missionaryData.ppimExplain}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>    
                                }
                                {(socialActionData.ppimExists === "0") &&
                                    <div className="column">
                                        <TextareaControl
                                            placeholder="No: ¿Por qué no?"
                                            form-index="4"
                                            field="ppimExplain"
                                            disabled={inputDisabled} value={missionaryData.ppimExplain}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>    
                                }
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        label="¿Su iglesia local cuenta con misionero(s)? Especifique cuántos y el campo misionero en el que participan"
                                        placeholder="¿Cuántos?"
                                        form-index="4"
                                        field="missionaries"
                                        disabled={inputDisabled} value={missionaryData.missionaries}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                {(missionaryData.missionaries === "0") &&
                                    <div className="column">
                                        <TextareaControl
                                            placeholder="Si no tiene misioneros ¿Hay alguna razón?"
                                            form-index="4"
                                            field="missionariesExplain"
                                            disabled={inputDisabled} value={missionaryData.missionariesExplain}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                }
                            </div>
                            {(missionaryData.missionaries !== "0" && missionaryData.missionaries) &&
                                <Fragment>
                                    <div className="block columns">
                                        <div className="column">
                                            <TextareaControl
                                                placeholder="Campo(s) misionero(s) o Punto(s) de Predicación (Separados por comas [,])"
                                                form-index="4"
                                                field="missionFields"
                                                disabled={inputDisabled} value={missionaryData.missionFields}
                                                onChange={handleChangeField}
                                                required
                                            />
                                        </div>
                                        <div className="column">
                                            <InputControl
                                                type="number"
                                                label="¿Cuántos de estos cumplen los requisitos disciplinarios (Artículo 637, Misioneros)?"
                                                placeholder="Cantidad"
                                                form-index="4"
                                                field="metRequirements"
                                                disabled={inputDisabled} value={missionaryData.metRequirements}
                                                onChange={handleChangeField}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="block">
                                        <MultipleOptionsControl
                                            description="Señale qué tipo de cargo tiene el misionero(a) según Art. 638 Nombramiento de Misioneros."
                                            columnWeight="4"
                                            form-index="4"
                                            field="missionariesPosts"
                                            disabled={inputDisabled} value={missionaryData.missionariesPosts}
                                            onChange={handleChangeField}
                                        >
                                            <Option>Predicador local de tiempo completo</Option>
                                            <Option>Predicador local de medio tiempo</Option>
                                            <Option>Otro</Option>
                                        </MultipleOptionsControl>
                                    </div>
                                    {(missionaryData.missionariesPosts[2]) &&
                                        <TextareaControl
                                            placeholder="Especifique los Cargos"
                                            form-index="4"
                                            field="missionariesExtraPosts"
                                            disabled={inputDisabled} value={missionaryData.missionariesExtraPosts}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    }
                                </Fragment>
                            }
                            <div className="block columns">
                                <div className="column">
                                    <SelectControl
                                        label="¿Hay algunos miembros de su iglesia preparándose para ser misionero en algún Seminario Metodista?"
                                        placeholder="Seleccionar"
                                        options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                        form-index="4"
                                        field="newMissionariesStuding"
                                        disabled={inputDisabled} value={missionaryData.newMissionariesStuding}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                {(missionaryData.newMissionariesStuding === "0") &&
                                    <div className="column">
                                        <TextareaControl
                                            placeholder="¿Por qué no?"
                                            field="newMissionariesStudingExplain"
                                            disabled={inputDisabled} value={missionaryData.newMissionariesStudingExplain}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                }
                                {(missionaryData.newMissionariesStuding === "1") &&
                                    <div className="column">
                                        <InputControl
                                            type="number"
                                            label="¿Cuántos están inscritos en el Seminario Dr. Gonzalo Báez Camargo?"
                                            placeholder="Cantidad"
                                            field="newMissionariesStudingInGBC"
                                            disabled={inputDisabled} value={missionaryData.newMissionariesStudingInGBC}
                                            onChange={handleChangeField}
                                            required
                                        />
                                        <InputControl
                                            type="number"
                                            label="¿Cuántos están inscritos en el Seminario Juan Wesley?"
                                            placeholder="Cantidad"
                                            field="newMissionariesStudingInJW"
                                            disabled={inputDisabled} value={missionaryData.newMissionariesStudingInJW}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                }
                            </div>
                            <div className="block">
                                {!inputDisabled && <button type="submit" className="button is-link is-fullwidth" disabled={formLoading}>
                                    <Icon name="save" />
                                    <span>Guardar</span>
                                </button>}
                            </div>
                        </div>
                    </form>

                    <HeaderTitle subtitle="Asuntos Migratorios" />
                    <form form-index="5" onSubmit={handleSubmit}>
                        <div className="box">
                            <div className="block">
                                <InputControl
                                    type="text"
                                    label="Nombre del presidente local de Asuntos Migratorios"
                                    placeholder="Nombre completo"
                                    iconLeft="person"
                                    form-index="5"
                                    field="presidentName"
                                    disabled={inputDisabled} value={immigrationData.presidentName}
                                    onChange={handleChangeField}
                                    required
                                />
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <InputControl
                                        type="text"
                                        label="Teléfono (o Celular)"
                                        placeholder="Número de Teléfono"
                                        iconLeft="smartphone"
                                        form-index="5"
                                        field="presidentPhone"
                                        disabled={inputDisabled} value={immigrationData.presidentPhone}
                                        onChange={handleChangeField}
                                    />
                                </div>
                                <div className="column">
                                    <InputControl
                                        type="email"
                                        label="Correo Electrónico"
                                        placeholder="Correo electrónico"
                                        iconLeft="mail"
                                        form-index="5"
                                        field="presidentEmail"
                                        disabled={inputDisabled} value={immigrationData.presidentEmail}
                                        onChange={handleChangeField}
                                    />
                                </div>
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <SelectControl
                                        label="¿Su iglesia cuenta con Programas permanentes o medidas para atención de los migrantes?"
                                        placeholder="Seleccionar"
                                        options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                        form-index="5"
                                        field="programExists"
                                        disabled={inputDisabled} value={immigrationData.programExists}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                {(immigrationData.programExists === "1") &&
                                    <div className="column">
                                        <TextareaControl
                                            placeholder="Describa Cuáles"
                                            form-index="5"
                                            field="programExplain"
                                            disabled={inputDisabled} value={immigrationData.programExplain}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                }
                                {(immigrationData.programExists === "0") &&
                                    <div className="column">
                                        <TextareaControl
                                            placeholder="¿Por qué no?"
                                            form-index="5"
                                            field="programExplain"
                                            disabled={inputDisabled} value={immigrationData.programExplain}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                }
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <SelectControl
                                        label="¿Su iglesia trabaja con centros de ayuda integral a migrantes?"
                                        placeholder="Seleccionar"
                                        options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                        form-index="5"
                                        field="centersExists"
                                        disabled={inputDisabled} value={immigrationData.centersExists}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                {(immigrationData.centersExists === "1") &&
                                    <div className="column">
                                        <TextareaControl
                                            placeholder="Especifique"
                                            form-index="5"
                                            field="centersExplain"
                                            disabled={inputDisabled} value={immigrationData.centersExplain}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                }
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        label="Cantidad de Migrantes que recibieron apoyo en el año 2022"
                                        placeholder="Cantidad"
                                        form-index="5"
                                        field="peopleHelped"
                                        disabled={inputDisabled} value={immigrationData.peopleHelped}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        label="Cantidad recolectada este año en la ofrenda especial a favor del migrante"
                                        placeholder="Cantidad"
                                        iconLeft="attach_money"
                                        form-index="5"
                                        field="pickup"
                                        disabled={inputDisabled} value={immigrationData.pickup}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="block">
                                {!inputDisabled && <button type="submit" className="button is-link is-fullwidth" disabled={formLoading}>
                                    <Icon name="save" />
                                    <span>Guardar</span>
                                </button>}
                            </div>
                        </div>
                    </form>

                    <HeaderTitle subtitle="Medioambiente" />
                    <form form-index="6" onSubmit={handleSubmit}>
                        <div className="box">
                            <div className="block">
                                <InputControl
                                    type="text"
                                    label="Nombre del presidente local de Medioambiente"
                                    placeholder="Nombre completo"
                                    iconLeft="person"
                                    form-index="6"
                                    field="presidentName"
                                    disabled={inputDisabled} value={enviromentData.presidentName}
                                    onChange={handleChangeField}
                                    required
                                />
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <InputControl
                                        type="text"
                                        label="Teléfono (o Celular)"
                                        placeholder="Número de Teléfono"
                                        iconLeft="smartphone"
                                        form-index="6"
                                        field="presidentPhone"
                                        disabled={inputDisabled} value={enviromentData.presidentPhone}
                                        onChange={handleChangeField}
                                    />
                                </div>
                                <div className="column">
                                    <InputControl
                                        type="email"
                                        label="Correo Electrónico"
                                        placeholder="Correo electrónico"
                                        iconLeft="mail"
                                        form-index="6"
                                        field="presidentEmail"
                                        disabled={inputDisabled} value={enviromentData.presidentEmail}
                                        onChange={handleChangeField}
                                    />
                                </div>
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <SelectControl
                                        label="¿Su iglesia ha implementado acciones verdes para el cuidado del medioambiente?"
                                        placeholder="Seleccionar"
                                        options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                        form-index="6"
                                        field="actionsExists"
                                        disabled={inputDisabled} value={enviromentData.actionsExists}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                {(enviromentData.actionsExists === "0") &&
                                    <div className="column">
                                        <TextareaControl
                                            placeholder="¿Por qué no?"
                                            form-index="6"
                                            field="actionsExplain"
                                            disabled={inputDisabled} value={enviromentData.actionsExplain}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                }
                            </div>
                            {(enviromentData.actionsExists === "1") &&
                                <Fragment>
                                    <div className="block">
                                        <MultipleOptionsControl
                                            columnWeight="4"
                                            form-index="6"
                                            field="actions"
                                            disabled={inputDisabled} value={enviromentData.actions}
                                            onChange={handleChangeField}
                                        >
                                            <Option>No usar desechables en las reuniones de la iglesia</Option>
                                            <Option>Recolección de pilas</Option>
                                            <Option>Recolección de medicamento caduco</Option>
                                            <Option>Apagar la luz cuando no la utilizas</Option>
                                            <Option>Reducir el uso de papel mediante el uso de archivos electrónicos</Option>
                                            <Option>Otras</Option>
                                        </MultipleOptionsControl>
                                    </div>
                                    {(enviromentData.actions[5]) &&
                                        <div className="block">
                                            <TextareaControl
                                                placeholder="Acciones que no se encuentran en la lista (Separadas por comas [,])"
                                                form-index="6"
                                                field="actionsExtras"
                                                disabled={inputDisabled} value={enviromentData.actionsExtras}
                                                onChange={handleChangeField}
                                                required
                                            />
                                        </div>
                                    }
                                </Fragment>
                            }

                            <div className="block">
                                {!inputDisabled && <button type="submit" className="button is-link is-fullwidth" disabled={formLoading}>
                                    <Icon name="save" />
                                    <span>Guardar</span>
                                </button>}
                            </div>
                        </div>
                    </form>
                </Fragment>
            }
        </AppStructure>
    );
};

export default ChristianTestimonyForm;