export const useReportDownloadLink = (level, id, form) => {
    const baseUrl = "https://estimmar.iglesia-metodista.org.mx/reports";
    const conferences = ["cam","cancen","cano","cao","cas","case"];
    const districts = [
        "cam_centro",
        "cam_sudoriental",
        "cam_valle_de_anahuac",
        "cancen_chihuahua",
        "cancen_filadelfia",
        "cancen_jabes",
        "cancen_juan_wesley",
        "cancen_nehemias",
        "cano_pacifico",
        "cano_peninsular",
        "cao_coahuila_centro",
        "cao_filadelfia",
        "cao_fronterizo",
        "cao_manantiales",
        "cao_nehemias",
        "cao_nuevo_fronterizo",
        "cao_rey_de_reyes",
        "cao_reynosa_oriente",
        "cao_san_pablo",
        "cao_sin_fronteras",
        "cao_timoteo",
        "cao_vIctoria",
        "cas_bajio",
        "cas_hidalgo_y_las_huastecas",
        "case_oriente",
        "case_suroriental",
        "case_tlaxcala"
    ];
    const forms = [{folder:"program",file:"prog"},{folder:"shepherds",file:"pas"},{folder:"christianGrowth",file:"dc"},{folder:"organizations",file:"org"},{folder:"christianTestimony",file:"tc"},{folder:"finance",file:"fin"},{folder:"patrimony",file:"conapat"}];

    const levels = [
        [{link:`${baseUrl}/${forms[form].folder}/${forms[form].file}_${districts[id-1]}.xlsx`,name:`${districts[id-1]}`}],
        [{link:`${baseUrl}/${forms[form].folder}/${forms[form].file}_${conferences[id-1]}.xlsx`,name:`${conferences[id-1]}`}],
        conferences.map((conference) => {
            return {link:`${baseUrl}/${forms[form].folder}/${forms[form].file}_${conference}.xlsx`,name:`${conference}`};
        })
    ];

    return levels[level-2];
};