import { useEffect, useState } from "react";
import { Icon } from "../../components/icon";
import { HeaderTitle } from "../../components/titles";
import useAPI from "../../connection/useAPI";
import { useAuth2 } from "../../functions/auth2Context";
import { useReportDownloadLink } from "../../functions/generalHooks";
import AppStructure from "../../structures/appStructure";

const ChristianTestimonyForm = () => {
    const { auth } = useAPI();
    const { currentUser } = useAuth2();
    const downloadLink = useReportDownloadLink;
    const [ buttons, setButtons ] = useState([]);

    useEffect(() => {
        const promises = [auth.getLevel(currentUser.uid)];

        Promise.all(promises).then((data) => {
            const result = data[0];
            setButtons(downloadLink(result.role,result.id,4));
        });
    },[]);

    return(
        <AppStructure>
            <HeaderTitle title="Concentrado de Testimonio Cristiano" />

            {(buttons.length > 0) ? buttons.map((btn) => {
                return(<div key={btn.name} className="block"><a className="button is-success is-fullwidth is-uppercase" href={btn.link} target="_blank"><Icon name="download" /> &nbsp; {btn.name}</a></div>);
            })
            :
            <p>Cargando datos...</p>}
        </AppStructure>
    )
};

export default ChristianTestimonyForm;