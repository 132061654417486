import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SelectControl } from "../../components/formControls";
import { HeaderTitle } from "../../components/titles";
import useAPI from "../../connection/useAPI";
import { useAuth2 } from "../../functions/auth2Context";
import AppStructure from "../../structures/appStructure";

const GeneralPercentages = () => {
    const [ conferencesData, setConferencesData ] = useState([]);
    const [ title, setTitle ] = useState('');
    const [ selectedConference, setSelectedConference ] = useState(0);
    const { currentUser } = useAuth2();
    const { fillingReports } = useAPI();
    const conferences = [{label:"CAM",value:"0"},{label:"CANCEN",value:"1"},{label:"CANO",value:"2"},{label:"CAO",value:"3"},{label:"CAS",value:"4"},{label:"CASE",value:"5"}];

    useEffect(() => {
        const promises = [];
        promises.push(fillingReports.getGeneralPercentages(currentUser.uid,currentUser.role));

        Promise.all(promises).then((data) => {
            //console.log(data[0]);
            setConferencesData(data[0]);

            if(currentUser.role === '3')
                setTitle(data[0][0].name)

            if(currentUser.role === '2')
                setTitle(`Distrito: ${data[0][0].districts[0].name}`);

        });
    },[]);

    function handleChange(e){
        const value = e.currentTarget.value;

        if(value != '')
            setSelectedConference(value);
    }

    return(
        <AppStructure>
            <HeaderTitle title="Panorama General" />

            {currentUser.role === '4' ?
                <div className="box">
                    <SelectControl
                        label="Conferencia"
                        placeholder="Seleccionar"
                        options={conferences}
                        onChange={handleChange}
                        value={selectedConference}
                    />
                </div>

                :

                <div className="box">
                    <HeaderTitle subtitle={title} />
                </div>
            }

            {conferencesData.length === 0 ? <p>Cargando Datos...</p> :
                conferencesData[selectedConference].districts.map(district => {
                    return(
                        <Fragment key={district.id}>
                            <HeaderTitle subtitle={district.name} />
                            <div className="box table-container">
                                <table className="table is-fullwidth">
                                    <thead>
                                        <tr>
                                            <th>Iglesia</th>
                                            <th>Programa</th>
                                            <th>Pastoral</th>
                                            <th>Desarrollo</th>
                                            <th>Organizaciones</th>
                                            <th>Testimonio</th>
                                            <th>Finanzas</th>
                                            <th>CONAPAT</th>
                                            <th>TOTAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {district.churches.map(church => {
                                            let classes = ['','','','','','','',''];

                                            if(church.program < 100) classes[0] = " is-warning";
                                            if(church.program === 0) classes[0] = " is-danger";

                                            if(church.shepherds < 100) classes[1] = " is-warning";
                                            if(church.shepherds === 0) classes[1] = " is-danger";

                                            if(church.growth < 100) classes[2] = " is-warning";
                                            if(church.growth === 0) classes[2] = " is-danger";

                                            if(church.organizations < 100) classes[3] = " is-warning";
                                            if(church.organizations === 0) classes[3] = " is-danger";

                                            if(church.testimony < 100) classes[4] = " is-warning";
                                            if(church.testimony === 0) classes[4] = " is-danger";

                                            if(church.finance < 100) classes[5] = " is-warning";
                                            if(church.finance === 0) classes[5] = " is-danger";

                                            if(church.patrimony < 100) classes[6] = " is-warning";
                                            if(church.patrimony === 0) classes[6] = " is-danger";

                                            return(
                                                <tr key={church.id}>
                                                    <th>{church.name}<br /><small>{church.city}</small></th>
                                                    <td><Link to={`/form-reports/program-data/${church.id}`} className={`button is-fullwidth${classes[0]}`}>{church.program}%</Link></td>
                                                    <td><Link to={`/form-reports/shepherds-data/${church.id}`} className={`button is-fullwidth${classes[1]}`}>{church.shepherds}%</Link></td>
                                                    <td><Link to={`/form-reports/christian-growth-data/${church.id}`} className={`button is-fullwidth${classes[2]}`}>{church.growth}%</Link></td>
                                                    <td><Link to={`/form-reports/organizations-data/${church.id}`} className={`button is-fullwidth${classes[3]}`}>{church.organizations}%</Link></td>
                                                    <td><Link to={`/form-reports/christian-testimony-data/${church.id}`} className={`button is-fullwidth${classes[4]}`}>{church.testimony}%</Link></td>
                                                    <td><Link to={`/form-reports/finance-data/${church.id}`} className={`button is-fullwidth${classes[5]}`}>{church.finance}%</Link></td>
                                                    <td><Link to={`/form-reports/patrimony-data/${church.id}`} className={`button is-fullwidth${classes[6]}`}>{church.patrimony}%</Link></td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </Fragment>
                    );
                })
            }
        </AppStructure>
    );
};

export default GeneralPercentages;