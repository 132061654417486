import { createContext, useContext, useEffect, useState } from "react";
import useAPI from "../connection/useAPI";
import useAppVersion from "./useAppVersion";

const Auth2Context = createContext();

export const useAuth2 = () => {
    return useContext(Auth2Context);
};

export const Auth2Provider = ({children}) => {
    const [currentUser, setCurrentUser] = useState();
    const [loading,setLoading] = useState(true);
    const { auth } = useAPI();
    const appVersion = useAppVersion();

    useEffect(() => {
        auth.isLogged().then((data) => {
            if(!data.result){
                if(!appVersion.check())
                    auth.logout().then(() => {
                        setCurrentUser(null);
                    });
                else
                    setCurrentUser(data);
            }

            setLoading(false);
        });
    },[]);

    const value = {
        currentUser,
        setCurrentUser
    }

    return(
        <Auth2Context.Provider value={value}>
            {!loading && children}
        </Auth2Context.Provider>
    );
}