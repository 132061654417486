import { useState } from "react";
import Navbar from "../components/navbar";
import SidebarMenu from "../components/sidebarMenu";

const AppStructure = ({children}) => {
    const [menuActive,setMenuActive] = useState(false);

    function toggleMenu(e){
        e.preventDefault();

        const active = menuActive;
        setMenuActive(!active);
    }

    return(
        <div className="full-page">
            <SidebarMenu menuActive={menuActive} toggleMenu={toggleMenu} />
            
            <div className="structure">
                <Navbar menuActive={menuActive} toggleMenu={toggleMenu} />
                <section className="app-body">
                    <div className="container">
                        {children}
                    </div>

                    <div className="section"></div>
                    <footer className="footer">
                        <div className="content has-text-centered">
                            <p>
                                <strong>estIMMAR</strong> de la <a href="https://iglesia-metodista.org.mx">Iglesia Metodista de México</a>. 2022-2023 &copy; Todos los derechos reservados.
                                <br />
                            </p>
                        </div>
                    </footer>
                </section>
            </div>
        </div>
    );
}

export default AppStructure;