import { useState } from "react";
import { useAuth2 } from "./auth2Context";

const useLocalStorage = (itemName, initialValue) => {
    const { currentUser } = useAuth2();

    function hashCode(str) {
        let hash = 0;
        for (let i = 0, len = str.length; i < len; i++) {
            let chr = str.charCodeAt(i);
            hash = (hash << 5) - hash + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }

    itemName = `${itemName}_${hashCode(currentUser.uid)}`;
    const localStorageItem = localStorage.getItem(itemName);
    let parsedItem = initialValue;

    if(!localStorageItem)
        localStorage.setItem(itemName,JSON.stringify(parsedItem));
    else
        parsedItem = JSON.parse(localStorageItem);

    const [item, setItem ] = useState(parsedItem);

    function saveItem(newItem){
        const stringifiedItem = JSON.stringify(newItem);
        localStorage.setItem(itemName, stringifiedItem);
        setItem(newItem);
    }

    return [item,saveItem];
};

export default useLocalStorage;