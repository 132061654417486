import AppStructure from "../../structures/appStructure";
import { Link } from "react-router-dom";
import { HeaderTitle } from "../../components/titles";

const Dashboard = () => {
    return(
        <AppStructure>
            <HeaderTitle title="Plataforma estIMMAR" />

            <div className="box">
                <p>A través de la plataforma de estIMMAR podrá llenar el formulario para poder realizar el ejercicio de estadística de la Iglesia Metodista de México A.R.</p>
            </div>

            <h3 className="title">Instrucciones</h3>
            
            <div className="box">
                <p><strong></strong> Ve al menú de la izquierda y selecciona el botón de Formulario <span className="icon"><i className="material-symbols-rounded">description</i></span></p>
                <p><small>En caso de estar en el celular, dirígete a la barra de navegación de arriba y presiona el botón <span className="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="9" y1="3" x2="9" y2="21"></line></svg></span> para abrir el menú</small></p>
            </div>
        </AppStructure>
    );
};

export default Dashboard;