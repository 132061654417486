import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CheckboxControl, InputControl, MultipleOptionsControl, Option, SelectControl, TextareaControl } from "../../components/formControls";
import { Icon } from "../../components/icon";
import ProgressBar from "../../components/progressBar";
import { HeaderTitle } from "../../components/titles";
import useAPI from "../../connection/useAPI";
import { useAuth2 } from "../../functions/auth2Context";
import useLocalStorage from "../../functions/useLocalStorage";
import AppStructure from "../../structures/appStructure";

const ChristianDevelopmentForm = () => {
    const [ itemsCompleted, setItemsCompleted ] = useState(0);
    const [ loading, setLoading ] = useState(false);
    const [ formLoading, setFormLoading ] = useState(false);
    const [ christianGrowthDataId, setChristianGrowthDataId ] = useState(null);
    const [ inputDisabled, setInputDisabled ] = useState(false);
    const [ presidentData, setPresidentData ] = useLocalStorage("CHRISTIANGROWTH_PRESIDENTDATA",{});
    const [ adultStudyProgram, setAdultStudyProgram ] = useLocalStorage("CHRISTIANGROWTH_ADULTSTUDYPROGRAM",{groups:[]});
    const [ masterPlanProgram, setMasterPlanProgram ] = useLocalStorage("CHRISTIANGROWTH_MASTERPLANPROGRAM",{});
    const [ childrenStudyProgram, setChildrenStudyProgram ] = useLocalStorage("CHRISTIANGROWTH_CHILDRENSTUDYPROGRAM",{groups:[]});
    const [ staffGrowthProgram, setStaffGrowthProgram ] = useLocalStorage("CHRISTIANGROWTH_STAFFGROWTHPROGRAM",{});
    const [ christianSchool, setChristianSchool ] = useLocalStorage("CHRISTIANGROWTH_CHRISTIANSCHOOL",{});
    const [ music, setMusic ] = useLocalStorage("CHRISTIANGROWTH_MUSIC",{});
    const { currentUser } = useAuth2();
    const { christianGrowthData } = useAPI();
    const { churchId } = useParams();

    const formObjects = [presidentData,adultStudyProgram,masterPlanProgram,childrenStudyProgram,staffGrowthProgram,christianSchool,music];
    const valueSetters = [setPresidentData,setAdultStudyProgram,setMasterPlanProgram,setChildrenStudyProgram,setStaffGrowthProgram,setChristianSchool,setMusic];
    const formFields = ["presidentData","adultStudyProgram","masterPlanProgram","childrenStudyProgram","staffGrowthProgram","christianSchool","music"];

    useEffect(() => {
        setLoading(true);

        let cId = currentUser.uid;
        //setInputDisabled(true);
        if(churchId){
            cId = churchId;
            setInputDisabled(true);
        }
        const promises = [christianGrowthData.get(cId)];

        Promise.all(promises).then((response) => {
            fillServerData(response[0]);

            setLoading(false);
        });
    },[]);

    function fillServerData(christianGrowthObject){
        if(christianGrowthObject.id){
            let serverItemsCompleted = 0;

            formFields.forEach((field,fieldIndex) => {
                const fieldObject = JSON.parse(christianGrowthObject[field]);

                if(fieldObject){
                    valueSetters[fieldIndex](fieldObject);
                    serverItemsCompleted++;
                }
            });

            setItemsCompleted(serverItemsCompleted);
            setChristianGrowthDataId(christianGrowthObject.id);
        }
    }

    function handleChangeField(e){
        const formIndex = e.currentTarget.getAttribute("form-index");
        let fieldValue = e.currentTarget.value;
        if(typeof fieldValue === 'string'){
            fieldValue = fieldValue.replace(/(\r\n|\n|\r|")/gi,'');
        }
        const field = e.currentTarget.getAttribute("field");
        const object = JSON.parse(JSON.stringify(formObjects[formIndex]));
        object[field] = fieldValue;

        valueSetters[formIndex](object);
    }

    function handleSubmit(e){
        e.preventDefault();
        setFormLoading(true);

        const formIndex = e.currentTarget.getAttribute("form-index");

        christianGrowthData.save(currentUser.uid,
                        currentUser.uid,
                        christianGrowthDataId,
                        formFields[formIndex],
                        JSON.stringify(formObjects[formIndex])
        ).then((response) => {
            fillServerData(response);

            setFormLoading(false);
        });
    }

    return(
        <AppStructure>
            <HeaderTitle title="Formulario de Desarrollo Cristiano" />
            <ProgressBar itemsToEvaluate={7} itemsCompleted={itemsCompleted} />
            { loading ? <p>Cargando Datos...</p> :
                <Fragment>
                    <form onSubmit={handleSubmit} form-index="0">
                        <div className="box">
                            <div className="block">
                                <InputControl
                                    type="text"
                                    label="Nombre del presidente local del Área de Desarrollo Cristiano"
                                    placeholder="Nombre completo"
                                    iconLeft="person"
                                    form-index="0"
                                    field="name"
                                    disabled={inputDisabled} value={presidentData.name}
                                    onChange={handleChangeField}
                                    required
                                />
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <InputControl
                                        type="text"
                                        label="Teléfono (o Celular)"
                                        placeholder="Número de Teléfono"
                                        iconLeft="smartphone"
                                        form-index="0"
                                        field="phone"
                                        disabled={inputDisabled} value={presidentData.phone}
                                        onChange={handleChangeField}
                                    />
                                </div>
                                <div className="column">
                                    <InputControl
                                        type="email"
                                        label="Correo Electrónico"
                                        placeholder="Correo electrónico"
                                        iconLeft="mail"
                                        form-index="0"
                                        field="email"
                                        disabled={inputDisabled} value={presidentData.email}
                                        onChange={handleChangeField}
                                    />
                                </div>
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <InputControl
                                        label="¿Cuántas células de personas nuevas preside este funcionario?"
                                        type="number"
                                        placeholder="Cantidad"
                                        form-index="0"
                                        field="newPeopleGroups"
                                        disabled={inputDisabled} value={presidentData.newPeopleGroups}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                <div className="column">
                                    <InputControl
                                        label="Total de personas que asisten"
                                        type="number"
                                        placeholder="Cantidad"
                                        form-index="0"
                                        field="newPeopleGroupsAttendance"
                                        disabled={inputDisabled} value={presidentData.newPeopleGroupsAttendance}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                            </div>
                            {!inputDisabled &&
                                <div className="block">
                                    <button type="submit" className="button is-link is-fullwidth" disabled={formLoading}>
                                        <Icon name="save" />
                                        <span>Guardar</span>
                                    </button>
                                </div>
                            }
                        </div>
                    </form>

                    <HeaderTitle subtitle="Programa de estudio para adultos y jóvenes" />
                    <form onSubmit={handleSubmit} form-index="1">
                        <div className="box">
                            <div className="block">
                                <MultipleOptionsControl
                                    description="En su iglesia se realizaron:"
                                    columnWeight="6"
                                    form-index="1"
                                    field="groups"
                                    disabled={inputDisabled} value={adultStudyProgram.groups}
                                    onChange={handleChangeField}
                                >
                                    <Option>Escuela Dominical de Adultos</Option>
                                    <Option>Grupos de Estudio para Adultos</Option>
                                </MultipleOptionsControl>
                            </div>
                            {(!adultStudyProgram.groups[0] && !adultStudyProgram.groups[1]) &&
                                <div className="block">
                                    <InputControl
                                        placeholder="Si no tiene ninguno, ¿Por qué?"
                                        type="text"
                                        form-index="1"
                                        field="noGroupsExplain"
                                        disabled={inputDisabled} value={adultStudyProgram.noGroupsExplain}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                            }
                            {(adultStudyProgram.groups[0] || adultStudyProgram.groups[1]) &&
                                <Fragment>
                                    <div className="block">
                                        <TextareaControl
                                            placeholder="¿Qué material o materiales utilizó? (Separados por comas [,])"
                                            type="text"
                                            form-index="1"
                                            field="groupsMaterials"
                                            disabled={inputDisabled} value={adultStudyProgram.groupsMaterials}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                    <div className="block">
                                        <p>Conteste las siguientes preguntas solo en los casos que haya usado un material diferente al PRNE. Si sólo usó PRNE vaya a la siguiente sección.</p>
                                    </div>
                                </Fragment>
                            }
                            {(adultStudyProgram.groups[0]) &&
                                <div className="block columns">
                                    <div className="column">
                                        <InputControl
                                            label="En Escuela Dominical de adultos"
                                            placeholder="Cantidad de Grupos"
                                            type="number"
                                            form-index="1"
                                            field="edGruopsNumber"
                                            disabled={inputDisabled} value={adultStudyProgram.edGruopsNumber}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                    <div className="column">
                                        <InputControl
                                            label="&nbsp;"
                                            placeholder="Total de Asistencias"
                                            type="number"
                                            form-index="1"
                                            field="edGruopsAttendance"
                                            disabled={inputDisabled} value={adultStudyProgram.edGruopsAttendance}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                </div>
                            }
                            {(adultStudyProgram.groups[1]) &&
                                <div className="block columns">
                                    <div className="column">
                                        <InputControl
                                            label="Grupos de Estudio"
                                            placeholder="Cantidad de Grupos"
                                            type="number"
                                            form-index="1"
                                            field="otherGruopsNumber"
                                            disabled={inputDisabled} value={adultStudyProgram.otherGruopsNumber}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                    <div className="column">
                                        <InputControl
                                            label="&nbsp;"
                                            placeholder="Total de Asistencias"
                                            type="number"
                                            form-index="1"
                                            field="otherGruopsAttendance"
                                            disabled={inputDisabled} value={adultStudyProgram.otherGruopsAttendance}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                </div>
                            }
                            {(adultStudyProgram.groups[0] || adultStudyProgram.groups[1]) &&
                                <div className="block">
                                    <SelectControl
                                        label="¿En qué formato realizaron las reuniones?"
                                        placeholder="Formato"
                                        options={[{value:"1",label:"Virtual"},{value:"2",label:"Presencial"},{value:"3",label:"Híbrido"}]}
                                        form-index="1"
                                        field="meetingsFormat"
                                        disabled={inputDisabled} value={adultStudyProgram.meetingsFormat}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                            }
                            {(adultStudyProgram.groups[0]) &&
                                <div className="block columns">
                                    <div className="column">
                                        <InputControl
                                            label="Cantidad total de congregantes que asiste regularmente a la escuela dominical o grupos de estudio"
                                            placeholder="Adultos"
                                            type="number"
                                            form-index="1"
                                            field="edAdultAttendance"
                                            disabled={inputDisabled} value={adultStudyProgram.edAdultAttendance}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                    <div className="column">
                                        <InputControl
                                            label="&nbsp;"
                                            placeholder="Jóvenes"
                                            type="number"
                                            form-index="1"
                                            field="edYouthAttendance"
                                            disabled={inputDisabled} value={adultStudyProgram.edYouthAttendance}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                </div>
                            }
                            {(adultStudyProgram.groups[1]) &&
                                <div className="block columns">
                                    <div className="column">
                                        <InputControl
                                            label="Cantidad de grupos de la escuela dominical o grupos de estudio"
                                            placeholder="Adultos"
                                            type="number"
                                            form-index="1"
                                            field="otherAdultAttendance"
                                            disabled={inputDisabled} value={adultStudyProgram.otherAdultAttendance}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                    <div className="column">
                                        <InputControl
                                            label="&nbsp;"
                                            placeholder="Jóvenes"
                                            type="number"
                                            form-index="1"
                                            field="otherYouthAttendance"
                                            disabled={inputDisabled} value={adultStudyProgram.otherYouthAttendance}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                </div>
                            }
                            {!inputDisabled &&
                                <div className="block">
                                    <button type="submit" className="button is-link is-fullwidth" disabled={formLoading}>
                                        <Icon name="save" />
                                        <span>Guardar</span>
                                    </button>
                                </div>
                            }
                        </div>
                    </form>

                    <HeaderTitle subtitle="Programa Rector Nacional de Estudios" />
                    <form onSubmit={handleSubmit} form-index="2">
                        <div className="box">
                            <div className="block">
                                <SelectControl
                                    label="Se ha implementado el PRNE en su Iglesia"
                                    placeholder="Seleccionar"
                                    options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                    form-index="2"
                                    field="exists"
                                    disabled={inputDisabled} value={masterPlanProgram.exists}
                                    onChange={handleChangeField}
                                    required
                                />
                            </div>
                            {(masterPlanProgram.exists === "1") &&
                                <Fragment>
                                    <div className="block">
                                        <p>Si ha implementado el PRNE indique la cantidad de alumnos que asistieron, así como la cantidad de grupos que se formaron (Si no tuvo ese nivel escriba “0”)</p>
                                    </div>
                                    <div className="block columns">
                                        <div className="column">
                                            <h4 className="title is-6">Tomo II: Nivel 0-1 Alcanzar-Discipulado</h4>
                                        </div>
                                        <div className="column">
                                            <div className="columns is-mobile">
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Numero de grupos"
                                                        form-index="2"
                                                        field="volumeIIGroups"
                                                        disabled={inputDisabled} value={masterPlanProgram.volumeIIGroups}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Total de alumnos"
                                                        form-index="2"
                                                        field="volumeIIStudents"
                                                        disabled={inputDisabled} value={masterPlanProgram.volumeIIStudents}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="block columns">
                                        <div className="column">
                                            <h4 className="title is-6">Tomo III: Nivel 3  Probandos</h4>
                                        </div>
                                        <div className="column">
                                            <div className="columns is-mobile">
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Numero de grupos"
                                                        form-index="2"
                                                        field="volumeIIIGroups"
                                                        disabled={inputDisabled} value={masterPlanProgram.volumeIIIGroups}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Total de alumnos"
                                                        form-index="2"
                                                        field="volumeIIIStudents"
                                                        disabled={inputDisabled} value={masterPlanProgram.volumeIIIStudents}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="block columns">
                                        <div className="column">
                                            <h4 className="title is-6">Tomo IV: Nivel 4 Crecer en Cristo</h4>
                                        </div>
                                        <div className="column">
                                            <div className="columns is-mobile">
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Numero de grupos"
                                                        form-index="2"
                                                        field="volumeIVGroups"
                                                        disabled={inputDisabled} value={masterPlanProgram.volumeIVGroups}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Total de alumnos"
                                                        form-index="2"
                                                        field="volumeIVStudents"
                                                        disabled={inputDisabled} value={masterPlanProgram.volumeIVStudents}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="block columns">
                                        <div className="column">
                                            <h4 className="title is-6">Tomo V: Nivel 5 Servir a Cristo</h4>
                                        </div>
                                        <div className="column">
                                            <div className="columns is-mobile">
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Numero de grupos"
                                                        form-index="2"
                                                        field="volumeVGroups"
                                                        disabled={inputDisabled} value={masterPlanProgram.volumeVGroups}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Total de alumnos"
                                                        form-index="2"
                                                        field="volumeVStudents"
                                                        disabled={inputDisabled} value={masterPlanProgram.volumeVStudents}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="block columns">
                                        <div className="column">
                                            <h4 className="title is-6">Tomo VI: Nivel 6 Ministro de Cristo (Líder)</h4>
                                        </div>
                                        <div className="column">
                                            <div className="columns is-mobile">
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Numero de grupos"
                                                        form-index="2"
                                                        field="volumeVIGroups"
                                                        disabled={inputDisabled} value={masterPlanProgram.volumeVIGroups}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Total de alumnos"
                                                        form-index="2"
                                                        field="volumeVIStudents"
                                                        disabled={inputDisabled} value={masterPlanProgram.volumeVIStudents}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="block columns">
                                        <div className="column">
                                            <h4 className="title is-6">Tomo VII: Nivel 6 Manual de Finanzas</h4>
                                        </div>
                                        <div className="column">
                                            <div className="columns is-mobile">
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Numero de grupos"
                                                        form-index="2"
                                                        field="volumeVIIGroups"
                                                        disabled={inputDisabled} value={masterPlanProgram.volumeVIIGroups}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Total de alumnos"
                                                        form-index="2"
                                                        field="volumeVIIStudents"
                                                        disabled={inputDisabled} value={masterPlanProgram.volumeVIIStudents}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="block columns">
                                        <div className="column">
                                            <h4 className="title is-6">Tomo XI: Escuela de Familias</h4>
                                        </div>
                                        <div className="column">
                                            <div className="columns is-mobile">
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Numero de grupos"
                                                        form-index="2"
                                                        field="volumeXIGroups"
                                                        disabled={inputDisabled} value={masterPlanProgram.volumeXIGroups}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Total de alumnos"
                                                        form-index="2"
                                                        field="volumeXIStudents"
                                                        disabled={inputDisabled} value={masterPlanProgram.volumeXIStudents}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            }
                            <hr />
                            <div className="block">
                                <SelectControl
                                    label="En su iglesia se utilizó el PRNE Infantil"
                                    placeholder="Seleccionar"
                                    options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                    form-index="2"
                                    field="kidsExists"
                                    disabled={inputDisabled} value={masterPlanProgram.kidsExists}
                                    onChange={handleChangeField}
                                    required
                                />
                            </div>
                            {(masterPlanProgram.kidsExists === "1") &&
                                <Fragment>
                                    <div className="block">
                                        <p>Si ha implementado el PRNE Infantil, indique cuántos de niños asistieron, y la cantidad de grupos formados (Si no tuvo ese nivel escriba “0”)</p>
                                    </div>
                                    <div className="block columns">
                                    <div className="column is-one-third">
                                        <h4 className="title is-6">Mis primeros pasos con Jesús (niños nuevos)</h4>
                                    </div>
                                    <div className="column">
                                        <div className="columns">
                                            <div className="column columns is-mobile">
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Año 1"
                                                        form-index="2"
                                                        field="level0year1"
                                                        disabled={inputDisabled} value={masterPlanProgram.level0year1}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Año 2"
                                                        form-index="2"
                                                        field="level0year2"
                                                        disabled={inputDisabled} value={masterPlanProgram.level0year2}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="column columns is-mobile">
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Año 3"
                                                        form-index="2"
                                                        field="level0year3"
                                                        disabled={inputDisabled} value={masterPlanProgram.level0year3}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Total de Grupos"
                                                        form-index="2"
                                                        field="level0groups"
                                                        disabled={inputDisabled} value={masterPlanProgram.level0groups}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="block columns">
                                    <div className="column is-one-third">
                                        <h4 className="title is-6">Nivel 1 (0 a 2 años) Cunas y maternal</h4>
                                    </div>
                                    <div className="column">
                                        <div className="columns">
                                            <div className="column columns is-mobile">
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Año 1"
                                                        form-index="2"
                                                        field="level1year1"
                                                        disabled={inputDisabled} value={masterPlanProgram.level1year1}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Año 2"
                                                        form-index="2"
                                                        field="level1year2"
                                                        disabled={inputDisabled} value={masterPlanProgram.level1year2}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="column columns is-mobile">
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Año 3"
                                                        form-index="2"
                                                        field="level1year3"
                                                        disabled={inputDisabled} value={masterPlanProgram.level1year3}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Total de Grupos"
                                                        form-index="2"
                                                        field="level1groups"
                                                        disabled={inputDisabled} value={masterPlanProgram.level1groups}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="block columns">
                                    <div className="column is-one-third">
                                        <h4 className="title is-6">Nivel 2 (3 a 5 años) Párvulos</h4>
                                    </div>
                                    <div className="column">
                                        <div className="columns">
                                            <div className="column columns is-mobile">
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Año 1"
                                                        form-index="2"
                                                        field="level2year1"
                                                        disabled={inputDisabled} value={masterPlanProgram.level2year1}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Año 2"
                                                        form-index="2"
                                                        field="level2year2"
                                                        disabled={inputDisabled} value={masterPlanProgram.level2year2}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="column columns is-mobile">
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Año 3"
                                                        form-index="2"
                                                        field="level2year3"
                                                        disabled={inputDisabled} value={masterPlanProgram.level2year3}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Total de Grupos"
                                                        form-index="2"
                                                        field="level2groups"
                                                        disabled={inputDisabled} value={masterPlanProgram.level2groups}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="block columns">
                                    <div className="column is-one-third">
                                        <h4 className="title is-6">Nivel 3 (6 a 8 años) Primarios "A"</h4>
                                    </div>
                                    <div className="column">
                                        <div className="columns">
                                            <div className="column columns is-mobile">
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Año 1"
                                                        form-index="2"
                                                        field="level3year1"
                                                        disabled={inputDisabled} value={masterPlanProgram.level3year1}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Año 2"
                                                        form-index="2"
                                                        field="level3year2"
                                                        disabled={inputDisabled} value={masterPlanProgram.level3year2}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="column columns is-mobile">
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Año 3"
                                                        form-index="2"
                                                        field="level3year3"
                                                        disabled={inputDisabled} value={masterPlanProgram.level3year3}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Total de Grupos"
                                                        form-index="2"
                                                        field="level3groups"
                                                        disabled={inputDisabled} value={masterPlanProgram.level3groups}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="block columns">
                                    <div className="column is-one-third">
                                        <h4 className="title is-6">Nivel 4 (9 a 11 años) Primarios "B"</h4>
                                    </div>
                                    <div className="column">
                                        <div className="columns">
                                            <div className="column columns is-mobile">
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Año 1"
                                                        form-index="2"
                                                        field="level4year1"
                                                        disabled={inputDisabled} value={masterPlanProgram.level4year1}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Año 2"
                                                        form-index="2"
                                                        field="level4year2"
                                                        disabled={inputDisabled} value={masterPlanProgram.level4year2}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="column columns is-mobile">
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Año 3"
                                                        form-index="2"
                                                        field="level4year3"
                                                        disabled={inputDisabled} value={masterPlanProgram.level4year3}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Total de Grupos"
                                                        form-index="2"
                                                        field="level4groups"
                                                        disabled={inputDisabled} value={masterPlanProgram.level4groups}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="block columns">
                                    <div className="column is-one-third">
                                        <h4 className="title is-6">Nivel 5 (12 a 14 años) Secundarios</h4>
                                    </div>
                                    <div className="column">
                                        <div className="columns">
                                            <div className="column columns is-mobile">
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Año 1"
                                                        form-index="2"
                                                        field="level5year1"
                                                        disabled={inputDisabled} value={masterPlanProgram.level5year1}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Año 2"
                                                        form-index="2"
                                                        field="level5year2"
                                                        disabled={inputDisabled} value={masterPlanProgram.level5year2}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="column columns is-mobile">
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Año 3"
                                                        form-index="2"
                                                        field="level5year3"
                                                        disabled={inputDisabled} value={masterPlanProgram.level5year3}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                                <div className="column">
                                                    <InputControl
                                                        type="number"
                                                        placeholder="Total de Grupos"
                                                        form-index="2"
                                                        field="level5groups"
                                                        disabled={inputDisabled} value={masterPlanProgram.level5groups}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </Fragment>
                            }
                            {!inputDisabled &&
                                <div className="block">
                                    <button type="submit" className="button is-link is-fullwidth" disabled={formLoading}>
                                        <Icon name="save" />
                                        <span>Guardar</span>
                                    </button>
                                </div>
                            }
                        </div>
                    </form>

                    <HeaderTitle subtitle="Programa de estudio para niños" />
                    <form onSubmit={handleSubmit} form-index="3">
                        <div className="box">
                            <div className="block">
                                <MultipleOptionsControl
                                    description="En su iglesia se realizaron:"
                                    columnWeight="6"
                                    form-index="3"
                                    field="groups"
                                    disabled={inputDisabled} value={childrenStudyProgram.groups}
                                    onChange={handleChangeField}
                                >
                                    <Option>Escuela dominical Infantil</Option>
                                    <Option>Grupos de estudios para niños</Option>
                                </MultipleOptionsControl>
                            </div>
                            {(!childrenStudyProgram.groups[0] && !childrenStudyProgram.groups[1]) &&
                                <div className="block">
                                    <InputControl
                                        placeholder="Si no tiene ninguno, ¿Por qué?"
                                        type="text"
                                        form-index="3"
                                        field="noGroupsExplain"
                                        disabled={inputDisabled} value={childrenStudyProgram.noGroupsExplain}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                            }
                            {(childrenStudyProgram.groups[0] || childrenStudyProgram.groups[1]) &&
                                <Fragment>
                                    <div className="block">
                                        <TextareaControl
                                            placeholder="¿Qué material o materiales utilizó? (Separados por comas [,])"
                                            type="text"
                                            form-index="3"
                                            field="groupsMaterials"
                                            disabled={inputDisabled} value={childrenStudyProgram.groupsMaterials}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                    <div className="block">
                                        <SelectControl
                                            label="¿En qué formato realizaron las reuniones?"
                                            placeholder="Formato"
                                            options={[{value:"1",label:"Virtual"},{value:"2",label:"Presencial"},{value:"3",label:"Híbrido"}]}
                                            form-index="3"
                                            field="meetingsFormat"
                                            disabled={inputDisabled} value={childrenStudyProgram.meetingsFormat}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                    <div className="block">
                                        <p>¿Qué cantidad de niños asiste regularmente a la Escuela Dominical Infantil de su iglesia o grupos de estudio?</p>
                                    </div>
                                    <div className="block columns">
                                        <div className="column columns is-mobile">
                                            <div className="column">
                                                <InputControl
                                                    placeholder="Párvulos"
                                                    type="number"
                                                    form-index="3"
                                                    field="parvulosAttendant"
                                                    disabled={inputDisabled} value={childrenStudyProgram.parvulosAttendant}
                                                    onChange={handleChangeField}
                                                    required
                                                />
                                            </div>
                                            <div className="column">
                                                <InputControl
                                                    placeholder="Principiantes"
                                                    type="number"
                                                    form-index="3"
                                                    field="principiantesAttendant"
                                                    disabled={inputDisabled} value={childrenStudyProgram.principiantesAttendant}
                                                    onChange={handleChangeField}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="column columns is-mobile">
                                            <div className="column">
                                                <InputControl
                                                    placeholder="Primarios"
                                                    type="number"
                                                    form-index="3"
                                                    field="primariosAttendant"
                                                    disabled={inputDisabled} value={childrenStudyProgram.primariosAttendant}
                                                    onChange={handleChangeField}
                                                    required
                                                />
                                            </div>
                                            <div className="column">
                                                <InputControl
                                                    placeholder="Intermedios"
                                                    type="number"
                                                    form-index="3"
                                                    field="intermediosAttendant"
                                                    disabled={inputDisabled} value={childrenStudyProgram.intermediosAttendant}
                                                    onChange={handleChangeField}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            }
                            {!inputDisabled &&
                                <div className="block">
                                    <button type="submit" className="button is-link is-fullwidth" disabled={formLoading}>
                                        <Icon name="save" />
                                        <span>Guardar</span>
                                    </button>
                                </div>
                            }
                        </div>
                    </form>

                    <HeaderTitle subtitle="Programa de Formación y Desarrollo para Funcionarios" />
                    <form onSubmit={handleSubmit} form-index="4">
                    <div className="box">
                            <div className="block columns">
                                <div className="column">
                                    <InputControl
                                        label="De acuerdo con su organigrama ¿cuántos funcionarios del Área de Desarrollo Cristiano hay en su iglesia?"
                                        placeholder="Canitdad"
                                        type="number"
                                        form-index="4"
                                        field="amount"
                                        disabled={inputDisabled} value={staffGrowthProgram.amount}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                <div className="column">
                                    <InputControl
                                        label="¿Cuántos maestros de escuela dominical o su equivalente entre semana hay en su Iglesia?"
                                        placeholder="Canitdad"
                                        type="number"
                                        form-index="4"
                                        field="edTeachers"
                                        disabled={inputDisabled} value={staffGrowthProgram.edTeachers}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="block">
                                <p>Indique cuantos funcionarios de Desarrollo Cristiano y Maestros participaron en el Curso de Capacitación para Funcionarios.</p>
                            </div>
                            <div className="block">
                                <h5 className="title is-5">Programa de formación y desarrollo para funcionarios</h5>
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <p>Eje 1. Eje Administrativo y Operativo</p>
                                </div>
                                <div className="column">
                                    <p>Tiene grupo del curso administrativo operativo (contiene el Plan Operativo Nacional y el Plan Rector Nacional de Estudio)</p>
                                </div>
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        label="Funcionarios que participan"
                                        placeholder="Cantidad"
                                        form-index="4"
                                        field="axis1"
                                        disabled={inputDisabled} value={staffGrowthProgram.axis1}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <p>Eje 2. Eje Liderazgo Como Jesús</p>
                                </div>
                                <div className="column">
                                    <p>Tiene grupo del curso Liderazgo como Jesús</p>
                                </div>
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        label="Funcionarios que participan"
                                        placeholder="Cantidad"
                                        form-index="4"
                                        field="axis2"
                                        disabled={inputDisabled} value={staffGrowthProgram.axis2}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <p>Eje 3. Eje Tecnológico.</p>
                                </div>
                                <div className="column">
                                    <p>Tiene grupo del curso tecnológico (contiene word, excel, powerpoint)</p>
                                </div>
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        label="Funcionarios que participan"
                                        placeholder="Cantidad"
                                        form-index="4"
                                        field="axis3"
                                        disabled={inputDisabled} value={staffGrowthProgram.axis3}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <p>Eje 4. Eje Pedagógico (Para Maestros).</p>
                                </div>
                                <div className="column">
                                    <p>Tiene grupo del curso pedagógico (contiene técnicas de enseñanza siglo XXI)</p>
                                </div>
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        label="Funcionarios que participan"
                                        placeholder="Cantidad"
                                        form-index="4"
                                        field="axis4"
                                        disabled={inputDisabled} value={staffGrowthProgram.axis4}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <p>Eje 5. Eje Ministerio Musical</p>
                                </div>
                                <div className="column">
                                    <p>Tiene grupo del curso ministerio musical (contiene la música en la Biblia, en la iglesia primitiva y edad media)</p>
                                </div>
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        label="Funcionarios que participan"
                                        placeholder="Cantidad"
                                        form-index="4"
                                        field="axis5"
                                        disabled={inputDisabled} value={staffGrowthProgram.axis5}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                            </div>
                            {!inputDisabled &&
                                <div className="block">
                                    <button type="submit" className="button is-link is-fullwidth" disabled={formLoading}>
                                        <Icon name="save" />
                                        <span>Guardar</span>
                                    </button>
                                </div>
                            }
                        </div>
                    </form>
                        

                    <HeaderTitle subtitle="Escuela Cristiana de Vacaciones" />
                    <form onSubmit={handleSubmit} form-index="5">
                        <div className="box">
                            <div className="block">
                                <div className="column">
                                    <SelectControl
                                        label="¿Realizó este año la Escuela Cristiana de Vacaciones?"
                                        placeholder="Seleccionar"
                                        options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                        form-index="5"
                                        field="exists"
                                        disabled={inputDisabled} value={christianSchool.exists}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                            </div>
                            {(christianSchool.exists === "1") &&
                                <Fragment>
                                     <div className="block columns">
                                        <div className="column">
                                            <InputControl
                                                label="¿En cuántos días realizó la Escuela Cristiana de Vacaciones?"
                                                placeholder="Cantidad"
                                                type="number"
                                                form-index="5"
                                                field="daysNumber"
                                                disabled={inputDisabled} value={christianSchool.daysNumber}
                                                onChange={handleChangeField}
                                                required
                                            />
                                        </div>
                                        <div className="column">
                                            <SelectControl
                                                label="¿En qué formato realizaron las reuniones?"
                                                placeholder="Selecciona"
                                                options={[{value:"1",label:"Virtual"},{value:"2",label:"Presencial"},{value:"3",label:"Híbrido"}]}
                                                form-index="5"
                                                field="meetingsFormat"
                                                disabled={inputDisabled} value={christianSchool.meetingsFormat}
                                                onChange={handleChangeField}
                                                required
                                            />
                                        </div>
                                    </div>
                                    {(christianSchool.meetingsFormat === "1") &&
                                        <div className="block columns">
                                            <div className="column">
                                                <TextareaControl
                                                    label="Si realizó la ECV en formato virtual"
                                                    placeholder="¿Cuál fue el resultado que tuvieron?"
                                                    form-index="5"
                                                    field="meetingsResult"
                                                    disabled={inputDisabled} value={christianSchool.meetingsResult}
                                                    onChange={handleChangeField}
                                                    required
                                                />
                                            </div>
                                            <div className="column">
                                                <InputControl
                                                    type="number"
                                                    label="Indique la cantidad de participantes en este esquema:"
                                                    placeholder="Cantidad"
                                                    form-index="5"
                                                    field="meetingsAttendant"
                                                    disabled={inputDisabled} value={christianSchool.meetingsAttendant}
                                                    onChange={handleChangeField}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    }
                                    
                                    <div className="block columns">
                                        <div className="column">
                                            <SelectControl
                                                label="¿Usó en la Escuela Cristiana de Vacaciones, el material de estudio de la IMMAR?"
                                                placeholder="Seleccionar"
                                                options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                                form-index="5"
                                                field="materialUsed"
                                                disabled={inputDisabled} value={christianSchool.materialUsed}
                                                onChange={handleChangeField}
                                                required
                                            />
                                        </div>
                                        {christianSchool.materialUsed === "0" &&
                                            <div className="column">
                                                <InputControl
                                                    label="&nbsp;"
                                                    placeholder="Si no, ¿Por qué?"
                                                    type="text"
                                                    form-index="5"
                                                    field="noMaterialUsedExplain"
                                                    disabled={inputDisabled} value={christianSchool.noMaterialUsedExplain}
                                                    onChange={handleChangeField}
                                                    required
                                                />
                                            </div>
                                        }
                                    </div>
                                    {christianSchool.materialUsed === "0" &&
                                        <div className="block">
                                            <InputControl
                                                label="Si realizó la ECV con otro material"
                                                placeholder="Indique el nombre"
                                                type="text"
                                                form-index="5"
                                                field="otherMaterial"
                                                disabled={inputDisabled} value={christianSchool.otherMaterial}
                                                onChange={handleChangeField}
                                                required
                                            />
                                        </div>
                                    }
                                    <div className="block columns">
                                        <div className="column columns is-mobile">
                                            <div className="column">
                                                <InputControl
                                                    type="number"
                                                    label="Niños que asistieron a la ECV"
                                                    placeholder="Cantidad"
                                                    form-index="5"
                                                    field="studentsNumber"
                                                    disabled={inputDisabled} value={christianSchool.studentsNumber}
                                                    onChange={handleChangeField}
                                                    required
                                                />
                                            </div>
                                            <div className="column">
                                                <InputControl
                                                    type="number"
                                                    label="Niños de la Congregación"
                                                    placeholder="Cantidad"
                                                    form-index="5"
                                                    field="localStudentsNumber"
                                                    disabled={inputDisabled} value={christianSchool.localStudentsNumber}
                                                    onChange={handleChangeField}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="column columns is-mobile">
                                            <div className="column">
                                                <InputControl
                                                    type="number"
                                                    label="Niños Nuevos"
                                                    placeholder="Cantidad"
                                                    form-index="5"
                                                    field="newStudentsNumber"
                                                    disabled={inputDisabled} value={christianSchool.newStudentsNumber}
                                                    onChange={handleChangeField}
                                                    required
                                                />
                                            </div>
                                            <div className="column">
                                                <InputControl
                                                    type="number"
                                                    label="Niños que recibieron a Cristo"
                                                    placeholder="Cantidad"
                                                    form-index="5"
                                                    field="sevedStudentsNumber"
                                                    disabled={inputDisabled} value={christianSchool.sevedStudentsNumber}
                                                    onChange={handleChangeField}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="block columns">
                                        <div className="column columns is-mobile">
                                            <div className="column">
                                                <InputControl
                                                    type="number"
                                                    label="Grupos que Formaron"
                                                    placeholder="Cantidad"
                                                    form-index="5"
                                                    field="groupsNumber"
                                                    disabled={inputDisabled} value={christianSchool.groupsNumber}
                                                    onChange={handleChangeField}
                                                    required
                                                />
                                            </div>
                                            <div className="column">
                                                <InputControl
                                                    type="number"
                                                    label="Grupos"
                                                    placeholder="Cantidad"
                                                    form-index="5"
                                                    field="groups2Number"
                                                    disabled={inputDisabled} value={christianSchool.groups2Number}
                                                    onChange={handleChangeField}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="column columns is-mobile">
                                            <div className="column">
                                                <InputControl
                                                    type="number"
                                                    label="Total de Maestros"
                                                    placeholder="Cantidad"
                                                    form-index="5"
                                                    field="teachersNumber"
                                                    disabled={inputDisabled} value={christianSchool.teachersNumber}
                                                    onChange={handleChangeField}
                                                    required
                                                />
                                            </div>
                                            <div className="column">
                                                <InputControl
                                                    type="number"
                                                    label="Total de Ayudantes"
                                                    placeholder="Cantidad"
                                                    form-index="5"
                                                    field="assistanceNumber"
                                                    disabled={inputDisabled} value={christianSchool.assistanceNumber}
                                                    onChange={handleChangeField}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="block columns">
                                        <div className="column">
                                            <SelectControl
                                                label="¿Se está dando seguimiento a los niños “nuevos” que asistieron?"
                                                placeholder="Seleccionar"
                                                options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                                form-index="5"
                                                field="studentsFollowing"
                                                disabled={inputDisabled} value={christianSchool.studentsFollowing}
                                                onChange={handleChangeField}
                                                required
                                            />
                                        </div>
                                        {(christianSchool.studentsFollowing === "1") &&
                                            <div className="column">
                                                <TextareaControl
                                                    placeholder="¿Cómo se está haciendo?"
                                                    form-index="5"
                                                    field="studentsFollowingExplain"
                                                    disabled={inputDisabled} value={christianSchool.studentsFollowingExplain}
                                                    onChange={handleChangeField}
                                                    required
                                                />
                                            </div>
                                        }
                                        
                                    </div>
                                </Fragment>
                            }
                            {!inputDisabled &&
                                <div className="block">
                                    <button type="submit" className="button is-link is-fullwidth" disabled={formLoading}>
                                        <Icon name="save" />
                                        <span>Guardar</span>
                                    </button>
                                </div>
                            }
                        </div>

                    </form>
                    
                    <HeaderTitle subtitle="Música y alabanza" />
                    <form onSubmit={handleSubmit} form-index="6">
                        <div className="box">
                            <div className="block">
                                <InputControl
                                    label="Nombre del presidente de la Comision Local de Música y Alabanza"
                                    type="text"
                                    placeholder="Nombre Completo"
                                    form-index="6"
                                    field="presidentName"
                                    disabled={inputDisabled} value={music.presidentName}
                                    onChange={handleChangeField}
                                    required
                                />
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <InputControl
                                        type="text"
                                        label="Teléfono (o Celular)"
                                        placeholder="Número de Teléfono"
                                        iconLeft="smartphone"
                                        form-index="6"
                                        field="presidentPhone"
                                        disabled={inputDisabled} value={music.presidentPhone}
                                        onChange={handleChangeField}
                                    />
                                </div>
                                <div className="column">
                                    <InputControl
                                        type="email"
                                        label="Correo Electrónico"
                                        placeholder="Correo electrónico"
                                        iconLeft="mail"
                                        form-index="6"
                                        field="presidentEmail"
                                        disabled={inputDisabled} value={music.presidentEmail}
                                        onChange={handleChangeField}
                                    />
                                </div>
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <InputControl
                                        label="¿Cuántas células de personas nuevas preside este funcionario?"
                                        type="number"
                                        placeholder="Cantidad"
                                        form-index="6"
                                        field="newMembersGroups"
                                        disabled={inputDisabled} value={music.newMembersGroups}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                <div className="column">
                                    <InputControl
                                        label="Total de personas que asisten"
                                        type="number"
                                        placeholder="Cantidad"
                                        form-index="6"
                                        field="newMembersGroupsPeople"
                                        disabled={inputDisabled} value={music.newMembersGroupsPeople}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="box">
                            <div className="block">
                                <InputControl
                                    type="number"
                                    label="Total de personas que participan en este ministerio en su Iglesia (Coros, grupos corales, solistas, directores de canto congregacional, etc.)"
                                    placeholder="Cantidad"
                                    form-index="6"
                                    field="participants"
                                    disabled={inputDisabled} value={music.participants}
                                    onChange={handleChangeField}
                                    required
                                />
                            </div>
                            <div className="block">
                                <SelectControl
                                    label="¿Su Iglesia utiliza el himnario metodista en sus cultos y actividades?"
                                    placeholder="Seleccionar"
                                    options={[{value:"1",label:"Siempre"},{value:"2",label:"Casi Siempre"},{value:"3",label:"Casi Nunca"},{value:"0",label:"Nunca"}]}
                                    form-index="6"
                                    field="methodistHymnbook"
                                    disabled={inputDisabled} value={music.methodistHymnbook}
                                    onChange={handleChangeField}
                                    required
                                />
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <SelectControl
                                        label="¿Su Iglesia utiliza otros himnarios o música cristiana, aparte del himnario metodista, o en lugar del himnario metodista en cultos y actividades?"
                                        placeholder="Seleccionar"
                                        options={[{value:"1",label:"Sí, sólo otros"},{value:"2",label:"Sí, ambos"},{value:"0",label:"No"}]}
                                        form-index="6"
                                        field="otherHymnbook"
                                        disabled={inputDisabled} value={music.otherHymnbook}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                {(music.otherHymnbook === "1" || music.otherHymnbook === "2") &&
                                    <div className="column">
                                        <TextareaControl
                                            placeholder="Especifique"
                                            form-index="6"
                                            field="otherHymnbookExplain"
                                            disabled={inputDisabled} value={music.otherHymnbookExplain}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                }
                                
                            </div>
                            <div className="block">
                                <SelectControl
                                    label="¿Su Iglesia utiliza acompañamiento instrumental musical (en vivo o en audio digital) en la adoración y alabanza?"
                                    placeholder="Seleccionar"
                                    options={[{value:"1",label:"En vivo"},{value:"2",label:"Digital"},{value:"3",label:"Ambos",value:"0",label:"No utilizamos"}]}
                                    form-index="6"
                                    field="musicalBacking"
                                    disabled={inputDisabled} value={music.musicalBacking}
                                    onChange={handleChangeField}
                                    required
                                />
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <SelectControl
                                        label="¿En su Iglesia se cuida el contenido doctrinal en la alabanza?"
                                        placeholder="Seleccionar"
                                        options={[{value:"1",label:"Siempre"},{value:"2",label:"Casi Siempre"},{value:"3",label:"Casi Nunca"},{value:"0",label:"Nunca"}]}
                                        form-index="6"
                                        field="doctrine"
                                        disabled={inputDisabled} value={music.doctrine}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                {(music.doctrine === "1" || music.doctrine === "2" || music.doctrine === "3") &&
                                    <div className="column">
                                        <TextareaControl
                                            placeholder="Especifique cómo:"
                                            form-index="6"
                                            field="doctrineExplain"
                                            disabled={inputDisabled} value={music.doctrineExplain}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                }
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <SelectControl
                                        label="¿En su Iglesia se cuida el balance adecuado entre alabanza y predicación durante los cultos?"
                                        placeholder="Seleccionar"
                                        options={[{value:"1",label:"Siempre"},{value:"2",label:"Casi Siempre"},{value:"3",label:"Casi Nunca"},{value:"0",label:"Nunca"}]}
                                        form-index="6"
                                        field="balance"
                                        disabled={inputDisabled} value={music.balance}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                {(music.balance === "1" || music.balance === "2" || music.balance === "3") &&
                                    <div className="column">
                                        <TextareaControl
                                            placeholder="Especifique cómo:"
                                            form-index="6"
                                            field="balanceExplain"
                                            disabled={inputDisabled} value={music.balanceExplain}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                }
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <SelectControl
                                        label="¿En su Iglesia se practica regularmente la reflexión sobre el ministerio musical con los miembros de grupos de alabanza y/o coros sobre su ministerio musical?"
                                        placeholder="Seleccionar"
                                        options={[{value:"1",label:"Siempre"},{value:"2",label:"Casi Siempre"},{value:"3",label:"Casi Nunca"},{value:"0",label:"Nunca"}]}
                                        form-index="6"
                                        field="reflection"
                                        disabled={inputDisabled} value={music.reflection}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                {(music.reflection === "1" || music.reflection === "2" || music.reflection === "3") &&
                                    <div className="column">
                                        <TextareaControl
                                            placeholder="Especifique cómo:"
                                            form-index="6"
                                            field="reflectionExplain"
                                            disabled={inputDisabled} value={music.reflectionExplain}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                }
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <SelectControl
                                        label="¿En su Iglesia se realizan regularmente acciones tendientes a la mejora en la interpretación musical?"
                                        placeholder="Seleccionar"
                                        options={[{value:"1",label:"Siempre"},{value:"2",label:"Casi Siempre"},{value:"3",label:"Casi Nunca"},{value:"0",label:"Nunca"}]}
                                        form-index="6"
                                        field="upgrade"
                                        disabled={inputDisabled} value={music.upgrade}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                {(music.upgrade === "1" || music.upgrade === "2" || music.upgrade === "3") &&
                                    <div className="column">
                                        <TextareaControl
                                            placeholder="Especifique cómo:"
                                            form-index="6"
                                            field="upgradeExplain"
                                            disabled={inputDisabled} value={music.upgradeExplain}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                }
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <SelectControl
                                        label="¿Conocen el calendario litúrgico con sugerencias de himnos y alabanzas contemporáneas para cada domingo?"
                                        placeholder="Seleccionar"
                                        options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                        form-index="6"
                                        field="calendar"
                                        disabled={inputDisabled} value={music.calendar}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                {(music.calendar === "1") &&
                                    <Fragment>
                                        <div className="column">
                                            <SelectControl
                                                label="¿Utilizan esa calendarización?"
                                                placeholder="Seleccionar"
                                                options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                                form-index="6"
                                                field="calendarUsed"
                                                disabled={inputDisabled} value={music.calendarUsed}
                                                onChange={handleChangeField}
                                                required
                                            />
                                        </div>
                                        {(music.calendarUsed === "0") &&
                                            <div className="column">
                                                <TextareaControl
                                                    placeholder="Si respondió: no. ¿Por qué no?"
                                                    form-index="6"
                                                    field="noCalendarUsedExplain"
                                                    disabled={inputDisabled} value={music.noCalendarUsedExplain}
                                                    onChange={handleChangeField}
                                                    required
                                                />
                                            </div>
                                        }
                                    </Fragment>
                                }
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <SelectControl
                                        label="¿En su iglesia implementan un plan de trabajo basado en los lineamientos nacionales de música?"
                                        placeholder="Seleccionar"
                                        options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                        form-index="6"
                                        field="workplan"
                                        disabled={inputDisabled} value={music.workplan}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                {(music.workplan === "0") &&
                                    <div className="column">
                                        <TextareaControl
                                            placeholder="Si respondió: no. ¿Por qué no?"
                                            form-index="6"
                                            field="noWorkplanExplain"
                                            disabled={inputDisabled} value={music.noWorkplanExplain}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                }
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <SelectControl
                                        label="¿En su iglesia utiliza el himnario metodista de partituras en versión digital, o impresa?"
                                        placeholder="Seleccionar"
                                        options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                        form-index="6"
                                        field="hymnalSheetMusic"
                                        disabled={inputDisabled} value={music.hymnalSheetMusic}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                {(music.workplan === "0") &&
                                    <div className="column">
                                        <TextareaControl
                                            placeholder="Si respondió: no. ¿Por qué no?"
                                            form-index="6"
                                            field="noHymnalSheetMusicExplain"
                                            disabled={inputDisabled} value={music.noHymnalSheetMusicExplain}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                }
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <SelectControl
                                        label="¿En su iglesia conocen de las grabaciones del acompañamiento musical de himnos en MP3 que se encuentra en la página de la IMMAR?"
                                        placeholder="Seleccionar"
                                        options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                        form-index="6"
                                        field="musicBackMp3"
                                        disabled={inputDisabled} value={music.musicBackMp3}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                {(music.musicBackMp3 === "1") &&
                                    <div className="column">
                                        <SelectControl
                                            label="¿Los usan?"
                                            placeholder="Seleccionar"
                                            options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                            form-index="6"
                                            field="musicBackMp3Used"
                                            disabled={inputDisabled} value={music.musicBackMp3Used}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                }
                                {(music.musicBackMp3Used === "0") &&
                                    <div className="column">
                                        <TextareaControl
                                            placeholder="Si respondió: no. ¿Por qué no?"
                                            form-index="6"
                                            field="noMusicBackMp3Explain"
                                            disabled={inputDisabled} value={music.noMusicBackMp3Explain}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                }
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <SelectControl
                                        label="¿Alguno de los integrantes de su iglesia ha participado en el Coro Magno de la IMMAR?"
                                        placeholder="Seleccionar"
                                        options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                        form-index="6"
                                        field="greatChoir"
                                        disabled={inputDisabled} value={music.greatChoir}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                {(music.greatChoir === "1") &&
                                    <div className="column">
                                        <InputControl
                                            placeholder="¿Cuántos?"
                                            type="number"
                                            form-index="6"
                                            field="greatChoirParticipants"
                                            disabled={inputDisabled} value={music.greatChoirParticipants}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                }
                            </div>
                            <div className="block">
                                <SelectControl
                                    label="¿Alguno de los integrantes de su iglesia le gustaría participar en el Coro Magno de la IMMAR?"
                                    placeholder="Seleccionar"
                                    options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                    form-index="6"
                                    field="greatChoirInvitation"
                                    disabled={inputDisabled} value={music.greatChoirInvitation}
                                    onChange={handleChangeField}
                                    required
                                />
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <SelectControl
                                        label="¿Conocen los Lineamientos Nacionales de Música y Alabanza?"
                                        placeholder="Seleccionar"
                                        options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                        form-index="6"
                                        field="nationalGuidelines"
                                        disabled={inputDisabled} value={music.nationalGuidelines}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                {(music.nationalGuidelines === "1") &&
                                    <div className="column">
                                        <SelectControl
                                            label="¿Los aplican?"
                                            placeholder="Seleccionar"
                                            options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                            form-index="6"
                                            field="nationalGuidelinesUsed"
                                            disabled={inputDisabled} value={music.nationalGuidelinesUsed}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                }
                                {(music.nationalGuidelinesUsed === "0") &&
                                    <div className="column">
                                        <TextareaControl
                                            placeholder="Si respondió: no. ¿Por qué no?"
                                            form-index="6"
                                            field="noNationalGuidelinesUsedExplain"
                                            disabled={inputDisabled} value={music.noNationalGuidelinesUsedExplain}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                }
                            </div>
                            {!inputDisabled &&
                                <div className="block">
                                    <button type="submit" className="button is-link is-fullwidth" disabled={formLoading}>
                                        <Icon name="save" />
                                        <span>Guardar</span>
                                    </button>
                                </div>
                            }
                        </div>
                    </form>
                </Fragment>
            }
        </AppStructure>
    );
};

export default ChristianDevelopmentForm;