import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CheckboxControl, InputControl, MultipleOptionsControl, Option, RadioControl, SelectControl, TextareaControl } from "../../components/formControls";
import { Icon } from "../../components/icon";
import ProgressBar from "../../components/progressBar";
import { HeaderTitle } from "../../components/titles";
import useAPI from "../../connection/useAPI";
import { useAuth2 } from "../../functions/auth2Context";
import useLocalStorage from "../../functions/useLocalStorage";
import AppStructure from "../../structures/appStructure";

const ShepherdForm = () => {
    const [ itemsCompleted, setItemsCompleted ] = useState(0);
    const [ loading, setLoading ] = useState(false);
    const [ formLoading, setFormLoading ] = useState(false);
    const [ shepherdDataId, setShepherdDataId ] = useState(null);
    const [ inputDisabled, setInputDisabled ] = useState(false);
    const [ shepherdsData, setShepherdsData ] = useLocalStorage("SHEPHERDFORM_SHEPHERDSDATA",[{activities:[],trainings:[{}]}]);
    const [ adminData, setAdminData ] = useLocalStorage("SHEPHERDFORM_ADMINDATA",{});
    const [ evaulationData, setEvaluationData ] = useLocalStorage("SHEPHERDFORM_EVALUATIONDATA",{});
    const { currentUser } = useAuth2();
    const { shepherdsDataForm } = useAPI();
    const { churchId } = useParams();

    const formObjects = [shepherdsData,adminData,evaulationData];
    const valueSetters = [setShepherdsData,setAdminData,setEvaluationData];
    const formFields = ["shepherdsData","adminData","evaulationData"];

    useEffect(() => {
        setLoading(true);

        let cId = currentUser.uid;
        //setInputDisabled(true);
        
        if(churchId){
            cId = churchId;
            setInputDisabled(true);
        }
        const promises = [shepherdsDataForm.get(cId)];

        Promise.all(promises).then((response) => {
            fillServerData(response[0]);

            setLoading(false);
        });
    },[]);

    function fillServerData(shepherdsDataObject){
        if(shepherdsDataObject.id){
            let serverItemsCompleted = 0;

            formFields.forEach((field,fieldIndex) => {
                const fieldObject = JSON.parse(shepherdsDataObject[field]);

                if(fieldObject){
                    valueSetters[fieldIndex](fieldObject);
                    serverItemsCompleted++;
                }
            });

            setItemsCompleted(serverItemsCompleted);
            setShepherdDataId(shepherdsDataObject.id);
        }
    }


    function addShepherdData(){
        const object = JSON.parse(JSON.stringify(shepherdsData));
        object.push({activities:[],trainings:[{}]});
        
        setShepherdsData(object);
    }

    function handleChangeField(e){
        const formIndex = e.currentTarget.getAttribute("form-index");
        let fieldValue = e.currentTarget.value;
        if(typeof fieldValue === 'string'){
            fieldValue = fieldValue.replace(/(\r\n|\n|\r|")/gi,'');
        }
        const field = e.currentTarget.getAttribute("field");
        const object = JSON.parse(JSON.stringify(formObjects[formIndex]));
        object[field] = fieldValue;

        valueSetters[formIndex](object);
    }

    function handleSubmit(e){
        e.preventDefault();
        setFormLoading(true);

        const formIndex = e.currentTarget.getAttribute("form-index");
        shepherdsDataForm.save(currentUser.uid,
                        currentUser.uid,
                        shepherdDataId,
                        formFields[formIndex],
                        JSON.stringify(formObjects[formIndex])
        ).then((response) => {
            fillServerData(response);

            setFormLoading(false);
        });
    }

    return(
        <AppStructure>
            <HeaderTitle title="Formulario Pastoral" />
            <ProgressBar itemsToEvaluate={3} itemsCompleted={itemsCompleted} />
            <HeaderTitle
                subtitle="Pastores"
                description="Esta información es necesaria para cada ministro. Se deben agregar a todos los pastores asociados."
            />

            {shepherdsData.map((item,itemIndex) => {
                return(<ShepherdDataForm
                    key={item.name}
                    value={shepherdsData}
                    setValue={setShepherdsData}
                    index={itemIndex}
                    formLoading={formLoading}
                    disabled={inputDisabled}
                    onSubmit={handleSubmit}
                />);
            })}
            
            {!inputDisabled &&
                <div className="block">
                    <button className="button is-info" onClick={addShepherdData}>
                        <Icon name="add" />
                        <span>Agregar Otro Pastor</span>
                    </button>
                </div>
            }

            <HeaderTitle subtitle="Junta de Administradores" />
            <form onSubmit={handleSubmit} form-index="1">
                <div className="box">
                    <div className="block columns">
                        <div className="column">
                            <InputControl
                                type="number"
                                iconLeft="menu_book"
                                label="¿Cuántos administradores han sido capacitados aplicando el Manual para Funcionarios?"
                                placeholder="Cantidad"
                                form-index="1"
                                field="adminCapacitationAttendant"
                                disabled={inputDisabled}
                                value={adminData.adminCapacitationAttendant}
                                onChange={handleChangeField}
                                required
                            />
                        </div>
                        <div className="column">
                            <InputControl
                                type="number"
                                iconLeft="attach_money"
                                label="Si la iglesia promovió una ofrenda para el Seminario Metodista anote la cantidad aportada ( $ 0 implica que no se promovió)"
                                placeholder="Monto"
                                form-index="1"
                                field="seminaryOffering"
                                value={adminData.seminaryOffering}
                                disabled={inputDisabled}
                                onChange={handleChangeField}
                                required
                            />
                        </div>
                    </div>
                    <div className="block">
                        <p>Durante 2022</p>
                        <p>¿En qué formato realizaron mayormente las reuniones de administradores?</p>
                    </div>
                    <div className="block">
                        <SelectControl
                            label=""
                            placeholder="Seleccionar"
                            options={[{value:"1",label:"Virtual"},{value:"2",label:"Presencial"},{value:"3",label:"Híbrida"}]}
                            form-index="1"
                            field="meetingsFormat"
                            value={adminData.meetingsFormat}
                            disabled={inputDisabled}
                            onChange={handleChangeField}
                            required
                        />
                    </div>
                    {!inputDisabled &&
                        <div className="block">
                            <button className="button is-link is-fullwidth" disabled={formLoading} type="submit">
                                <Icon name="save" />
                                <span>Guardar</span>
                            </button>
                        </div>
                    }
                </div>
            </form>

            <HeaderTitle subtitle="Sobre la Evaluación Pastoral" />
            <form form-index="2" onSubmit={handleSubmit}>
                <div className="box">
                    <div className="block columns">
                        <div className="column">
                            <p>Número de administradores encuestados que acreditaron el desempeño del Pastor:</p>
                        </div>
                        <div className="column">
                            <InputControl
                                type="number"
                                placeholder="Cantidad"
                                form-index="2"
                                field="adminNumber"
                                value={evaulationData.adminNumber}
                                disabled={inputDisabled}
                                onChange={handleChangeField}
                                required
                            />
                        </div>
                    </div>
                    <div className="block columns">
                        <div className="column">
                            <p>Número total de administradores encuestados  en la Iglesia Local:</p>
                        </div>
                        <div className="column">
                            <InputControl
                                type="number"
                                placeholder="Cantidad"
                                form-index="2"
                                field="adminTotalNumber"
                                value={evaulationData.adminTotalNumber}
                                disabled={inputDisabled}
                                onChange={handleChangeField}
                                required
                            />
                        </div>
                    </div>
                    <div className="block columns">
                        <div className="column">
                            <p>Número de indicadores que alcanzaron sus metas del Programa de Trabajo de la Iglesia Local:</p>
                        </div>
                        <div className="column">
                            <InputControl
                                type="number"
                                placeholder="Cantidad"
                                form-index="2"
                                field="indicatorsGoals"
                                value={evaulationData.indicatorsGoals}
                                disabled={inputDisabled}
                                onChange={handleChangeField}
                                required
                            />
                        </div>
                    </div>
                    <div className="block columns">
                        <div className="column">
                            <p>Número total de indicadores del Programa de Trabajo de la Iglesia Local:</p>
                        </div>
                        <div className="column">
                            <InputControl
                                type="number"
                                placeholder="Cantidad"
                                form-index="2"
                                field="programIndicators"
                                value={evaulationData.programIndicators}
                                disabled={inputDisabled}
                                onChange={handleChangeField}
                                required
                            />
                        </div>
                    </div>
                    <div className="block columns">
                        <div className="column">
                            <p>Número de facultades y deberes disciplinarios cumplidos (Art. 335 de la Disciplina):</p>
                        </div>
                        <div className="column">
                            <InputControl
                                type="number"
                                placeholder="Cantidad"
                                form-index="2"
                                field="obligations"
                                value={evaulationData.obligations}
                                disabled={inputDisabled}
                                onChange={handleChangeField}
                                required
                            />
                        </div>
                    </div>
                    <div className="block columns">
                        <div className="column">
                            <p>Número total de facultades y deberes disciplinarios:</p>
                        </div>
                        <div className="column">
                            <InputControl
                                type="number"
                                placeholder="Cantidad"
                                form-index="2"
                                field="totalObligations"
                                value={evaulationData.totalObligations}
                                disabled={inputDisabled}
                                onChange={handleChangeField}
                                required
                            />
                        </div>
                    </div>
                    {!inputDisabled &&
                        <div className="block">
                            <button type="submit" className="button is-link is-fullwidth" disabled={formLoading}>
                                <Icon name="save" />
                                <span>Guardar</span>
                            </button>
                        </div>
                    }
                </div>
            </form>
        </AppStructure>
    );
};

export default ShepherdForm;

const ShepherdDataForm = (props) => {
    const {value, setValue, index, formLoading, onSubmit, disabled} = props;
    const syntheticEvent = {currentTarget:{value,getAttribute: function(attribute){
        return props[attribute];
    }}};

    const relationshipOptions = [
        {value:"1",label:"Pbro. I."},
        {value:"2",label:"Pbro. L."},
        {value:"3",label:"Predicador Local"},
        {value:"4",label:"Pastor Suplente"},
        {value:"5",label:"Pbro. Jubilado"},
        {value:"6",label:"Probandos la Conferencia"}
    ];
    const sexOptions = [
        {value:"1",label:"Mujer"},
        {value:"2",label:"Hombre"}
    ];
    const gradeOptions = [
        {value:"1",label:"Preparatoria"},
        {value:"5",label:"Técnico"},
        {value:"2",label:"Licenciatura"},
        {value:"3",label:"Maestría"},
        {value:"4",label:"Doctorado"}
    ];
    const seminarOptions = [
        {value:"1",label:"Ninguno"},
        {value:"2",label:"SMGBC"},
        {value:"3",label:"SMJW"},
        {value:"4",label:"SETE SMGBC"},
        {value:"5",label:"SETE SMJW"},
        {value:"6",label:"Otro"}
    ];

    function handleChange(e){
        const field = e.currentTarget.getAttribute("field");
        let internalValue = e.currentTarget.value;
        if(typeof internalValue === 'string'){
            internalValue = internalValue.replace(/(\r\n|\n|\r|")/gi,'');
        }
        const mainObject = JSON.parse(JSON.stringify(value));
        const object = JSON.parse(JSON.stringify(value[index]));
        object[field] = internalValue;
        mainObject[index] = object;

        console.log(internalValue);
        setValue(mainObject);
    }

    function addTrainingData(){
        const mainObject = JSON.parse(JSON.stringify(value));
        const object = JSON.parse(JSON.stringify(value[index]));
        object.trainings.push({});
        mainObject[index] = object;

        setValue(mainObject);
    }

    return(
        <div className="box">
            <form onSubmit={onSubmit} form-index="0">
                <div className="block">
                    <SelectControl
                        label="Relación con la IMMAR"
                        placeholder="Selecciona Alguno"
                        options={relationshipOptions}
                        field="immarRelationship"
                        disabled={disabled} value={value[index].immarRelationship}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="block columns">
                    <div className="column">
                        <InputControl
                            label="Nombre Completo"
                            placeholder="Nombre/s"
                            type="text"
                            field="firstName"
                            disabled={disabled} value={value[index].firstName}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="column">
                        <InputControl
                            label="&nbsp;"
                            placeholder="Apellido Paterno"
                            type="text"
                            field="fatherLastName"
                            disabled={disabled} value={value[index].fatherLastName}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="column">
                        <InputControl
                            label="&nbsp;"
                            placeholder="Apellido Materno"
                            type="text"
                            field="motherLastName"
                            disabled={disabled} value={value[index].motherLastName}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                <div className="block columns">
                    <div className="column">
                        <SelectControl
                            label="Sexo"
                            placeholder="Sexo"
                            options={sexOptions}
                            field="sex"
                            disabled={disabled} value={value[index].sex}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="column">
                        <InputControl
                            label="Fecha de Nacimiento"
                            type="date"
                            field="birthDay"
                            disabled={disabled} value={value[index].birthDay}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                <div className="block columns">
                    <div className="column">
                        <InputControl
                            label="Correo Electrónico"
                            placeholder="Correo Electrónico"
                            type="email"
                            field="email"
                            disabled={disabled} value={value[index].email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="column">
                        <InputControl
                            label="Número de Celular"
                            placeholder="Número de Celular"
                            type="text"
                            field="phone"
                            disabled={disabled} value={value[index].phone}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                <div className="block columns">
                    <div className="column">
                        <SelectControl
                            label="Escolaridad Secular"
                            placeholder="Último Grado de Estudios"
                            options={gradeOptions}
                            field="educationLevel"
                            disabled={disabled} value={value[index].educationLevel}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="column">
                        <InputControl
                            label="&nbsp;"
                            placeholder="Institución"
                            type="text"
                            field="school"
                            disabled={disabled} value={value[index].school}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="column">
                        <InputControl
                            label="&nbsp;"
                            placeholder="Título/Cédula Prof."
                            type="text"
                            field="certificate"
                            disabled={disabled} value={value[index].certificate}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                <div className="block columns">
                    <div className="column">
                        <SelectControl
                            label="Estudios Teológicos"
                            placeholder="Seminario de Egreso"
                            options={seminarOptions}
                            field="seminary"
                            disabled={disabled} value={value[index].seminary}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="column">
                        <SelectControl
                            label="&nbsp;"
                            placeholder="Nivel"
                            options={gradeOptions}
                            field="seminaryLevel"
                            disabled={disabled} value={value[index].seminaryLevel}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="column">
                        <InputControl
                            label="&nbsp;"
                            placeholder="Año de Egreso"
                            type="text"
                            field="seminaryGraduationYear"
                            disabled={disabled} value={value[index].seminaryGraduationYear}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                <div className="block columns">
                    <div className="column">
                        <InputControl
                            label="Inicio de Servicio en la IMMAR"
                            type="date"
                            field="immarStartingYear"
                            disabled={disabled} value={value[index].immarStartingYear}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="column">
                        <InputControl
                            label="Recepción en Plena Conexión"
                            type="date"
                            field="immarConnectionYear"
                            disabled={disabled} value={value[index].immarConnectionYear}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                <div className="block columns">
                    <div className="column">
                        <InputControl
                            label="Nombramiento Actual"
                            placeholder="Pastor Tiempo Completo, Pastor Tiempo Parcial, etc."
                            type="text"
                            field="currentAppointment"
                            disabled={disabled} value={value[index].currentAppointment}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="column">
                        <InputControl
                            label="Fecha de Nombramiento"
                            type="date"
                            field="appointmentDate"
                            disabled={disabled} value={value[index].appointmentDate}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>

                <div className="block">
                    <TextareaControl
                        label="Escribe en orden los compromisos (cargos pastorales y puestos administrativos) que has tenido desde tu ingreso"
                        field="immarPositions"
                        disabled={disabled} value={value[index].immarPositions}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="block">
                    <h4 className="title is-4">Otra Información</h4>
                    <p>Actividades que realizas con regularidad</p>
                </div>
                <div className="block">
                    <MultipleOptionsControl
                        description="En su iglesia se realizaron:"
                        columnWeight="4"
                        field="activities"
                        disabled={disabled} value={value[index].activities}
                        onChange={handleChange}
                    >
                        <Option>Lectura</Option>
                        <Option>Música</Option>
                        <Option>Deporte</Option>
                        <Option>Escritura</Option>
                        <Option>Docencia</Option>
                        <Option>Otro</Option>
                    </MultipleOptionsControl>
                </div>
                {(value[index].activities[5]) &&
                    <div className="block">
                        <InputControl
                            placeholder="Especificar otra Actividad"
                            type="text"
                            field="otherActivities"
                            disabled={disabled} value={value[index].otherActivities}
                            onChange={handleChange}
                            required
                        />
                    </div>
                }
                <div className="block">
                    <p>La Comision de Continuidad Pastoral, interesada en tu preparación, quiere conocer la forma más efectiva de ayudarte, para que te sigas preparando para la obra del ministerio</p>
                </div>
                <div className="block">
                    <TextareaControl
                        placeholder="Anota los temas, que en tu opinión, se deben abordar en próximas ocasiones. (Separados por comas [,])"
                        field="themes"
                        disabled={disabled} value={value[index].themes}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="block">
                    <TextareaControl
                        placeholder="Indica el curso, taller o tema de capacitación que te pueden servir para tu preparación. (Separados por comas [,])"
                        field="courses"
                        disabled={disabled} value={value[index].courses}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="block">
                    <SelectControl
                        label="Durante el año 2022 ¿Tuviste algún tipo de capacitación?"
                        placeholder="Seleccionar"
                        options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                        field="trainingExists"
                        disabled={disabled} value={value[index].trainingExists}
                        onChange={handleChange}
                        required
                    />
                </div>
                {(value[index].trainingExists === "1") &&
                    <Fragment>
                        <div className="block">
                            <p>Indica Curso, insituticón que impartió y total de horas de duración</p>
                        </div>
                        {value[index].trainings.map((item,itemIndex) => {
                            return(<CoursesForm key={item.name} value={value} index={index} itemIndex={itemIndex} setValue={setValue} disabled={disabled} />);
                        })}
                        {!disabled &&
                            <div className="block">
                                <button type="button" className="button" onClick={addTrainingData}>
                                    <Icon name="add_circle" />
                                    <span>Agregar Otro Curso</span>
                                </button>
                            </div>
                        }
                    </Fragment>
                }
                <div className="block columns">
                    <div className="column">
                        <InputControl
                            type="number"
                            label="¿Cuántas células de personas nuevas tiene el pastor?"
                            placeholder="Cantidad"
                            field="newPeopleGroups"
                            disabled={disabled} value={value[index].newPeopleGroups}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="column">
                        <InputControl
                            type="number"
                            label="Total de personas que asisten"
                            placeholder="Cantidad"
                            field="newPeopleGroupsAttendant"
                            disabled={disabled} value={value[index].newPeopleGroupsAttendant}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                {!disabled &&
                    <div className="block">
                        <button type="submit" className="button is-link is-fullwidth" disabled={formLoading}>
                            <Icon name="save" />
                            <span>Guardar</span>
                        </button>
                    </div>
                }
            </form>
        </div>
    );
};

export const CoursesForm = ({value,index,itemIndex,setValue,disabled}) => {
    function handleChange(e){
        const field = e.currentTarget.getAttribute("field");
        let internalValue = e.currentTarget.value;
        if(typeof internalValue === 'string'){
            internalValue = internalValue.replace(/(\r\n|\n|\r|")/gi,'');
        }
        const mainObject = JSON.parse(JSON.stringify(value));
        const object = JSON.parse(JSON.stringify(value[index].trainings[itemIndex]));
        object[field] = internalValue;
        mainObject[index].trainings[itemIndex] = object;

        setValue(mainObject);
    }

    return(
        <div className="block columns">
            <div className="column">
                <InputControl
                    placeholder="Nombre del Curso"
                    type="text"
                    field="name"
                    disabled={disabled} value={value[index].trainings[itemIndex].name}
                    onChange={handleChange}
                    required
                />
            </div>
            <div className="column">
                <InputControl
                    placeholder="Institución que Impartió"
                    type="text"
                    field="school"
                    disabled={disabled} value={value[index].trainings[itemIndex].school}
                    onChange={handleChange}
                    required
                />
            </div>
            <div className="column">
                <InputControl
                    placeholder="Horas Efectivas"
                    type="number"
                    field="hours"
                    disabled={disabled} value={value[index].trainings[itemIndex].hours}
                    onChange={handleChange}
                    required
                />
            </div>
            {/*<div className="column is-2">
                <button className="button is-danger is-fullwidth">
                    <Icon name="delete" />
                </button>
            </div>*/}
        </div>
    );
};