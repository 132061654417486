import "../node_modules/bulma/css/bulma.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./functions/authContext";
import Login from "./pages/access/login";
import PrivateRouter from "./components/privateRouter";
import Dashboard from "./pages/general/dashboard";
import ForgotPassword from "./pages/access/forgotPassword";
import Register from "./pages/access/register";
import ChurchInfo from "./pages/church/churchInfo";
import ProgramForm from "./pages/estimmarForm/programForm";
import { Auth2Provider } from "./functions/auth2Context";
import ShepherdForm from "./pages/estimmarForm/shepherdForm";
import ChristianDevelopmentForm from "./pages/estimmarForm/christianDevelopmentForm";
import OrganizationsForm from "./pages/estimmarForm/organizationsForm";
import ChristianTestimonyForm from "./pages/estimmarForm/christianTestimonyForm";
import FinanceForm from "./pages/estimmarForm/financeForm";
import PatrimonyForm from "./pages/estimmarForm/patrimonyForm";
import ReportProgramForm from "./pages/formReports/programForm";
import ReportShepherdsForm from "./pages/formReports/shepherdForm";
import ReportChristianGrowthForm from "./pages/formReports/christianGrowthForm";
import ReportOrganizationsForm from "./pages/formReports/organizationsForm";
import ReportChristianTestimonyForm from "./pages/formReports/christianTestimonyForm";
import ReportFinanceForm from "./pages/formReports/financeForm";
import ReportPatrimonyForm from "./pages/formReports/patrimonyForm";
import GeneralPercentages from "./pages/formReports/generalPercentages";
import History from "./pages/formReports/history";

function App() {
  return (
    <BrowserRouter>
      <Auth2Provider>
        <Routes>
          {/* General */}
          <Route path="/" element={<PrivateRouter><Dashboard /></PrivateRouter>} />

          {/* Access */}
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />

          {/* Church */}
          <Route path="/church-form" element={<PrivateRouter><ChurchInfo /></PrivateRouter>} />

          {/* estIMMAR Form */}
          <Route path="/form/program" element={<PrivateRouter><ProgramForm /></PrivateRouter>} />
          <Route path="/form/shepherds" element={<PrivateRouter><ShepherdForm /></PrivateRouter>} />
          <Route path="/form/christian-growth" element={<PrivateRouter><ChristianDevelopmentForm /></PrivateRouter>} />
          <Route path="/form/organizations" element={<PrivateRouter><OrganizationsForm /></PrivateRouter>} />
          <Route path="/form/christian-testimony" element={<PrivateRouter><ChristianTestimonyForm /></PrivateRouter>} />
          <Route path="/form/finance" element={<PrivateRouter><FinanceForm /></PrivateRouter>} />
          <Route path="/form/patrimony" element={<PrivateRouter><PatrimonyForm /></PrivateRouter>} />

          {/* Filling Reports */}
          <Route path="/form-reports/program" element={<PrivateRouter><ReportProgramForm /></PrivateRouter>} />
          <Route path="/form-reports/program-data/:churchId" element={<PrivateRouter><ProgramForm /></PrivateRouter>} />
          <Route path="/form-reports/shepherds" element={<PrivateRouter><ReportShepherdsForm /></PrivateRouter>} />
          <Route path="/form-reports/shepherds-data/:churchId" element={<PrivateRouter><ShepherdForm /></PrivateRouter>} />
          <Route path="/form-reports/christian-growth" element={<PrivateRouter><ReportChristianGrowthForm /></PrivateRouter>} />
          <Route path="/form-reports/christian-growth-data/:churchId" element={<PrivateRouter><ChristianDevelopmentForm /></PrivateRouter>} />
          <Route path="/form-reports/organizations" element={<PrivateRouter><ReportOrganizationsForm /></PrivateRouter>} />
          <Route path="/form-reports/organizations-data/:churchId" element={<PrivateRouter><OrganizationsForm /></PrivateRouter>} />
          <Route path="/form-reports/christian-testimony" element={<PrivateRouter><ReportChristianTestimonyForm /></PrivateRouter>} />
          <Route path="/form-reports/christian-testimony-data/:churchId" element={<PrivateRouter><ChristianTestimonyForm /></PrivateRouter>} />
          <Route path="/form-reports/finance" element={<PrivateRouter><ReportFinanceForm /></PrivateRouter>} />
          <Route path="/form-reports/finance-data/:churchId" element={<PrivateRouter><FinanceForm /></PrivateRouter>} />
          <Route path="/form-reports/patrimony" element={<PrivateRouter><ReportPatrimonyForm /></PrivateRouter>} />
          <Route path="/form-reports/patrimony-data/:churchId" element={<PrivateRouter><PatrimonyForm /></PrivateRouter>} />
          <Route path="/form-reports/history" element={<PrivateRouter><History /></PrivateRouter>} />
          {/* Filling General Reports */}
          <Route path="/form-reports/general" element={<PrivateRouter><GeneralPercentages /></PrivateRouter>} />
        </Routes>
      </Auth2Provider>
    </BrowserRouter>
  );
}

export default App;
