import { useRef, useState } from "react";
import { useAuth } from "../../functions/authContext";
import { useNavigate, Link } from "react-router-dom";
import logo from "../../assets/img/logo_estimmar.png";
import useAPI from "../../connection/useAPI";

const Register = () => {
    const emailRef = useRef();
    const passwordRef = useRef();
    const nameRef = useRef();
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState("");
    const { register } = useAuth();
    const navigate = useNavigate();
    const { votes } = useAPI();

    async function handleSubmit(e){
        e.preventDefault();

        try{
            setError("");
            setLoading(true);

            await register(emailRef.current.value,passwordRef.current.value).then(response => {
                votes.registerUser(response.user.uid,emailRef.current.value,nameRef.current.value).then((result) => {
                    if(result.code === 200)
                        navigate("/");
                });
            });

            
        }catch(err){
            setError("Algo salió mal con tus datos, ¡Intenta de nuevo o prueba con otro correo electrónico!");
            console.log(err);
            setLoading(false);
        }
    }

    return(
        <div className="access-page">
            <div className="box">
                <img src={logo} alt="IMMAR online" />

                <div className="block">
                    { error && <div className="notification is-danger">{error}</div>}
                </div>

                <div className="block">
                    <form onSubmit={handleSubmit}>
                        <div className="field">
                            <input className="input" ref={emailRef} type="email" placeholder="Correo Electrónico" />
                        </div>
                        <div className="field">
                            <input className="input" ref={passwordRef} type="password" placeholder="Contraseña" />
                        </div>
                        <div className="field">
                            <input className="input" ref={nameRef} type="text" placeholder="Nombre Completo" />
                        </div>
                        <div className="is-centered">
                            <button className="button is-link" disabled={loading} type="submit">Entrar</button>
                        </div>
                    </form>
                </div>
                
                <div className="block" style={{textAlign:"center"}}>
                    <Link to="/login">Ya tengo una cuenta</Link>
                </div>
            </div>
        </div>
    );
};

export default Register;