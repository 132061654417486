import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { InputControl, MultipleOptionsControl, Option, SelectControl, TextareaControl } from "../../components/formControls";
import { Icon } from "../../components/icon";
import ProgressBar from "../../components/progressBar";
import { HeaderTitle } from "../../components/titles";
import useAPI from "../../connection/useAPI";
import { useAuth2 } from "../../functions/auth2Context";
import useLocalStorage from "../../functions/useLocalStorage";
import AppStructure from "../../structures/appStructure";

const PatrimonyForm = () => {
    const [ itemsCompleted, setItemsCompleted ] = useState(0);
    const [ loading, setLoading ] = useState(false);
    const [ formLoading, setFormLoading ] = useState(false);
    const [ patrimonyDataId, setpatrimonyDataId ] = useState(null);
    const [ inputDisabled, setInputDisabled ] = useState(false);
    const [ presidentData, setPresidentData ] = useLocalStorage("PATRIMONYFORM_PRESIDENTDATA",{});
    const [ estateData, setEstateData ] = useLocalStorage("PATRIMONYFORM_ESTATEDATA",{conferenceKnowPropertiesExplain:[]});
    const { currentUser } = useAuth2();
    const { patrimonyData } = useAPI();
    const { churchId } = useParams();

    const formObjects = [presidentData,estateData];
    const valueSetters = [setPresidentData,setEstateData];
    const formFields = ["presidentData","estateData"];

    useEffect(() => {
        setLoading(true);

        let cId = currentUser.uid;
        //setInputDisabled(true);
        if(churchId){
            cId = churchId;
            setInputDisabled(true);
        }
        const promises = [patrimonyData.get(cId)];

        Promise.all(promises).then((response) => {
            fillServerData(response[0]);

            setLoading(false);
        });
    },[]);

    function fillServerData(christianTestimonyObject){
        if(christianTestimonyObject.id){
            let serverItemsCompleted = 0;

            formFields.forEach((field,fieldIndex) => {
                const fieldObject = JSON.parse(christianTestimonyObject[field]);

                if(fieldObject){
                    valueSetters[fieldIndex](fieldObject);
                    serverItemsCompleted++;
                }
            });

            setItemsCompleted(serverItemsCompleted);
            setpatrimonyDataId(christianTestimonyObject.id);
        }
    }

    function handleChangeField(e){
        const formIndex = e.currentTarget.getAttribute("form-index");
        let fieldValue = e.currentTarget.value;
        if(typeof fieldValue === 'string'){
            fieldValue = fieldValue.replace(/(\r\n|\n|\r|")/gi,'');
        }
        const field = e.currentTarget.getAttribute("field");
        const object = JSON.parse(JSON.stringify(formObjects[formIndex]));
        object[field] = fieldValue;

        valueSetters[formIndex](object);
    }

    function handleSubmit(e){
        e.preventDefault();
        setFormLoading(true);

        const formIndex = e.currentTarget.getAttribute("form-index");

        patrimonyData.save(currentUser.uid,
                        currentUser.uid,
                        patrimonyDataId,
                        formFields[formIndex],
                        JSON.stringify(formObjects[formIndex])
        ).then((response) => {
            fillServerData(response);

            setFormLoading(false);
        });
    }

    return(
        <AppStructure>
            <HeaderTitle title="Formulario de CONAPAT" />
            <ProgressBar itemsToEvaluate={2} itemsCompleted={itemsCompleted} />

        {(loading) ? <p>Cargando Datos...</p> :
            <Fragment>
                <form form-index="0" onSubmit={handleSubmit}>
                    <div className="box">
                        <div className="block">
                            <InputControl
                                type="text"
                                label="Nombre del Responsable local del cuidado del patrimonio de la iglesia."
                                placeholder="Nombre completo"
                                iconLeft="person"
                                form-index="0"
                                field="name"
                                disabled={inputDisabled} value={presidentData.name}
                                onChange={handleChangeField}
                                required
                            />
                        </div>
                        <div className="block columns">
                            <div className="column">
                                <InputControl
                                    type="text"
                                    label="Teléfono (o Celular)"
                                    placeholder="Número de Teléfono"
                                    iconLeft="smartphone"
                                    form-index="0"
                                    field="phone"
                                    disabled={inputDisabled} value={presidentData.phone}
                                    onChange={handleChangeField}
                                />
                            </div>
                            <div className="column">
                                <InputControl
                                    type="email"
                                    label="Correo Electrónico"
                                    placeholder="Correo electrónico"
                                    iconLeft="mail"
                                    form-index="0"
                                    field="email"
                                    disabled={inputDisabled} value={presidentData.email}
                                    onChange={handleChangeField}
                                />
                            </div>
                        </div>
                        <div className="block columns">
                            <div className="column">
                                <InputControl
                                    label="¿Cuántas células de personas nuevas preside este funcionario?"
                                    type="number"
                                    placeholder="Cantidad"
                                    form-index="0"
                                    field="newPeopleGroups"
                                    disabled={inputDisabled} value={presidentData.newPeopleGroups}
                                    onChange={handleChangeField}
                                    required
                                />
                            </div>
                            <div className="column">
                                <InputControl
                                    label="Total de personas que asisten"
                                    type="number"
                                    placeholder="Cantidad"
                                    form-index="0"
                                    field="newPeopleGroupsAttendance"
                                    disabled={inputDisabled} value={presidentData.newPeopleGroupsAttendance}
                                    onChange={handleChangeField}
                                    required
                                />
                            </div>
                        </div>
                        {!inputDisabled &&
                            <div className="block">
                                <button type="submit" className="button is-link is-fullwidth" disabled={formLoading}>
                                    <Icon name="save" />
                                    <span>Guardar</span>
                                </button>
                            </div>
                        }
                    </div>
                </form>

                <HeaderTitle subtitle="Inmuebles" />
                <form form-index="1" onSubmit={handleSubmit}>
                    <div className="box">
                        <div className="block">
                            <InputControl
                                type="number"
                                label="Indique el número total de inmuebles (terrenos, casas y/o templos) con los que cuenta su Iglesia local)."
                                placeholder="Cantidad"
                                form-index="1"
                                field="total"
                                disabled={inputDisabled} value={estateData.total}
                                onChange={handleChangeField}
                                required
                            />
                        </div>
                        {(estateData.total !== "0" && estateData.total !== "" && estateData.total) &&
                            <Fragment>
                                <div className="block">
                                    <label className="label">Del número de inmuebles enunciados en el apartado anterior escriba cuántos se encuentran en los siguientes supuestos:</label>
                                </div>
                                <div className="block columns">
                                    <div className="column">
                                        <InputControl
                                            type="number"
                                            label="Registrado en SEGOB"
                                            placeholder="Cantidad"
                                            form-index="1"
                                            field="registerI"
                                            disabled={inputDisabled} value={estateData.registerI}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                    <div className="column">
                                        <InputControl
                                            type="number"
                                            label="Expediente en INDAABIN"
                                            placeholder="Cantidad"
                                            form-index="1"
                                            field="registerII"
                                            disabled={inputDisabled} value={estateData.registerII}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                    <div className="column">
                                        <InputControl
                                            type="number"
                                            label="Cuenta con certificado de uso expedido por INDAABIN"
                                            placeholder="Cantidad"
                                            form-index="1"
                                            field="registerIII"
                                            disabled={inputDisabled} value={estateData.registerIII}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="block columns">
                                    <div className="column">
                                        <InputControl
                                            type="number"
                                            label="Número de Folio Real"
                                            placeholder="Cantidad"
                                            form-index="1"
                                            field="registerIV"
                                            disabled={inputDisabled} value={estateData.registerIV}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                    <div className="column">
                                        <InputControl
                                            type="number"
                                            label="Número de R.F.I. (registro federal inmobiliario)"
                                            placeholder="Cantidad"
                                            form-index="1"
                                            field="registerV"
                                            disabled={inputDisabled} value={estateData.registerV}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                    <div className="column">
                                        <InputControl
                                            type="number"
                                            label="Cuenta con Declaratoria de Procedencia"
                                            placeholder="Cantidad"
                                            form-index="1"
                                            field="registerVI"
                                            disabled={inputDisabled} value={estateData.registerVI}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="block columns">
                                    <div className="column">
                                        <InputControl
                                            type="number"
                                            label="Falta dar aviso a SEGOB que pertenecen a IMMAR"
                                            placeholder="Cantidad"
                                            form-index="1"
                                            field="registerVII"
                                            disabled={inputDisabled} value={estateData.registerVII}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                    <div className="column">
                                        <InputControl
                                            type="number"
                                            label="Con contrato de Arrendamiento ante su Conferencia"
                                            placeholder="Cantidad"
                                            form-index="1"
                                            field="registerVIII"
                                            disabled={inputDisabled} value={estateData.registerVIII}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                    <div className="column">
                                        <InputControl
                                            type="number"
                                            label="Prestado sin contrato de arrendamiento"
                                            placeholder="Cantidad"
                                            form-index="1"
                                            field="registerIX"
                                            disabled={inputDisabled} value={estateData.registerIX}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="block columns">
                                    <div className="column">
                                        <InputControl
                                            type="number"
                                            label="Otro"
                                            placeholder="Cantidad"
                                            form-index="1"
                                            field="registerX"
                                            disabled={inputDisabled} value={estateData.registerX}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                    {(estateData.registerX !== "0" && estateData.registerX !== "" && estateData.registerX) &&
                                        <div className="column">
                                            <TextareaControl
                                                placeholder="Especifique (Separado por comas [,]"
                                                form-index="1"
                                                field="registerXExplain"
                                                disabled={inputDisabled} value={estateData.registerXExplain}
                                                onChange={handleChangeField}
                                                required
                                            />
                                        </div>
                                    }
                                </div>
                                <div className="block columns">
                                    <div className="column">
                                        <SelectControl
                                            label="¿Cuenta con la documentación oficial que avala sus respuestas anteriores?"
                                            placeholder="Seleccionar"
                                            options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                            form-index="1"
                                            field="officialDocs"
                                            disabled={inputDisabled} value={estateData.officialDocs}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                    {(estateData.officialDocs === "1") &&
                                        <div className="column">
                                            <TextareaControl
                                                placeholder="Especifique (Separado por comas [,]"
                                                form-index="1"
                                                field="registerXExplain"
                                                disabled={inputDisabled} value={estateData.registerXExplain}
                                                onChange={handleChangeField}
                                                required
                                            />
                                        </div>
                                    }
                                </div>
                            </Fragment>
                        }
                        <div className="block">
                            <SelectControl
                                label="¿Considera que la conferencia tiene desconocimiento del estado de los bienes inmuebles de la IMMAR?"
                                placeholder="Seleccionar"
                                options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                form-index="1"
                                field="conferenceKnowProperties"
                                disabled={inputDisabled} value={estateData.conferenceKnowProperties}
                                onChange={handleChangeField}
                                required
                            />
                        </div>
                        {(estateData.conferenceKnowProperties === "1") &&
                            <div className="block">
                                <MultipleOptionsControl
                                    columnWeight="12"
                                    description="Selecciones todas las razones que expliquen: ¿Por qué?"
                                    form-index="1"
                                    field="conferenceKnowPropertiesExplain"
                                    disabled={inputDisabled} value={estateData.conferenceKnowPropertiesExplain}
                                    onChange={handleChangeField}
                                    required
                                >
                                    <Option>Inexistencia o existencia incompleta de registros</Option>
                                    <Option>Falta de clasificación</Option>
                                    <Option>Desactualización de bienes inmuebles</Option>
                                    <Option>Falta de comunicación en responsables</Option>
                                    <Option>Desconocimiento de obligaciones en la iglesia local</Option>
                                </MultipleOptionsControl>
                            </div>
                        }
                        <div className="block">
                            <InputControl
                                type="date"
                                label="¿En qué fecha entregó la Iglesia Local a AFA Conferencial el Formato de Inmuebles que se le solicitó? (Dejar Vacío si no ha entregado)"
                                form-index="1"
                                field="afaDeliveryDate"
                                disabled={inputDisabled} value={estateData.afaDeliveryDate}
                                onChange={handleChangeField}
                            />
                        </div>
                        {(!estateData.afaDeliveryDate) &&
                            <div className="block columns">
                                <div className="column">
                                    <InputControl
                                        type="text"
                                        label="¿Por qué no se ha hecho?"
                                        placeholder="Explique"
                                        form-index="1"
                                        field="afaDeliveryExplain"
                                        disabled={inputDisabled} value={estateData.afaDeliveryExplain}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                <div className="column">
                                    <InputControl
                                        type="date"
                                        label="¿Cuándo lo van a entregar? (Dejar Vacío si no se sabe)"
                                        form-index="1"
                                        field="nextAfaDeliveryDate"
                                        disabled={inputDisabled} value={estateData.nextAfaDeliveryDate}
                                        onChange={handleChangeField}
                                    />
                                </div>
                            </div>
                        }
                        <div className="block columns">
                            <div className="column">
                                <SelectControl
                                    label="¿Ha integrado al 100% la información de bienes inmuebles que administra la Iglesia Local en el año?"
                                    placeholder="Seleccionar"
                                    options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                    form-index="1"
                                    field="fullDelivery"
                                    disabled={inputDisabled} value={estateData.fullDelivery}
                                    onChange={handleChangeField}
                                    required
                                />
                            </div>
                            {(estateData.fullDelivery === "1") &&
                                <div className="column">
                                    <InputControl
                                        type="date"
                                        label="¿En qué fecha la entregó al AFA Conferencial?"
                                        form-index="1"
                                        field="fullDeliveryDate"
                                        disabled={inputDisabled} value={estateData.fullDeliveryDate}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                            }
                        </div>
                        <div className="block columns">
                            <div className="column">
                                <SelectControl
                                    label="¿Se han clasificado los bienes inmuebles que administra la Iglesia Local en el año?"
                                    placeholder="Seleccionar"
                                    options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                    form-index="1"
                                    field="clasified"
                                    disabled={inputDisabled} value={estateData.clasified}
                                    onChange={handleChangeField}
                                    required
                                />
                            </div>
                            {(estateData.clasified === "0") &&
                                <div className="column">
                                    <TextareaControl
                                        placeholder="¿Por qué no?"
                                        form-index="1"
                                        field="clasifiedExplain"
                                        disabled={inputDisabled} value={estateData.clasifiedExplain}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                            }
                        </div>
                        <div className="block columns">
                            <div className="column">
                                <SelectControl
                                    label="¿Ha desarrollado mecanismos de actualización de los bienes inmuebles que administra la Iglesia Local?"
                                    placeholder="Seleccionar"
                                    options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                    form-index="1"
                                    field="updateMechanisms"
                                    disabled={inputDisabled} value={estateData.updateMechanisms}
                                    onChange={handleChangeField}
                                    required
                                />
                            </div>
                            {(estateData.updateMechanisms === "0") &&
                                <div className="column">
                                    <TextareaControl
                                        placeholder="¿Por qué no?"
                                        form-index="1"
                                        field="updateMechanismsExplain"
                                        disabled={inputDisabled} value={estateData.updateMechanismsExplain}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                            }
                        </div>
                        <div className="block">
                            <TextareaControl
                                label="¿Cuáles instrumentos de comunicación para el registro de los bienes inmuebles ha establecido?"
                                placeholder="Separados por comas (,)"
                                form-index="1"
                                field="communicationTools"
                                disabled={inputDisabled} value={estateData.communicationTools}
                                onChange={handleChangeField}
                                required
                            />
                        </div>
                        <div className="block">
                            <InputControl
                                type="date"
                                label="¿Para cuándo se tendrán?"
                                form-index="1"
                                field="communicationToolsDate"
                                disabled={inputDisabled} value={estateData.communicationToolsDate}
                                onChange={handleChangeField}
                            />
                        </div>
                        <div className="block">
                            <SelectControl
                                label="¿Conoce las obligaciones de la Iglesia Local para el registro de los bienes inmuebles que administra?"
                                placeholder="Seleccionar"
                                options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                form-index="1"
                                field="kownObligations"
                                disabled={inputDisabled} value={estateData.kownObligations}
                                onChange={handleChangeField}
                                required
                            />
                        </div>
                        <div className="block columns">
                            <div className="column">
                                <SelectControl
                                    label="¿Cumple la Iglesia Local con las obligaciones?"
                                    placeholder="Seleccionar"
                                    options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                    form-index="1"
                                    field="fulfilledObligations"
                                    disabled={inputDisabled} value={estateData.fulfilledObligations}
                                    onChange={handleChangeField}
                                    required
                                />
                            </div>
                            {(estateData.fulfilledObligations === "0") &&
                                <div className="column">
                                    <TextareaControl
                                        placeholder="¿Por qué no cumple?"
                                        form-index="1"
                                        field="fulfilledObligationsExplain"
                                        disabled={inputDisabled} value={estateData.fulfilledObligationsExplain}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                            }
                        </div>
                        <div className="block columns">
                            <div className="column">
                                <InputControl
                                    type="number"
                                    label="¿Cuántos de los inmuebles que administra la Iglesia Local han sido valuados?"
                                    placeholder="Cantidad"
                                    form-index="1"
                                    field="valued"
                                    disabled={inputDisabled} value={estateData.valued}
                                    onChange={handleChangeField}
                                    required
                                />
                            </div>
                            <div className="column">
                                <TextareaControl
                                    label="Si alguno no ha sido valuado ¿Cuál es el motivo?"
                                    placeholder="explain"
                                    form-index="1"
                                    field="noValuedExplain"
                                    disabled={inputDisabled} value={estateData.noValuedExplain}
                                    onChange={handleChangeField}
                                    required
                                />
                            </div>
                        </div>
                        {!inputDisabled &&
                            <div className="block">
                                <button className="button is-link is-fullwidth" type="submit" disabled={formLoading}>
                                    <Icon name="save" />
                                    <span>Guardar</span>
                                </button>
                            </div>
                        }
                    </div>
                </form>
            </Fragment>
        }
        </AppStructure>
    );
};

export default PatrimonyForm;