import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { InputControl, SelectControl, TextareaControl } from "../../components/formControls";
import { Icon } from "../../components/icon";
import ProgressBar from "../../components/progressBar";
import { HeaderTitle } from "../../components/titles";
import useAPI from "../../connection/useAPI";
import { useAuth2 } from "../../functions/auth2Context";
import useLocalStorage from "../../functions/useLocalStorage";
import AppStructure from "../../structures/appStructure";

const ProgramForm = () => {
    const [ itemsCompleted, setItemsCompleted ] = useState(0);
    const [ loading, setLoading ] = useState(false);
    const [ formLoading, setFormLoading ] = useState(false);
    const [ inputDisabled, setInputDisabled ] = useState(false);
    const [ programDataId, setProgramDataId ] = useState(null);
    const [ presidentData, setPresidentData ] = useLocalStorage("PROGRAMFORM_PRESIDENTDATA",{});
    const [ attendanceData, setAttendanceData ] = useLocalStorage("PROGRAMFORM_ATTENDANCEDATA",{});
    const [ adminData, setAdminData ] = useLocalStorage("PROGRAMFORM_ADMINDATA",{});
    const { currentUser } = useAuth2();
    const { programData } = useAPI();
    const { churchId } = useParams();

    const formObjects = [presidentData,attendanceData,adminData];
    const valueSetters = [setPresidentData,setAttendanceData,setAdminData];
    const formFields = ["presidentData","attendanceData","adminData"];

    useEffect(() => {
        setLoading(true);

        let cId = currentUser.uid;
        //setInputDisabled(true);
        
        if(churchId){
            cId = churchId;
            setInputDisabled(true);
        }
        const promises = [programData.get(cId)];

        Promise.all(promises).then((response) => {
            fillServerData(response[0]);

            setLoading(false);
        });
    },[]);

    function fillServerData(programObject){
        if(programObject.id){
            const presidentResponse = JSON.parse(programObject.presidentData);
            const attendanceResponse = JSON.parse(programObject.attendanceData);
            const adminResponse = JSON.parse(programObject.adminData);
            let serverItemsCompleted = 0;

            if(presidentResponse){
                setPresidentData(presidentResponse);
                serverItemsCompleted++;
            }
            if(attendanceResponse){
                setAttendanceData(attendanceResponse);
                serverItemsCompleted++;
            }
            if(adminResponse){
                setAdminData(adminResponse);
                serverItemsCompleted++;
            }

            setItemsCompleted(serverItemsCompleted);
            setProgramDataId(programObject.id);
        }
    }

    function handleChangeField(e){
        const formIndex = e.currentTarget.getAttribute("form-index");
        let fieldValue = e.currentTarget.value;
        if(typeof fieldValue === 'string'){
            fieldValue = fieldValue.replace(/(\r\n|\n|\r|")/gi,'');
        }
        const field = e.currentTarget.getAttribute("field");
        const object = JSON.parse(JSON.stringify(formObjects[formIndex]));
        object[field] = fieldValue;

        valueSetters[formIndex](object);
    }

    function handleSubmit(e){
        e.preventDefault();
        setFormLoading(true);

        const formIndex = e.currentTarget.getAttribute("form-index");
        
        programData.save(currentUser.uid,
                        currentUser.uid,
                        programDataId,
                        formFields[formIndex],
                        JSON.stringify(formObjects[formIndex])
        ).then((response) => {
            fillServerData(response);

            setFormLoading(false);
        });
    }

    return(
        <AppStructure>
            <HeaderTitle title="Formulario de Programa" />
            <ProgressBar itemsToEvaluate={3} itemsCompleted={itemsCompleted} />

            { loading ? <p>Cargando los Datos...</p> :
                <Fragment>
                    <form onSubmit={handleSubmit} form-index="0">
                        <div className="box">
                            <div className="block">
                                <InputControl
                                    type="text"
                                    label="Nombre del Presidente de Programa Local"
                                    placeholder="Nombre completo"
                                    iconLeft="person"
                                    form-index="0"
                                    field="name"
                                    disabled={inputDisabled}
value={presidentData.name}
                                    onChange={handleChangeField}
                                    required
                                />
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <InputControl
                                        type="text"
                                        label="Teléfono (o Celular)"
                                        placeholder="Número de Teléfono"
                                        iconLeft="smartphone"
                                        form-index="0"
                                        field="phone"
                                        disabled={inputDisabled}
value={presidentData.phone}
                                        onChange={handleChangeField}
                                    />
                                </div>
                                <div className="column">
                                    <InputControl
                                        type="email"
                                        label="Correo Electrónico"
                                        placeholder="Correo electrónico"
                                        iconLeft="mail"
                                        form-index="0"
                                        field="email"
                                        disabled={inputDisabled}
value={presidentData.email}
                                        onChange={handleChangeField}
                                    />
                                </div>
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <InputControl
                                        label="¿Cuántas células de personas nuevas preside este funcionario?"
                                        type="number"
                                        placeholder="Cantidad"
                                        form-index="0"
                                        field="newPeopleGroups"
                                        disabled={inputDisabled}
value={presidentData.newPeopleGroups}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                <div className="column">
                                    <InputControl
                                        label="Total de personas que asisten"
                                        type="number"
                                        placeholder="Cantidad"
                                        form-index="0"
                                        field="newPeopleGroupsAttendance"
                                        disabled={inputDisabled}
value={presidentData.newPeopleGroupsAttendance}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                            </div>
                            {!inputDisabled &&
                                <div className="block">
                                    <button type="submit" className="button is-link is-fullwidth" disabled={formLoading}>
                                        <Icon name="save" />
                                        <span>Guardar</span>
                                    </button>
                                </div>
                            }
                        </div>
                    </form>

                    <HeaderTitle
                        subtitle="Asistencia"
                        description="Los datos que se solicitan se refieren al promedio anual de hermanos asistentes a los servicios dominicales."
                    />
                    <form onSubmit={handleSubmit} form-index="1">
                        <div className="box">
                            <div className="block">
                                <InputControl
                                    type="number"
                                    label="Promedio de congregantes cada domingo:"
                                    placeholder="Cantidad"
                                    form-index="1"
                                    field="attendanceAverage"
                                    disabled={inputDisabled}
value={attendanceData.attendanceAverage}
                                    onChange={handleChangeField}
                                    required
                                />
                            </div>
                        </div>
                        <div className="columns is-mobile">
                            <div className="column">
                                <div className="box">
                                    <div className="block">
                                        <h2>Miembros en Plena Comunión</h2>
                                    </div>
                                    <div className="block columns">
                                        <div className="column">
                                            <InputControl
                                                type="number"
                                                label="De 12 años:"
                                                placeholder="Cantidad"
                                                form-index="1"
                                                field="fullCommunionMembers1"
                                                disabled={inputDisabled}
value={attendanceData.fullCommunionMembers1}
                                                onChange={handleChangeField}
                                                required
                                            />
                                        </div>
                                        <div className="column">
                                            <InputControl
                                                type="number"
                                                label="De 13 a 17 años:"
                                                placeholder="Cantidad"
                                                form-index="1"
                                                field="fullCommunionMembers2"
                                                disabled={inputDisabled}
value={attendanceData.fullCommunionMembers2}
                                                onChange={handleChangeField}
                                                required
                                            />
                                        </div>
                                        <div className="column">
                                            <InputControl
                                                type="number"
                                                label="De 18 a 28 años:"
                                                placeholder="Cantidad"
                                                form-index="1"
                                                field="fullCommunionMembers3"
                                                disabled={inputDisabled}
value={attendanceData.fullCommunionMembers3}
                                                onChange={handleChangeField}
                                                required
                                            />
                                        </div>
                                        <div className="column">
                                            <InputControl
                                                type="number"
                                                label="De 29 años en adelante:"
                                                placeholder="Cantidad"
                                                form-index="1"
                                                field="fullCommunionMembers4"
                                                disabled={inputDisabled}
value={attendanceData.fullCommunionMembers4}
                                                onChange={handleChangeField}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="column">
                                <div className="box">
                                    <div className="block">
                                        <h2>Miembros a Prueba</h2>
                                    </div>
                                    <div className="block columns">
                                        <div className="column">
                                            <InputControl
                                                type="number"
                                                label="De 0 a 12 años:"
                                                placeholder="Cantidad"
                                                form-index="1"
                                                field="probatoryMembers1"
                                                disabled={inputDisabled}
value={attendanceData.probatoryMembers1}
                                                onChange={handleChangeField}
                                                required
                                            />
                                        </div>
                                        <div className="column">
                                            <InputControl
                                                type="number"
                                                label="De 13 a 17 años:"
                                                placeholder="Cantidad"
                                                form-index="1"
                                                field="probatoryMembers2"
                                                disabled={inputDisabled}
value={attendanceData.probatoryMembers2}
                                                onChange={handleChangeField}
                                                required
                                            />
                                        </div>
                                        <div className="column">
                                            <InputControl
                                                type="number"
                                                label="De 18 a 28 años:"
                                                placeholder="Cantidad"
                                                form-index="1"
                                                field="probatoryMembers3"
                                                disabled={inputDisabled}
value={attendanceData.probatoryMembers3}
                                                onChange={handleChangeField}
                                                required
                                            />
                                        </div>
                                        <div className="column">
                                            <InputControl
                                                type="number"
                                                label="De 29 años en adelante:"
                                                placeholder="Cantidad"
                                                form-index="1"
                                                field="probatoryMembers4"
                                                disabled={inputDisabled}
value={attendanceData.probatoryMembers4}
                                                onChange={handleChangeField}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="columns is-mobile">
                            <div className="column">
                                <div className="box">
                                    <div className="block">
                                        <h2>Miembros Afiliados</h2>
                                    </div>
                                    <div className="block columns">
                                        <div className="column">
                                            <InputControl
                                                type="number"
                                                label="De 0 a 12 años:"
                                                placeholder="Cantidad"
                                                form-index="1"
                                                field="affiliateMembers1"
                                                disabled={inputDisabled}
value={attendanceData.affiliateMembers1}
                                                onChange={handleChangeField}
                                                required
                                            />
                                        </div>
                                        <div className="column">
                                            <InputControl
                                                type="number"
                                                label="De 13 a 17 años:"
                                                placeholder="Cantidad"
                                                form-index="1"
                                                field="affiliateMembers2"
                                                disabled={inputDisabled}
value={attendanceData.affiliateMembers2}
                                                onChange={handleChangeField}
                                                required
                                            />
                                        </div>
                                        <div className="column">
                                            <InputControl
                                                type="number"
                                                label="De 18 a 28 años:"
                                                placeholder="Cantidad"
                                                form-index="1"
                                                field="affiliateMembers3"
                                                disabled={inputDisabled}
value={attendanceData.affiliateMembers3}
                                                onChange={handleChangeField}
                                                required
                                            />
                                        </div>
                                        <div className="column">
                                            <InputControl
                                                type="number"
                                                label="De 29 años en adelante:"
                                                placeholder="Cantidad"
                                                form-index="1"
                                                field="affiliateMembers4"
                                                disabled={inputDisabled}
value={attendanceData.affiliateMembers4}
                                                onChange={handleChangeField}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="column">
                                <div className="box">
                                    <div className="block">
                                        <h2>Miembros Simpatizantes</h2>
                                    </div>
                                    <div className="block columns">
                                        <div className="column">
                                            <InputControl
                                                type="number"
                                                label="De 0 a 12 años:"
                                                placeholder="Cantidad"
                                                form-index="1"
                                                field="sympathizerMembers1"
                                                disabled={inputDisabled}
value={attendanceData.sympathizerMembers1}
                                                onChange={handleChangeField}
                                                required
                                            />
                                        </div>
                                        <div className="column">
                                            <InputControl
                                                type="number"
                                                label="De 13 a 17 años:"
                                                placeholder="Cantidad"
                                                form-index="1"
                                                field="sympathizerMembers2"
                                                disabled={inputDisabled}
value={attendanceData.sympathizerMembers2}
                                                onChange={handleChangeField}
                                                required
                                            />
                                        </div>
                                        <div className="column">
                                            <InputControl
                                                type="number"
                                                label="De 18 a 28 años:"
                                                placeholder="Cantidad"
                                                form-index="1"
                                                field="sympathizerMembers3"
                                                disabled={inputDisabled}
value={attendanceData.sympathizerMembers3}
                                                onChange={handleChangeField}
                                                required
                                            />
                                        </div>
                                        <div className="column">
                                            <InputControl
                                                type="number"
                                                label="De 29 años en adelante:"
                                                placeholder="Cantidad"
                                                form-index="1"
                                                field="sympathizerMembers4"
                                                disabled={inputDisabled}
value={attendanceData.sympathizerMembers4}
                                                onChange={handleChangeField}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {!inputDisabled &&
                            <div className="box">
                                <div className="block">
                                    <button type="submit" className="button is-link is-fullwidth" disabled={formLoading}>
                                        <Icon name="save" />
                                        <span>Guardar</span>
                                    </button>
                                </div>
                            </div>
                        }
                    </form>

                    <HeaderTitle subtitle="Junta de Administradores" />
                    <form onSubmit={handleSubmit} form-index="2">
                        <div className="box">
                            <div className="block">
                                <SelectControl
                                    label="Cuenta con Junta de Administradores:"
                                    placeholder="Seleccionar"
                                    options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                    form-index="2"
                                    field="adminExists"
                                    disabled={inputDisabled}
value={adminData.adminExists}
                                    onChange={handleChangeField}
                                    required
                                />
                            </div>
                            { (adminData.adminExists === "1") &&
                                <Fragment>
                                    <div className="block">
                                        <TextareaControl
                                            label="Especifique las áreas y comisiones, contempladas en la Disciplina, que conforman su Junta de Administradores"
                                            placeholder="Áreas separados por comas (,)"
                                            form-index="2"
                                            field="adminDisciplineAreas"
                                            disabled={inputDisabled}
value={adminData.adminDisciplineAreas}
                                            onChange={handleChangeField}
                                        />
                                    </div>
                                    <div className="block">
                                        <TextareaControl
                                            label="Si su iglesia cuenta con Áreas y/o Comisiones que no están contempladas explicitamente en la Disciplina de la IMMAR especifiquelas  a continuación:"
                                            placeholder="Áreas separados por comas (,)"
                                            form-index="2"
                                            field="adminExtraAreas"
                                            disabled={inputDisabled}
value={adminData.adminExtraAreas}
                                            onChange={handleChangeField}
                                        />
                                    </div>
                                    <div className="block columns">
                                        <div className="column">
                                            <SelectControl
                                                label="Su iglesia cuenta con Programa de Trabajo aplicando el Formato Único:"
                                                placeholder="Seleccionar"
                                                options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                                form-index="2"
                                                field="uniqueFormat"
                                                disabled={inputDisabled}
value={adminData.uniqueFormat}
                                                onChange={handleChangeField}
                                                required
                                            />
                                        </div>
                                        { /*(adminData.uniqueFormat === "1") &&
                                            <div className="column">
                                                <FileUploader description="Subir Archivo" />
                                            </div>*/
                                        }
                                        { (adminData.uniqueFormat === "0") &&
                                            <div className="column">
                                                <TextareaControl
                                                    placeholder="En caso de que NO aplique el Formato Único comente la razón:"
                                                    form-index="2"
                                                    field="noUniqueFormatExplain"
                                                    disabled={inputDisabled}
value={adminData.noUniqueFormatExplain}
                                                    onChange={handleChangeField}
                                                    required
                                                />
                                            </div>
                                        }
                                    </div>
                                </Fragment>
                            }
                            {!inputDisabled &&
                                <div className="block">
                                    <button className="button is-link is-fullwidth" disabled={formLoading}>
                                        <Icon name="save" />
                                        <span>Guardar</span>
                                    </button>
                                </div>
                            }
                        </div>

                    </form>
                </Fragment>
            }
        </AppStructure>
    );
};

export default ProgramForm;