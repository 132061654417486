import { useRef, useState } from "react";
import { useAuth } from "../../functions/authContext";
import { useNavigate, Link } from "react-router-dom";
import logo from "../../assets/img/logo_estimmar.png";

const ForgotPassword = () => {
    const emailRef = useRef();
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState("");
    const [ success, setSuccess ] = useState("");
    const { resetPassword } = useAuth();
    const navigate = useNavigate();

    async function handleSubmit(e){
        e.preventDefault();

        try{
            setError("");
            setLoading(true);

            await resetPassword(emailRef.current.value);
            setSuccess(<p>Se ha enviado un correo electrónico con un enlace para modificar tu contraseña. <strong>¡Recuerda revisar tu bandeja de correo no deseado o spam!</strong></p>)
        }catch(err){
            setError("Algo falló");
            console.log(err);
        }

        setLoading(false);
    }

    return(
        <div className="access-page">
            <div className="box">
                <img src={logo} alt="IMMAR online" />

                <div className="block">
                    { error && <div class="notification is-danger">{error}</div>}
                    { success && <div class="notification is-success">{success}</div>}
                </div>

                <div className="block">
                    <form onSubmit={handleSubmit}>
                        <div className="field">
                            <input className="input" ref={emailRef} type="email" placeholder="Correo Electrónico" />
                        </div>
                        <div className="is-centered">
                            <button className="button is-link" disabled={loading} type="submit">Entrar</button>
                        </div>
                    </form>
                </div>

                <div className="block" style={{textAlign:"center"}}>
                    <Link to="/login">Recordé o recuperé mi contraseña</Link>
                    <br />o<br />
                    <Link to="/register">Crear una nueva cuenta</Link>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;