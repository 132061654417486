import { Fragment, useEffect, useState} from "react";
import { InputControl, MultipleOptionsControl, Option, SelectControl, TextareaControl } from "../../components/formControls";
import { Icon } from "../../components/icon";
import ProgressBar from "../../components/progressBar";
import { HeaderTitle } from "../../components/titles";
import AppStructure from "../../structures/appStructure";
import useLocalStorage from "../../functions/useLocalStorage";
import { useAuth2 } from "../../functions/auth2Context";
import useAPI from "../../connection/useAPI";
import { useParams } from "react-router-dom";

const OrganizationsForm = () => {
    const [ organizationsId, setOrganizationsId ] = useLocalStorage("ORGANITATIONSFORM_ID",null);
    const [ itemsCompleted, setItemsCompleted ] = useState(0);
    const [ inputDisabled, setInputDisabled ] = useState(false);
    const [ fhmData, setFhmData ] = useLocalStorage("ORGANITATIONSFORM_FHMDATA",{visible:true});
    const [ gmData, setGmData ] = useLocalStorage("ORGANITATIONSFORM_GMDATA",{visible:true});
    const [ lbscData, setLbscData ] = useLocalStorage("ORGANITATIONSFORM_LBSCDATA",{visible:true});
    const [ smfData, setSmfData ] = useLocalStorage("ORGANITATIONSFORM_SMFDATA",{visible:true});
    const [ lmjiData, setLmjiData ] = useLocalStorage("ORGANITATIONSFORM_LMJIDATA",{visible:true,organization:[{cabinetPosts:[]},{cabinetPosts:[]}]});
    const { currentUser } = useAuth2();
    const { organizationsData } = useAPI();
    const [ loading, setLoading ] = useState(false);
    const [ formLoading, setFormLoading ] = useState(false);
    const { churchId } = useParams();

    const formObjects = [fhmData,gmData,lbscData,smfData,lmjiData];
    const valueSetters = [setFhmData,setGmData,setLbscData,setSmfData,setLmjiData];
    const formFields = ["fhmData","gmData","lbscData","smfData","lmjiData"];

    useEffect(() => {
        setLoading(true);

        let cId = currentUser.uid;
        //setInputDisabled(true);
        if(churchId){
            cId = churchId;
            setInputDisabled(true);
        }
        const promises = [organizationsData.get(cId)];

        Promise.all(promises).then((response) => {
            fillServerData(response[0]);

            setLoading(false);
        });
    },[]);

    function fillServerData(organizationsDataObject){
        if(organizationsDataObject.id){
            let serverItemsCompleted = 0;

            formFields.forEach((field,fieldIndex) => {
                const fieldObject = JSON.parse(organizationsDataObject[field]);

                if(fieldObject){
                    valueSetters[fieldIndex](fieldObject);
                    serverItemsCompleted++;
                }
            });

            setItemsCompleted(serverItemsCompleted);
            setOrganizationsId(organizationsDataObject.id);
        }
    }

    function handleChangeField(e){
        const fieldIndex = e.currentTarget.getAttribute("form-index");
        let fieldValue = e.currentTarget.value;
        if(typeof fieldValue === 'string'){
            fieldValue = fieldValue.replace(/(\r\n|\n|\r|")/gi,'');
        }
        const field = e.currentTarget.getAttribute("field");
        const object = JSON.parse(JSON.stringify(formObjects[fieldIndex]));
        object[field] = fieldValue;

        valueSetters[fieldIndex](object);
    }

    function handleSubmit(e){
        e.preventDefault();
        setFormLoading(true);

        const formIndex = e.currentTarget.getAttribute("form-index");
        const data = JSON.parse(JSON.stringify(formObjects[formIndex]));
        data.visible = false;

        // console.log(currentUser.uid);
        // console.log(churchId);
        // console.log(organizationsId[formIndex]);
        // console.log(data);
        /*saveMethods[formIndex](currentUser.uid,churchId,organizationsId[formIndex],JSON.stringify(data)).then((response) => {
            // console.log(response);
            fillServerData(response);
        });*/
        organizationsData.save(currentUser.uid,
            currentUser.uid,
            organizationsId,
            formFields[formIndex],
            JSON.stringify(formObjects[formIndex])
        ).then((response) => {
            fillServerData(response);

            setFormLoading(false);
        });
    }

    function toggleAreas(e){
        const areaIndex = e.currentTarget.getAttribute("area-index");
        const object = JSON.parse(JSON.stringify(formObjects[areaIndex]));
        object.visible = !object.visible;

        valueSetters[areaIndex](object);
    }

    

    return(
        <AppStructure>
            <HeaderTitle title="Formulario de Organizaciones" />
            <ProgressBar itemsToEvaluate={5} itemsCompleted={itemsCompleted} />

            {loading ?
                <p>Cargando datos</p>
            :
                <Fragment>
                    { /* FHM */ }
                    <form className="block" onSubmit={handleSubmit} form-index="0">
                        <div className="box">
                            <div className="block">
                                <button
                                    className="button is-large is-fullwidth is-responsive"
                                    area-index="0"
                                    onClick={toggleAreas}
                                    type="button"
                                >
                                    <span>Fraternidad de Hombres Metodistas</span>
                                    { /*!fhmData.visible && <span>(Click para mostrar datos)</span>*/ }
                                    <Icon name={ (fhmData.visible) ? "south" : "arrow_right_alt"} />
                                </button>
                            </div>
                            { fhmData.visible &&
                                <Fragment>
                                    <div className="block columns">
                                        <div className="column">
                                            <SelectControl
                                                label="¿Hay Fraternidad de Hombres Metodistas en su iglesia?"
                                                placeholder="Seleccionar"
                                                options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                                form-index="0"
                                                field="exists"
                                                disabled={inputDisabled} value={fhmData.exists}
                                                onChange={handleChangeField}
                                                required
                                            />
                                        </div>
                                        { (fhmData.exists === '0') &&
                                            <div className="column">
                                                <TextareaControl
                                                    placeholder="Si la respuesta es “no” explique la razón"
                                                    form-index="0"
                                                    field="reasonNotExists"
                                                    disabled={inputDisabled} value={fhmData.reasonNotExists}
                                                    onChange={handleChangeField}
                                                    required
                                                />
                                            </div>
                                        }
                                    </div>
                                    { (fhmData.exists === '1') &&
                                        <OrganizationForm disabled={inputDisabled} onChange={handleChangeField} fieldIndex="0" fieldsObject={fhmData} />
                                    }
                                    {!inputDisabled &&
                                        <div className="block">
                                            <button className="button is-link is-fullwidth" type="submit" disabled={formLoading}>
                                                <Icon name="save" />
                                                <span>Guardar</span>
                                            </button>
                                        </div>
                                    }
                                </Fragment>
                            }
                        </div>
                    </form>

                    { /* GM */ }
                    <form className="block" onSubmit={handleSubmit} form-index="1">
                        <div className="box">
                            <div className="block">
                                <button
                                    className="button is-large is-fullwidth is-responsive"
                                    area-index="1"
                                    onClick={toggleAreas}
                                    type="button"
                                >
                                    <span>Grupo de Matrimonios</span>
                                    { /*!fhmData.visible && <span>(Click para mostrar datos)</span>*/ }
                                    <Icon name={ (gmData.visible) ? "south" : "arrow_right_alt"} />
                                </button>
                            </div>
                            { gmData.visible &&
                                <Fragment>
                                    <div className="block columns">
                                        <div className="column">
                                            <InputControl
                                                type="number"
                                                label="¿Cuántos matrimonios hay en la Iglesia local?"
                                                placeholder="Cantidad"
                                                form-index="1"
                                                field="numberOfMarriages"
                                                disabled={inputDisabled} value={gmData.numberOfMarriages}
                                                onChange={handleChangeField}
                                                required
                                            />
                                        </div>
                                        <div className="column">
                                            <SelectControl
                                                label="¿Hay Grupo de matrimonios en su iglesia?"
                                                placeholder="Seleccionar"
                                                options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                                form-index="1"
                                                field="exists"
                                                disabled={inputDisabled} value={gmData.exists}
                                                onChange={handleChangeField}
                                                required
                                            />
                                        </div>
                                        { (gmData.exists === '0') &&
                                            <div className="column">
                                                <TextareaControl
                                                    placeholder="Si la respuesta es “no” explique la razón"
                                                    form-index="1"
                                                    field="reasonNotExists"
                                                    disabled={inputDisabled} value={gmData.reasonNotExists}
                                                    onChange={handleChangeField}
                                                    required
                                                />
                                            </div>
                                        }
                                    </div>
                                    { (gmData.exists === '1') &&
                                        <OrganizationForm disabled={inputDisabled} onChange={handleChangeField} fieldIndex="1" fieldsObject={gmData} />
                                    }
                                    {!inputDisabled &&
                                        <div className="block">
                                            <button className="button is-link is-fullwidth" type="submit" disabled={formLoading}>
                                                <Icon name="save" />
                                                <span>Guardar</span>
                                            </button>
                                        </div>
                                    }
                                </Fragment>
                            }
                        </div>
                    </form>

                    { /* LBSC */ }
                    <form className="block" onSubmit={handleSubmit} form-index="2">
                        <div className="box">
                            <div className="block">
                                <button
                                    className="button is-large is-fullwidth is-responsive"
                                    area-index="2"
                                    onClick={toggleAreas}
                                    type="button"
                                >
                                    <span>Legión Blanca de Servicio Cristiano</span>
                                    { /*!fhmData.visible && <span>(Click para mostrar datos)</span>*/ }
                                    <Icon name={ (lbscData.visible) ? "south" : "arrow_right_alt"} />
                                </button>
                            </div>
                            { lbscData.visible &&
                                <Fragment>
                                    <div className="block columns">
                                        <div className="column">
                                            <SelectControl
                                                label="¿Hay Legión Blanca de Servicio Cristiano en su iglesia?"
                                                placeholder="Seleccionar"
                                                options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                                form-index="2"
                                                field="exists"
                                                disabled={inputDisabled} value={lbscData.exists}
                                                onChange={handleChangeField}
                                                required
                                            />
                                        </div>
                                        { (lbscData.exists === '0') &&
                                            <div className="column">
                                                <TextareaControl
                                                    placeholder="Si la respuesta es “no” explique la razón"
                                                    form-index="2"
                                                    field="reasonNotExists"
                                                    disabled={inputDisabled} value={lbscData.reasonNotExists}
                                                    onChange={handleChangeField}
                                                    required
                                                />
                                            </div>
                                        }
                                    </div>
                                    { (lbscData.exists === '1') &&
                                        <OrganizationForm disabled={inputDisabled} onChange={handleChangeField} fieldIndex="2" fieldsObject={lbscData} />
                                    }
                                    {!inputDisabled &&
                                        <div className="block">
                                            <button className="button is-link is-fullwidth" type="submit" disabled={formLoading}>
                                                <Icon name="save" />
                                                <span>Guardar</span>
                                            </button>
                                        </div>
                                    }
                                </Fragment>
                            }
                        </div>
                    </form>

                    { /* SMF */ }
                    <form className="block" onSubmit={handleSubmit} form-index="3">
                        <div className="box">
                            <div className="block">
                                <button
                                    className="button is-large is-fullwidth is-responsive"
                                    area-index="3"
                                    onClick={toggleAreas}
                                    type="button"
                                >
                                    <span>Sociedad Misionera Femenil</span>
                                    { /*!fhmData.visible && <span>(Click para mostrar datos)</span>*/ }
                                    <Icon name={ (smfData.visible) ? "south" : "arrow_right_alt"} />
                                </button>
                            </div>
                            { smfData.visible &&
                                <Fragment>
                                    <div className="block columns">
                                        <div className="column">
                                            <SelectControl
                                                label="¿Hay Sociedad Misionera Femenil en su iglesia?"
                                                placeholder="Seleccionar"
                                                options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                                form-index="3"
                                                field="exists"
                                                disabled={inputDisabled} value={smfData.exists}
                                                onChange={handleChangeField}
                                                required
                                            />
                                        </div>
                                        { (smfData.exists === '0') &&
                                            <div className="column">
                                                <TextareaControl
                                                    placeholder="Si la respuesta es “no” explique la razón"
                                                    form-index="3"
                                                    field="reasonNotExists"
                                                    disabled={inputDisabled} value={smfData.reasonNotExists}
                                                    onChange={handleChangeField}
                                                    required
                                                />
                                            </div>
                                        }
                                    </div>
                                    { (smfData.exists === '1') &&
                                        <OrganizationForm disabled={inputDisabled} onChange={handleChangeField} fieldIndex="3" fieldsObject={smfData} />
                                    }
                                    {!inputDisabled &&
                                        <div className="block">
                                            <button className="button is-link is-fullwidth" type="submit" disabled={formLoading}>
                                                <Icon name="save" />
                                                <span>Guardar</span>
                                            </button>
                                        </div>
                                    }
                                </Fragment>
                            }
                        </div>
                    </form>

                    { /* LMJI */ }
                    <form className="block" onSubmit={handleSubmit} form-index="4">
                        <div className="box">
                            <div className="block">
                                <button
                                    className="button is-large is-fullwidth is-responsive"
                                    area-index="4"
                                    onClick={toggleAreas}
                                    type="button"
                                >
                                    <span>Ligas Metodistas de Jóvenes e Intermedios</span>
                                    { /*!fhmData.visible && <span>(Click para mostrar datos)</span>*/ }
                                    <Icon name={ (lmjiData.visible) ? "south" : "arrow_right_alt"} />
                                </button>
                            </div>
                            { lmjiData.visible &&
                                <Fragment>
                                    <div className="block columns">
                                        <div className="column">
                                            <SelectControl
                                                label="¿Hay Liga Metodista de Jóvenes e Intermedios en su iglesia?"
                                                placeholder="Seleccionar"
                                                options={[{value:"1",label:"Sí"},{value:"0",label:"No"},{value:"2",label:"No, tenemos otro esquema"}]}
                                                form-index="4"
                                                field="exists"
                                                disabled={inputDisabled} value={lmjiData.exists}
                                                onChange={handleChangeField}
                                                required
                                            />
                                        </div>
                                        { (lmjiData.exists === '0') &&
                                            <div className="column">
                                                <TextareaControl
                                                    placeholder="Si la respuesta es “no” explique la razón"
                                                    form-index="4"
                                                    field="reasonNotExists"
                                                    disabled={inputDisabled} value={lmjiData.reasonNotExists}
                                                    onChange={handleChangeField}
                                                    required
                                                />
                                            </div>
                                        }
                                    </div>
                                    { (lmjiData.exists === '2') &&
                                        <div className="block columns">
                                            <div className="column">
                                                <TextareaControl
                                                    placeholder="Especifique las características de este grupo:"
                                                    form-index="4"
                                                    field="otherScheme"
                                                    disabled={inputDisabled} value={lmjiData.otherScheme}
                                                    onChange={handleChangeField}
                                                    required
                                                />
                                            </div>
                                            <div className="column">
                                                <InputControl
                                                    type="number"
                                                    label="En promedio ¿Cuántas personas participan en las actividades de este grupo?"
                                                    placeholder="Cantidad"
                                                    form-index="4"
                                                    field="numberOfYouth"
                                                    disabled={inputDisabled} value={lmjiData.numberOfYouth}
                                                    onChange={handleChangeField}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    }
                                    { (lmjiData.exists === '1') &&
                                        <Fragment>
                                            <div className="block">
                                                <SelectControl
                                                    label="En nuestra Iglesia tenemos:"
                                                    placeholder="Seleccionar"
                                                    options={[{value:"1",label:"Liga Unida (Jóvenes e Intermedios)"},{value:"2",label:"Ligas Separadas (Jóvenes, Intermedios)"},{value:"3",label:"Sólo liga de Jóvenes"},{value:"4",label:"Sólo liga de Intermedios"}]}
                                                    form-index="4"
                                                    field="meetingType"
                                                    disabled={inputDisabled} value={lmjiData.meetingType}
                                                    onChange={handleChangeField}
                                                    required
                                                />
                                            </div>
                                            { (lmjiData.meetingType === "2") ?
                                                <Fragment> {/* value,setValue,index,organization */}
                                                    <LigaForm
                                                        disabled={inputDisabled} value={lmjiData}
                                                        setValue={setLmjiData}
                                                        index={0}
                                                        organization="de Jóvenes"
                                                    />
                                                    <hr />
                                                    <LigaForm
                                                        disabled={inputDisabled} value={lmjiData}
                                                        setValue={setLmjiData}
                                                        index={1}
                                                        organization="de Intermedios"
                                                    />
                                                </Fragment>
                                                :
                                                <LigaForm
                                                    disabled={inputDisabled} value={lmjiData}
                                                    setValue={setLmjiData}
                                                    index={0}
                                                    organization=""
                                                />
                                            }
                                        </Fragment>
                                    }
                                    {   (lmjiData.exists !== '0') &&
                                        <Fragment>
                                            <hr />
                                            <div className="block">
                                                <h3 className="title is-3">Vanguardias Juveniles</h3>
                                            </div>
                                            <div className="block">
                                                <SelectControl
                                                    label="Su Organización, ¿tiene acceso a Vanguardias Juveniles?"
                                                    placeholder="Seleccionar"
                                                    options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                                    form-index="4"
                                                    field="magazineAccess"
                                                    disabled={inputDisabled} value={lmjiData.magazineAccess}
                                                    onChange={handleChangeField}
                                                    required
                                                />
                                            </div>
                                            <div className="block">
                                                <SelectControl
                                                    label="¿La información de las convocatorias a las actividades Distritales, Conferenciales y Nacionales llegan a tiempo?"
                                                    placeholder="Seleccionar"
                                                    options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                                    form-index="4"
                                                    field="callsInTime"
                                                    disabled={inputDisabled} value={lmjiData.callsInTime}
                                                    onChange={handleChangeField}
                                                    required
                                                />
                                            </div>
                                            <hr />
                                            <div className="block">
                                                <h3 className="title is-3">Pastores y Consejeros Juveniles</h3>
                                            </div>
                                            <div className="block columns">
                                                <div className="column">
                                                    <SelectControl
                                                        label="¿Su Iglesia cuenta con Pastor Local de Jóvenes e Intermedios?"
                                                        placeholder="Seleccionar"
                                                        options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                                        form-index="4"
                                                        field="hasShepherd"
                                                        disabled={inputDisabled} value={lmjiData.hasShepherd}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                                { (lmjiData.hasShepherd === '0') &&
                                                    <div className="column">
                                                        <TextareaControl
                                                            placeholder="Si es no ¿Por qué?"
                                                            form-index="4"
                                                            field="noHasShepheredExplain"
                                                            disabled={inputDisabled} value={lmjiData.noHasShepheredExplain}
                                                            onChange={handleChangeField}
                                                            required
                                                        />
                                                    </div>
                                                }
                                                { (lmjiData.hasShepherd === '1') &&
                                                    <div className="column">
                                                        <InputControl
                                                            type="text"
                                                            label="¿Quién le otorgó ese nombramuiento?"
                                                            placeholder="Explique"
                                                            form-index="4"
                                                            field="appointedShepherd"
                                                            disabled={inputDisabled} value={lmjiData.appointedShepherd}
                                                            onChange={handleChangeField}
                                                            required
                                                        />
                                                    </div>
                                                }
                                            </div>
                                            { (lmjiData.hasShepherd === '1') &&
                                                <Fragment>
                                                    <div className="block">
                                                        <InputControl
                                                            type="text"
                                                            label="Nombre del Pastor Local de Jóvenes e Intermedios:"
                                                            placeholder="Nombre Completo"
                                                            form-index="4"
                                                            field="youthMinister"
                                                            disabled={inputDisabled} value={lmjiData.youthMinister}
                                                            onChange={handleChangeField}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="block columns">
                                                        <div className="column">
                                                            <InputControl
                                                                type="email"
                                                                placeholder="Correo Electrónico"
                                                                form-index="4"
                                                                field="youthMinisterEmail"
                                                                disabled={inputDisabled} value={lmjiData.youthMinisterEmail}
                                                                onChange={handleChangeField}
                                                            />
                                                        </div>
                                                        <div className="column">
                                                            <InputControl
                                                                type="text"
                                                                placeholder="Teléfono"
                                                                form-index="4"
                                                                field="youthMinisterPhone"
                                                                disabled={inputDisabled} value={lmjiData.youthMinisterPhone}
                                                                onChange={handleChangeField}
                                                            />
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            }
                                            <div className="block">
                                                <SelectControl
                                                    label="¿Su Iglesia cuenta con Asesores de la LMJI?"
                                                    placeholder="Seleccionar"
                                                    options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                                    form-index="4"
                                                    field="hasAdvisers"
                                                    disabled={inputDisabled} value={lmjiData.hasAdvisers}
                                                    onChange={handleChangeField}
                                                    required
                                                />
                                            </div>
                                            { (lmjiData.hasAdvisers === "1") &&
                                                <Fragment>
                                                    <div className="block">
                                                        <InputControl
                                                            type="text"
                                                            label="Nombre de los asesores de la LMJI:"
                                                            placeholder="Nombres Completos"
                                                            form-index="4"
                                                            field="advisersNames"
                                                            disabled={inputDisabled} value={lmjiData.advisersNames}
                                                            onChange={handleChangeField}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="block columns">
                                                        <div className="column">
                                                            <InputControl
                                                                type="email"
                                                                placeholder="Correo Electrónico"
                                                                form-index="4"
                                                                field="advisersEmail"
                                                                disabled={inputDisabled} value={lmjiData.advisersEmail}
                                                                onChange={handleChangeField}
                                                            />
                                                        </div>
                                                        <div className="column">
                                                            <InputControl
                                                                type="text"
                                                                placeholder="Teléfono"
                                                                form-index="4"
                                                                field="advisersPhone"
                                                                disabled={inputDisabled} value={lmjiData.advisersPhone}
                                                                onChange={handleChangeField}
                                                            />
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            }
                                            <div className="block">
                                                <SelectControl
                                                    label="¿El Pastor o Asesor de Jóvenes ya cursó el Diplomado Juvenil?"
                                                    placeholder="Seleccionar"
                                                    options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                                    form-index="4"
                                                    field="advisersDiplomat"
                                                    disabled={inputDisabled} value={lmjiData.advisersDiplomat}
                                                    onChange={handleChangeField}
                                                    required
                                                />
                                            </div>
                                            <div className="block">
                                                <SelectControl
                                                    label="¿El Pastor o Asesor de Jóvenes se encuentra inscrito en el Diplomado Juvenil?"
                                                    placeholder="Seleccionar"
                                                    options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                                    form-index="4"
                                                    field="advisersDiplomatStudying"
                                                    disabled={inputDisabled} value={lmjiData.advisersDiplomatStudying}
                                                    onChange={handleChangeField}
                                                    required
                                                />
                                            </div>
                                            <div className="block">
                                                <SelectControl
                                                    label="El Pastor o el Asesor de la LMJI, se han integrado en una Red de trabajo colaborativo en coordinación con el Pastor Nacional de Jóvenes e Intermedios, a fin de cumplir los Lineamientos Nacionales para la LMJI?"
                                                    placeholder="Seleccionar"
                                                    options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                                    form-index="4"
                                                    field="isInNationalNetwork"
                                                    disabled={inputDisabled} value={lmjiData.isInNationalNetwork}
                                                    onChange={handleChangeField}
                                                    required
                                                />
                                            </div>
                                            { (lmjiData.isInNationalNetwork === '0') &&
                                                <div className="block">
                                                    <TextareaControl
                                                        placeholder="Si es “no” ¿Por qué no?"
                                                        form-index="4"
                                                        field="notNationalNetworkExplain"
                                                        disabled={inputDisabled} value={lmjiData.notNationalNetworkExplain}
                                                        onChange={handleChangeField}
                                                        required
                                                    />
                                                </div>
                                            }
                                        </Fragment>
                                    }
                                    {!inputDisabled &&
                                        <div className="block">
                                            <button className="button is-link is-fullwidth" type="submit" disabled={formLoading}>
                                                <Icon name="save" />
                                                <span>Guardar</span>
                                            </button>
                                        </div>
                                    }
                                </Fragment>
                            }
                        </div>
                    </form>
                </Fragment>
            }
        </AppStructure>
    );
};

export default OrganizationsForm;

const OrganizationForm = ({onChange,fieldIndex,fieldsObject,disabled}) => {
    return(
        <Fragment>
            <div className="block">
                <InputControl
                    label="Nombre de Organización"
                    type="text"
                    field="name"
                    form-index={fieldIndex}
                    disabled={disabled} value={fieldsObject.name}
                    onChange={onChange}
                    required
                />
            </div>
            <div className="block columns">
                <div className="column">
                    <InputControl
                        label="Miembros activos"
                        placeholder="Cantidad"
                        type="number"
                        field="activeMembers"
                        form-index={fieldIndex}
                        disabled={disabled} value={fieldsObject.activeMembers}
                        onChange={onChange}
                        required
                    />
                </div>
                <div className="column">
                    <InputControl
                        label="Miembros asociados"
                        placeholder="Cantidad"
                        type="number"
                        field="associateMembers"
                        form-index={fieldIndex}
                        disabled={disabled} value={fieldsObject.associateMembers}
                        onChange={onChange}
                        required
                    />
                </div>
                <div className="column">
                    <InputControl
                        label="Miembros honorarios"
                        placeholder="Cantidad"
                        type="number"
                        field="honoraryMembers"
                        form-index={fieldIndex}
                        disabled={disabled} value={fieldsObject.honoraryMembers}
                        onChange={onChange}
                        required
                    />
                </div>
            </div>
            <div className="block columns">
                <div className="column">
                    <SelectControl
                        label="¿Con que frecuencia realizó las reuniones?"
                        placeholder="Seleccionar"
                        options={[{value:"1",label:"Semanal"},{value:"2",label:"Quincenal"},{value:"3",label:"Mensual"},{value:"4",label:"Otra"}]}
                        field="meetingFrequency"
                        form-index={fieldIndex}
                        disabled={disabled} value={fieldsObject.meetingFrequency}
                        onChange={onChange}
                        required
                    />
                </div>
                {   (fieldsObject.meetingFrequency === "4") &&
                    <div className="column">
                        <InputControl
                            type="text"
                            placeholder="¿Cuál?"
                            field="meetingFrequencyExtra"
                            form-index={fieldIndex}
                            disabled={disabled} value={fieldsObject.meetingFrequencyExtra}
                            onChange={onChange}
                            required
                        />
                    </div>
                }
            </div>
            <div className="block">
                <InputControl
                    type="number"
                    label="Cantidad promedio de miembros que participaron en las reuniones"
                    placeholder="Cantidad"
                    field="averageMembers"
                    form-index={fieldIndex}
                    disabled={disabled} value={fieldsObject.averageMembers}
                    onChange={onChange}
                    required
                />
            </div>
            <div className="block columns">
                <div className="column">
                    <SelectControl
                        label="¿Cuenta con células de personas nuevas en su organización?"
                        placeholder="Seleccionar"
                        options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                        field="newPeopleGroup"
                        form-index={fieldIndex}
                        disabled={disabled} value={fieldsObject.newPeopleGroup}
                        onChange={onChange}
                        required
                    />
                </div>
                {   (fieldsObject.newPeopleGroup === "0") &&
                    <div className="column">
                        <TextareaControl
                            placeholder="Si es “no” ¿Por qué no?"
                            field="newPeopleGroupExplanation"
                            form-index={fieldIndex}
                            disabled={disabled} value={fieldsObject.newPeopleGroupExplanation}
                            onChange={onChange}
                            required
                        />
                    </div>
                }
                {    (fieldsObject.newPeopleGroup === "1") &&
                    <div className="column">
                        <InputControl
                            type="number"
                            label="¿Cuántos Asisten?"
                            placeholder="Cantidad"
                            field="numberOfNewPeopleGroup"
                            form-index={fieldIndex}
                            disabled={disabled} value={fieldsObject.numberOfNewPeopleGroup}
                            onChange={onChange}
                            required
                        />
                    </div>
                }
            </div>
            { (fieldIndex === "1") ?
                <Fragment>
                    <div className="block">
                        <InputControl
                            label="Datos del Esposo Presidente"
                            placeholder="Nombre Completo"
                            type="text"
                            field="hePresidentName"
                            form-index={fieldIndex}
                            disabled={disabled} value={fieldsObject.hePresidentName}
                            onChange={onChange}
                            required
                        />
                    </div>
                    <div className="block columns">
                        <div className="column">
                            <InputControl
                                placeholder="Correo Electrónico"
                                type="email"
                                field="hePresidentEmail"
                                form-index={fieldIndex}
                                disabled={disabled} value={fieldsObject.hePresidentEmail}
                                onChange={onChange}
                            />
                        </div>
                        <div className="column">
                            <InputControl
                                placeholder="Teléfono"
                                type="text"
                                field="hePresidentPhone"
                                form-index={fieldIndex}
                                disabled={disabled} value={fieldsObject.hePresidentPhone}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className="block">
                        <InputControl
                            label="Datos de la Esposa Presidente"
                            placeholder="Nombre Completo"
                            type="text"
                            field="shePresidentName"
                            form-index={fieldIndex}
                            disabled={disabled} value={fieldsObject.shePresidentName}
                            onChange={onChange}
                            required
                        />
                    </div>
                    <div className="block columns">
                        <div className="column">
                            <InputControl
                                placeholder="Correo Electrónico"
                                type="email"
                                field="shePresidentEmail"
                                form-index={fieldIndex}
                                disabled={disabled} value={fieldsObject.shePresidentEmail}
                                onChange={onChange}
                            />
                        </div>
                        <div className="column">
                            <InputControl
                                placeholder="Teléfono"
                                type="text"
                                field="shePresidentPhone"
                                form-index={fieldIndex}
                                disabled={disabled} value={fieldsObject.shePresidentPhone}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                </Fragment>
                :
                <Fragment>
                    <div className="block">
                        <InputControl
                            label="Datos del Presidente Local"
                            placeholder="Nombre Completo"
                            type="text"
                            field="presidentName"
                            form-index={fieldIndex}
                            disabled={disabled} value={fieldsObject.presidentName}
                            onChange={onChange}
                            required
                        />
                    </div>
                    <div className="block columns">
                        <div className="column">
                            <InputControl
                                placeholder="Correo Electrónico"
                                type="email"
                                field="presidentEmail"
                                form-index={fieldIndex}
                                disabled={disabled} value={fieldsObject.presidentEmail}
                                onChange={onChange}
                            />
                        </div>
                        <div className="column">
                            <InputControl
                                placeholder="Teléfono"
                                type="text"
                                field="presidentPhone"
                                form-index={fieldIndex}
                                disabled={disabled} value={fieldsObject.presidentPhone}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                </Fragment>
            }
        </Fragment>
    );
};

//const LigaForm = ({onChange,fieldsObject,organization,organizationIndex,unique}) => {
const LigaForm = ({value,index,setValue,organization,disabled}) => {
    /*function handleChangeInput(e){
        const fieldValue = e.currentTarget.value;
        const field = e.currentTarget.getAttribute("field");
        const object = JSON.parse(JSON.stringify(fieldsObject));
        object[field] = fieldValue;

        onChange(object,organizationIndex,unique);
    }*/

    function handleChangeInput(e){
        const field = e.currentTarget.getAttribute("field");
        let internalValue = e.currentTarget.value;
        if(typeof internalValue === 'string'){
            internalValue = internalValue.replace(/(\r\n|\n|\r|")/gi,'');
        }
        const mainObject = JSON.parse(JSON.stringify(value));
        const object = JSON.parse(JSON.stringify(mainObject.organization[index]));
        object[field] = internalValue;
        mainObject.organization[index] = object;
        
        setValue(mainObject);
    }

    return(
        <Fragment>
            <div className="block">
                <InputControl
                    label={`Nombre de la Liga ${organization}`}
                    type="text"
                    field="name"
                    disabled={disabled} value={value.organization[index].name}
                    onChange={handleChangeInput}
                    required
                />
            </div>
            <div className="block columns">
                <div className="column">
                    <div className="block">
                        <h5 className="title is-5">Miembros Activos</h5>
                    </div>
                    <div className="block columns is-mobile">
                        <div className="column">
                            <InputControl
                                type="number"
                                placeholder="Mujeres"
                                field="activeWomenMembers"
                                disabled={disabled} value={value.organization[index].activeWomenMembers}
                                onChange={handleChangeInput}
                                required
                            />
                        </div>
                        <div className="column">
                            <InputControl
                                type="number"
                                placeholder="Hombres"
                                field="activeMenMembers"
                                disabled={disabled} value={value.organization[index].activeMenMembers}
                                onChange={handleChangeInput}
                                required
                            />
                        </div>
                    </div>
                </div>
                <div className="column">
                    <div className="block">
                        <h5 className="title is-5">Miembros Asociados</h5>
                    </div>
                    <div className="block columns is-mobile">
                        <div className="column">
                            <InputControl
                                type="number"
                                placeholder="Mujeres"
                                field="associateWomenMembers"
                                disabled={disabled} value={value.organization[index].associateWomenMembers}
                                onChange={handleChangeInput}
                                required
                            />
                        </div>
                        <div className="column">
                            <InputControl
                                type="number"
                                placeholder="Hombres"
                                field="associateMenMembers"
                                disabled={disabled} value={value.organization[index].associateMenMembers}
                                onChange={handleChangeInput}
                                required
                            />
                        </div>
                    </div>
                </div>
                <div className="column">
                    <div className="block">
                        <h5 className="title is-5">Miembros Honorarios</h5>
                    </div>
                    <div className="block columns is-mobile">
                        <div className="column">
                            <InputControl
                                type="number"
                                placeholder="Mujeres"
                                field="honoraryWomenMembers"
                                disabled={disabled} value={value.organization[index].honoraryWomenMembers}
                                onChange={handleChangeInput}
                                required
                            />
                        </div>
                        <div className="column">
                            <InputControl
                                type="number"
                                placeholder="Hombres"
                                field="honoraryMenMembers"
                                disabled={disabled} value={value.organization[index].honoraryMenMembers}
                                onChange={handleChangeInput}
                                required
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="block columns">
                <div className="column">
                    <SelectControl
                        label="¿Con que frecuencia realizó las reuniones?"
                        placeholder="Seleccionar"
                        options={[{value:"1",label:"Semanal"},{value:"2",label:"Quincenal"},{value:"3",label:"Mensual"},{value:"4",label:"Otra"}]}
                        field="meetingFrequency"
                        disabled={disabled} value={value.organization[index].meetingFrequency}
                        onChange={handleChangeInput}
                        required
                    />
                </div>
                {   (value.organization[index].meetingFrequency === "4") &&
                    <div className="column">
                        <InputControl
                            type="text"
                            placeholder="¿Cuál?"
                            field="meetingFrequencyExtra"
                            disabled={disabled} value={value.organization[index].meetingFrequencyExtra}
                            onChange={handleChangeInput}
                            required
                        />
                    </div>
                }
            </div>
            <div className="block">
                <InputControl
                    type="number"
                    label="Cantidad promedio de miembros que participaron en las reuniones"
                    placeholder="Cantidad"
                    field="averageMembers"
                    disabled={disabled} value={value.organization[index].averageMembers}
                    onChange={handleChangeInput}
                    required
                />
            </div>
            <div className="block columns">
                <div className="column">
                    <InputControl
                        label="¿Cuántos integrantes del Gabinete Local de la LMJI participan en el Diplomado Juvenil?"
                        placeholder="Cantidad"
                        type="number"
                        field="cabinetInDiploma"
                        disabled={disabled} value={value.organization[index].cabinetInDiploma}
                        onChange={handleChangeInput}
                        required
                    />
                </div>
                <div className="column">
                    <InputControl
                        label="Total de personas que participan (Gabinete y otros)"
                        placeholder="Cantidad"
                        type="number"
                        field="membersInDiploma"
                        disabled={disabled} value={value.organization[index].membersInDiploma}
                        onChange={handleChangeInput}
                        required
                    />
                </div>
            </div>
            <div className="block columns">
                <div className="column">
                    <SelectControl
                        label="¿Han asistido a las convocatorias distritales, conferenciales y/o nacionales este año?"
                        placeholder="Seleccionar"
                        options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                        field="meetingAttendance"
                        disabled={disabled} value={value.organization[index].meetingAttendance}
                        onChange={handleChangeInput}
                        required
                    />
                </div>
                {   (value.organization[index].meetingAttendance === "1") &&
                    <div className="column">
                        <TextareaControl
                            placeholder="¿A Cuáles? (Separados por comas [,])"
                            field="attendedMeetings"
                            disabled={disabled} value={value.organization[index].attendedMeetings}
                            onChange={handleChangeInput}
                            required
                        />
                    </div>
                }
            </div>
            <div className="block">
                <MultipleOptionsControl
                    columnWeight="4"
                    description="Marque los cargos que tienen actualmente en su gabinete local"
                    disabled={disabled} value={value.organization[index].cabinetPosts}
                    field="cabinetPosts"
                    onChange={handleChangeInput}
                >
                    <Option>Presidente</Option>
                    <Option>Secretario</Option>
                    <Option>Tesorero</Option>
                    <Option>Desarrollo</Option>
                    <Option>Testimonio</Option>
                </MultipleOptionsControl>
            </div>
            <div className="block columns">
                <div className="column">
                    <SelectControl
                        label="¿Cuenta con células de personas nuevas en su organización?"
                        placeholder="Seleccionar"
                        options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                        field="newPeopleGroup"
                        disabled={disabled} value={value.organization[index].newPeopleGroup}
                        onChange={handleChangeInput}
                        required
                    />
                </div>
                {   (value.organization[index].newPeopleGroup === "0") &&
                    <div className="column">
                        <TextareaControl
                            placeholder="Si es “no” ¿Por qué no?"
                            field="newPeopleGroupExplanation"
                            disabled={disabled} value={value.organization[index].newPeopleGroupExplanation}
                            onChange={handleChangeInput}
                            required
                        />
                    </div>
                }
                {   (value.organization[index].newPeopleGroup === "1") &&
                    <Fragment>
                        <div className="column">
                            <InputControl
                                type="number"
                                label="¿Cuántas Células?"
                                placeholder="Cantidad"
                                field="numberOfGroups"
                                disabled={disabled} value={value.organization[index].numberOfGroups}
                                onChange={handleChangeInput}
                                required
                            />
                        </div>
                        <div className="column">
                            <InputControl
                                type="number"
                                label="¿Cuántos Asisten?"
                                placeholder="Cantidad"
                                field="numberOfNewPeopleGroup"
                                disabled={disabled} value={value.organization[index].numberOfNewPeopleGroup}
                                onChange={handleChangeInput}
                                required
                            />
                        </div>
                    </Fragment>
                }
            </div>
            <div className="block">
                <InputControl
                    label="Datos del Presidente Local"
                    placeholder="Nombre Completo"
                    type="text"
                    field="presidentName"
                    disabled={disabled} value={value.organization[index].presidentName}
                    onChange={handleChangeInput}
                    required
                />
            </div>
            <div className="block columns">
                <div className="column">
                    <InputControl
                        placeholder="Correo Electrónico"
                        type="email"
                        field="presidentEmail"
                        disabled={disabled} value={value.organization[index].presidentEmail}
                        onChange={handleChangeInput}
                    />
                </div>
                <div className="column">
                    <InputControl
                        placeholder="Teléfono"
                        type="text"
                        field="presidentPhone"
                        disabled={disabled} value={value.organization[index].presidentPhone}
                        onChange={handleChangeInput}
                    />
                </div>
            </div>
        </Fragment>
    );
};