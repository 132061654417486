const useMenuList = () => {
    return [
        {
            title: "Datos del Templo",
            icon: "church",
            url: "/church-form",
            submenu:[],
            users:[
                "1" //local church
            ]
        },
        {
            title:"Formulario",
            icon:"description",
            submenu:[
                {
                    title: "Programa",
                    icon: "manage_history",
                    url: "/form/program"
                },
                {
                    title:"Pastoral",
                    icon: "breastfeeding",
                    url: "/form/shepherds"
                },
                {
                    title: "Desarrollo Cristiano",
                    icon: "auto_stories",
                    url: "/form/christian-growth"
                },
                {
                    title: "Organizaciones",
                    icon: "groups",
                    url: "/form/organizations"
                },
                {
                    title: "Testimonio Cristiano",
                    icon: "share",
                    url: "/form/christian-testimony"
                },
                {
                    title: "Finanzas",
                    icon: "calculate",
                    url: "/form/finance"
                },
                {
                    title: "CONAPAT",
                    icon: "home_work",
                    url: "/form/patrimony"
                }
            ],
            users: [
                "1",// local church
            ]
        },
        {
            title:"Progreso de Formularios",
            icon:"description",
            submenu:[
                {
                    title: "Programa",
                    icon: "manage_history",
                    url: "/form-reports/program"
                },
                {
                    title:"Pastoral",
                    icon: "breastfeeding",
                    url: "/form-reports/shepherds"
                },
                {
                    title: "Desarrollo Cristiano",
                    icon: "auto_stories",
                    url: "/form-reports/christian-growth"
                },
                {
                    title: "Organizaciones",
                    icon: "groups",
                    url: "/form-reports/organizations"
                },
                {
                    title: "Testimonio Cristiano",
                    icon: "share",
                    url: "/form-reports/christian-testimony"
                },
                {
                    title: "Finanzas",
                    icon: "calculate",
                    url: "/form-reports/finance"
                },
                {
                    title: "CONAPAT",
                    icon: "home_work",
                    url: "/form-reports/patrimony"
                }
            ],
            users: [
                "2",// districts
                "3",// conferences
                "4",// national
            ]
        },
        {
            title: "Histórico",
            icon: "history",
            url: "/form-reports/history",
            submenu:[],
            users:[
                "2",// districts
                "3",// conferences
                "4",// national
            ]
        },
        {
            title: "Panorama General",
            icon: "church",
            url: "/form-reports/general",
            submenu:[],
            users:[
                "2",// districts
                "3",// conferences
                "4",// national
            ]
        },
    ];
};

export default useMenuList;