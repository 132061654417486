import { useEffect, useState } from "react";
import { createRoutesFromElements } from "react-router-dom";
import { InputControl, SelectControl } from "../../components/formControls";
import { HeaderTitle } from "../../components/titles";
import useAPI from "../../connection/useAPI";
import { useAuth2 } from "../../functions/auth2Context";
import useLocalStorage from "../../functions/useLocalStorage";
import AppStructure from "../../structures/appStructure";

const ChurchInfo = () => {
    const [ name, setName ] = useLocalStorage("CHURCHDATA_name","");
    const [ nameError, setNameError ] = useState(false);
    const [ conference, setConference ] = useLocalStorage("CHURCHDATA_conference","");
    const [ conferenceError, setConferenceError ] = useState(false);
    const [ district, setDistrict ] = useLocalStorage("CHURCHDATA_district","");
    const [ districtError, setDistrictError ] = useState(false);
    const [ street, setStreet ] = useLocalStorage("CHURCHDATA_street","");
    const [ streetError, setStreetError ] = useState(false);
    const [ intNumber, setIntNumber ] = useLocalStorage("CHURCHDATA_intnumber","");
    const [ intNumberError, setIntNumberError ] = useState(false);
    const [ extNumber, setExtNumber ] = useLocalStorage("CHURCHDATA_extnumber","");
    const [ extNumberError, setExtNumberError ] = useState(false);
    const [ zipCode, setZipCode ] = useLocalStorage("CHURCHDATA_zipcode","");
    const [ zipCodeError, setZipCodeError ] = useState(false);
    const [ suburb, setSuburb ] = useLocalStorage("CHURCHDATA_suburb","");
    const [ suburbError, setSuburbError ] = useState(false);
    const [ city, setCity ] = useLocalStorage("CHURCHDATA_city","");
    const [ cityError, setCityError ] = useState(false);
    const [ state, setState ] = useLocalStorage("CHURCHDATA_state","");
    const [ stateError, setStateError ] = useState(false);
    const [ municipality, setMunicipality ] = useLocalStorage("CHURCHDATA_municipality","");
    const [ municipalityError, setMunicipalityError ] = useState(false);
    const [ locality, setLocality ] = useLocalStorage("CHURCHDATA_locality","");
    const [ localityError, setLocalityError ] = useState(false);
    const [ phone, setPhone ] = useLocalStorage("CHURCHDATA_phone","");
    const [ phoneError, setPhoneError ] = useState(false);
    const [ email, setEmail ] = useLocalStorage("CHURCHDATA_email","");
    const [ emailError, setEmailError ] = useState(false);

    const { catalogs, churchData } = useAPI();
    const { currentUser } = useAuth2();
    const [ conferences, setConferences ] = useState([]);
    const [ dbDistricts, setdbDistricts ] = useState([]);
    const [ districts, setDistricts ] = useState([]);
    const [ dbData, setDbData ] = useState();
    const [ loading, setLoading ] = useState(true);
    const [ sending, setSending ] = useState(false);
    const [ progress, setProgress ] = useState(0);
    const progressAgree = ["name","district","street","intNumber","extNumber","zipCode","suburb","city","state","municipality","locality","phone","email"];
    
    const functions = [setName,setConference,setDistrict,setStreet,setIntNumber,setExtNumber,setZipCode,setSuburb,setCity,setState,setMunicipality,setLocality,setPhone,setEmail];

    useEffect(() => {
        const promises = [
            catalogs.conferences(),
            catalogs.districts(),
            churchData.getData(currentUser.uid)
        ];

        Promise.all(promises).then(values => {
            //Fill Conferences
            const dbConferences = [];
            values[0].forEach((dbConference) => {
                dbConferences.push({value:dbConference.id,label:dbConference.acronym});
            });
            setConferences(dbConferences);

            //Fill Districts
            setdbDistricts(values[1]);
            fillDistricts(values[1]);

            //Fill ChurchData
            fillChurchData(values[2],values[1]);

            fillProgressBar(values[2]);

            setLoading(false);
        });
    },[]);

    useEffect(() => {
        fillDistricts(dbDistricts);
    },[conference]);

    function fillChurchData(churchData,districts){
        setDbData(churchData);

        if(name === "")
            setName(churchData.name);
        else
            if(name !== churchData.name)
                setNameError(true);

        if(conference === ""){
            setConference(churchData.conferenceId);
            fillDistricts(districts,churchData.conferenceId);
        }else
            if(conference !== churchData.conferenceId)
                setConferenceError(true);
        
        if(district === "")
            setDistrict(churchData.districtId);
        else
            if(district !== churchData.districtId)
                setDistrictError(true);
        
        if(street === "")
            setStreet(churchData.street);
        else
            if(street !== churchData.street)
                setStreetError(true);

        if(intNumber === "")
            setIntNumber(churchData.intNumber);
        else
            if(intNumber !== churchData.intNumber)
                setIntNumberError(true);

        if(extNumber === "")
            setExtNumber(churchData.extNumber);
        else
            if(extNumber !== churchData.extNumber)
                setExtNumberError(true);

        if(zipCode === "")
            setZipCode(churchData.zipCode);
        else
            if(zipCode !== churchData.zipCode)
                setZipCodeError(true);

        if(suburb === "")
            setSuburb(churchData.suburb);
        else
            if(suburb !== churchData.suburb)
                setSuburbError(true);

        if(city === "")
            setCity(churchData.city);
        else
            if(city !== churchData.city)
                setCityError(true);

        if(state === "")
            setState(churchData.state);
        else
            if(state !== churchData.state)
                setStateError(true);

        if(municipality === "")
            setMunicipality(churchData.municipality);
        else
            if(municipality !== churchData.municipality)
                setMunicipalityError(true);

        if(locality === "")
            setLocality(churchData.locality);
        else
            if(locality !== churchData.locality)
                setLocalityError(true);

        if(phone === "")
            setPhone(churchData.phone);
        else
            if(phone !== churchData.phone)
                setPhoneError(true);

        if(email === "")
            setEmail(churchData.email);
        else
            if(email !== churchData.email)
                setEmailError(true);
        
    }

    function fillDistricts(districtsParam,conferenceAux = null){
        if(conferenceAux === null)
            conferenceAux = conference;

        if(conferenceAux !== ""){
            const districtsArray = [];
            districtsParam.forEach((dbDistrict) => {
                if(parseInt(dbDistrict.conferenceId) === parseInt(conferenceAux))
                    districtsArray.push({value:dbDistrict.id,label:dbDistrict.name});
            });
            setDistricts(districtsArray);
        }else{
            setDistricts([]);
            setDistrict("");
        }
    }

    function fillProgressBar(fields){
        let numberOfFields = 0;
        let completedFields = 0;
        Object.entries(fields).forEach(([key,value]) => {
            if(progressAgree.includes(key)){
                if(value !== null && value !== "")
                    completedFields++;
                
                numberOfFields++;
            }
        });
        const completed = parseInt((completedFields * 100) / numberOfFields);
        setProgress(completed);
    }

    function handleInputChange(e){
        const key = e.currentTarget.id;
        const value = e.currentTarget.value;

        functions[key](value);
    }

    function handleSubmit(e){
        e.preventDefault();

        setSending(true);

        churchData.save(currentUser.uid,dbData.id,name,district,street,intNumber,extNumber,zipCode,suburb,city,state,municipality,locality,phone,email).then((response) => {
            setDbData(response);
            fillProgressBar(response);
            setSending(false);
        });
    }

    return(
        <AppStructure>
            <HeaderTitle title="Datos Generales del Templo" />
            <div className="box" style={{position:"sticky",top:"0px",zIndex:"99"}}>
                <div className="block">
                    <h2>{progress}% del formulario está completado en el Servidor</h2>
                </div>
                <div className="block">
                    <progress className="progress is-large" value={progress} max="100"></progress>
                </div>
            </div>

            { loading ? <p>Cargando datos...</p> :
                <form onSubmit={handleSubmit}>
                    <div className="box">
                        <div className="block">
                            <InputControl
                                label="Nombre del Templo"
                                iconLeft="church"
                                placeholder="Templo"
                                type="text"
                                id="0"
                                value={name}
                                onChange={handleInputChange}
                                required
                                className={nameError && "is-warning"}
                            />
                        </div>
                        <div className="block columns">
                            <div className="column">
                                <SelectControl
                                    label="Conferencia"
                                    iconLeft="zoom_out_map"
                                    placeholder="Conferencia"
                                    id="1"
                                    value={conference}
                                    onChange={handleInputChange}
                                    options={conferences}
                                    className={conferenceError && "is-warning"}
                                    required
                                />
                            </div>
                            <div className="column">
                                <SelectControl
                                    label="Distrito"
                                    iconLeft="zoom_in_map"
                                    placeholder="Distrito"
                                    id="2"
                                    value={district}
                                    onChange={handleInputChange}
                                    options={districts}
                                    className={districtError && "is-warning"}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div className="block">
                            <div className="field">
                                <h1>Dirección</h1>
                            </div>
                        </div>
                        <div className="block columns">
                            <div className="column">
                                <InputControl
                                    label="Calle"
                                    iconLeft="location_on"
                                    placeholder="Calle"
                                    type="text"
                                    id="3"
                                    value={street}
                                    onChange={handleInputChange}
                                    className={streetError && "is-warning"}
                                    required
                                />
                            </div>
                            <div className="column">
                                <InputControl
                                    label="Número interior"
                                    placeholder="Número interior"
                                    type="text"
                                    id="4"
                                    value={intNumber}
                                    onChange={handleInputChange}
                                    className={intNumberError && "is-warning"}
                                />
                            </div>
                            <div className="column">
                                <InputControl
                                    label="Número exterior"
                                    placeholder="Número exterior"
                                    type="text"
                                    id="5"
                                    value={extNumber}
                                    onChange={handleInputChange}
                                    className={extNumberError && "is-warning"}
                                />
                            </div>
                        </div>
                        <div className="block columns">
                            <div className="column">
                                <InputControl
                                    label="Código postal"
                                    iconLeft="markunread_mailbox"
                                    placeholder="Código postal"
                                    type="text"
                                    id="6"
                                    value={zipCode}
                                    onChange={handleInputChange}
                                    className={zipCodeError && "is-warning"}
                                />
                            </div>
                            <div className="column">
                                <InputControl
                                    label="Colonia"
                                    iconLeft="near_me"
                                    placeholder="Colonia"
                                    type="text"
                                    id="7"
                                    value={suburb}
                                    onChange={handleInputChange}
                                    className={suburbError && "is-warning"}
                                />
                            </div>
                        </div>
                        <div className="block columns">
                            <div className="column">
                                <InputControl
                                    label="Ciudad"
                                    iconLeft="location_city"
                                    placeholder="Ciudad"
                                    type="text"
                                    id="8"
                                    value={city}
                                    onChange={handleInputChange}
                                    className={cityError && "is-warning"}
                                />
                            </div>
                            <div className="column">
                                <InputControl
                                    label="Estado"
                                    iconLeft="location_searching"
                                    placeholder="Estado"
                                    type="text"
                                    id="9"
                                    value={state}
                                    onChange={handleInputChange}
                                    className={stateError && "is-warning"}
                                    required
                                />
                            </div>
                        </div>
                        <div className="block columns">
                            <div className="column">
                                <InputControl
                                    label="Municipio"
                                    iconLeft="map"
                                    placeholder="Municipio"
                                    type="text"
                                    id="10"
                                    value={municipality}
                                    onChange={handleInputChange}
                                    className={municipalityError && "is-warning"}
                                    required
                                />
                            </div>
                            <div className="column">
                                <InputControl
                                    label="Localidad"
                                    iconLeft="push_pin"
                                    placeholder="Localidad"
                                    type="text"
                                    id="11"
                                    value={locality}
                                    onChange={handleInputChange}
                                    className={localityError && "is-warning"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div className="block">
                            <div className="field">
                                <h1>Contacto</h1>
                            </div>
                        </div>
                        <div className="block columns">
                            <div className="column">
                                <InputControl
                                    label="Teléfono (o Celular)"
                                    iconLeft="phone_enabled"
                                    placeholder="Teléfono (o Celular)"
                                    type="text"
                                    id="12"
                                    value={phone}
                                    onChange={handleInputChange}
                                    className={phoneError && "is-warning"}
                                />
                            </div>
                            <div className="column">
                                <InputControl
                                    label="Correo Electrónico"
                                    iconLeft="mail"
                                    placeholder="Correo Electrónico"
                                    type="email"
                                    id="13"
                                    value={email}
                                    onChange={handleInputChange}
                                    className={emailError && "is-warning"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div className="block">
                            <div className="field">
                                <div className="control">
                                    <button type="submit" className="button is-link is-fullwidth" disabled={sending}>
                                        <span className="icon"><i className="material-symbols-rounded">save</i></span>
                                        <span>Guardar</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            }
        </AppStructure>
    );
};

export default ChurchInfo;