import { useRef, useState } from "react";
import { useAuth2 } from "../../functions/auth2Context";
import { useNavigate, Link } from "react-router-dom";
import logo from "../../assets/img/logo_estimmar.png";
import useAPI from "../../connection/useAPI";
import useAppVersion from "../../functions/useAppVersion";

const Login = () => {
    const emailRef = useRef();
    const passwordRef = useRef();
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState("");
    const { setCurrentUser } = useAuth2();
    const navigate = useNavigate();
    const { auth } = useAPI();
    const appVersion = useAppVersion();

    async function handleSubmit(e){
        e.preventDefault();

        setLoading(true);

        auth.login(emailRef.current.value,passwordRef.current.value).then(data => {
            if(!data.result){
                setCurrentUser(data);
                const localAppVersion = localStorage.getItem("APPVERSION");
                if(!localAppVersion)
                    appVersion.save();

                navigate("/");
            }else
                setError(data.result);
            
            setLoading(false);
        });

        /*try{
            setError("");
            setLoading(true);

            await login(emailRef.current.value,passwordRef.current.value);
            navigate("/");
        }catch(err){
            setError("Algo salió mal con tus datos, ¡Intenta de nuevo o recupera tu contraseña!");
            console.log(err);
        }*/
    }

    return(
        <div className="access-page">
            <div className="box">
                <img src={logo} alt="IMMAR online" />
                <div className="block">
                    <div className="notification is-info">
                        Durante el mes de Diciembre del 2022, sólo tendrán acceso las iglesias locales para llenar la encuesta.
                    </div>
                </div>

                <div className="block">
                    { error && <div className="notification is-danger">{error}</div>}
                </div>

                <div className="block">
                    <form onSubmit={handleSubmit}>
                        <div className="field">
                            <input className="input" ref={emailRef} autoComplete="username" type="text" placeholder="Usuario" required />
                        </div>
                        <div className="field">
                            <input className="input" ref={passwordRef} autoComplete="current-password" type="password" placeholder="Contraseña" required />
                        </div>
                        <div className="is-centered">
                            <button className="button is-link" disabled={loading} type="submit">Entrar</button>
                        </div>
                    </form>
                </div>
                
                {/*<div className="block" style={{textAlign:"center"}}>
                    <Link to="/forgot-password">Olvidé mi contraseña</Link>
                    <br />o<br />
                    <Link to="/register">Crear una nueva cuenta</Link>
                </div>*/}
            </div>
        </div>
    );
};

export default Login;