//import { useAuth } from "../functions/authContext";

const useAPI = () => {
    //const { currentUser } = useAuth();
    
    const apps = {
        server: "https://estimmar.iglesia-metodista.org.mx/API/",
        //server: "http://localhost/estIMMAR/",
        auth: {
            app:"?app=auth",
            isLogged: async function(){
                return await fetch(`${apps.server}${apps.auth.app}&function=isLogged`).then(reponse => reponse.json());
            },
            login: async function(user,password){
                return await fetch(`${apps.server}${apps.auth.app}&function=login`,{
                    method: "post",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `user=${user}&password=${password}`
                }).then(reponse => reponse.json());
            },
            logout: async function(){
                return await fetch(`${apps.server}${apps.auth.app}&function=logout`).then(reponse => reponse.json());
            },
            getLevel: async function(uid){
                return await fetch(`${apps.server}${apps.auth.app}&function=getLevel`,{
                    method: "post",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `uid=${uid}`
                }).then(reponse => reponse.json());
            },
            /*hasVoted: async function(campaignId){
                return await fetch(`${apps.server}${apps.votes.app}&function=hasVoted`,{
                    method: "post",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `campaignId=${campaignId}&uid=${currentUser.uid}`
                }).then(reponse => reponse.json());
            },
            registerUser: async function(uid,email,name){
                return await fetch(`${apps.server}${apps.votes.app}&function=registerUser`,{
                    method: "post",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `uid=${uid}&email=${email}&name=${name}`
                }).then(reponse => reponse.json());
            },*/
        },
        catalogs: {
            app:"?app=catalogs",
            conferences: async function(){
                return await fetch(`${apps.server}${apps.catalogs.app}&function=conferences`).then(reponse => reponse.json());
            },
            districts: async function(){
                return await fetch(`${apps.server}${apps.catalogs.app}&function=districts`).then(reponse => reponse.json());
            },
        },
        churchData: {
            app:"?app=churchData",
            getData: async function(uid){
                return await fetch(`${apps.server}${apps.churchData.app}&function=getData`,{
                    method: "post",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `uid=${uid}`
                }).then(reponse => reponse.json());
            },
            save: async function(uid,id,name,district,street,intNumber,extNumber,zipCode,suburb,city,state,municipality,locality,phone,email){
                return await fetch(`${apps.server}${apps.churchData.app}&function=save`,{
                    method: "post",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `uid=${uid}&id=${id}&name=${name}&district=${district}&street=${street}&extNumber=${extNumber}&intNumber=${intNumber}&zipCode=${zipCode}&suburb=${suburb}&city=${city}&state=${state}&municipality=${municipality}&locality=${locality}&phone=${phone}&email=${email}`
                }).then(reponse => reponse.json());
            }
        },
        organizationsData: {
            app:"?app=organizationsData",
            get: async function(churchId){
                return await fetch(`${apps.server}${apps.organizationsData.app}&function=getAll`,{
                    method: "post",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `churchId=${churchId}`
                }).then(reponse => reponse.json());
            },
            saveFHM: async function(uid,churchId,id,data){
                return await fetch(`${apps.server}${apps.organizationsData.app}&function=saveFHM`,{
                    method: "post",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `uid=${uid}&churchId=${churchId}&id=${id}&data=${data}&organizationId=1`
                }).then(reponse => reponse.json());
            },
            saveGM: async function(uid,churchId,id,data){
                return await fetch(`${apps.server}${apps.organizationsData.app}&function=saveGM`,{
                    method: "post",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `uid=${uid}&churchId=${churchId}&id=${id}&data=${data}&organizationId=2`
                }).then(reponse => reponse.json());
            },
            saveLBSC: async function(uid,churchId,id,data){
                return await fetch(`${apps.server}${apps.organizationsData.app}&function=saveLBSC`,{
                    method: "post",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `uid=${uid}&churchId=${churchId}&id=${id}&data=${data}&organizationId=3`
                }).then(reponse => reponse.json());
            },
            saveSMF: async function(uid,churchId,id,data){
                return await fetch(`${apps.server}${apps.organizationsData.app}&function=saveSMF`,{
                    method: "post",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `uid=${uid}&churchId=${churchId}&id=${id}&data=${data}&organizationId=4`
                }).then(reponse => reponse.json());
            },
            saveLMJI: async function(uid,churchId,id,data){
                return await fetch(`${apps.server}${apps.organizationsData.app}&function=saveLMJI`,{
                    method: "post",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `uid=${uid}&churchId=${churchId}&id=${id}&data=${data}&organizationId=5`
                }).then(reponse => reponse.json());
            },
            save: async function(uid,churchId,id,form,data){
                return await fetch(`${apps.server}${apps.organizationsData.app}&function=save`,{
                    method: "post",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `uid=${uid}&churchId=${churchId}&id=${id}&data=${data}&form=${form}`
                }).then(reponse => reponse.json());
            }
        },
        programData: {
            app:"?app=programData",
            get: async function(churchId){
                return await fetch(`${apps.server}${apps.programData.app}&function=get`,{
                    method: "post",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `churchId=${churchId}`
                }).then(reponse => reponse.json());
            },
            save: async function(uid,churchId,id,form,data){
                return await fetch(`${apps.server}${apps.programData.app}&function=save`,{
                    method: "post",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `uid=${uid}&churchId=${churchId}&id=${id}&data=${data}&form=${form}`
                }).then(reponse => reponse.json());
            }
        },
        christianGrowthData: {
            app:"?app=christianGrowthData",
            get: async function(churchId){
                return await fetch(`${apps.server}${apps.christianGrowthData.app}&function=get`,{
                    method: "post",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `churchId=${churchId}`
                }).then(reponse => reponse.json());
            },
            save: async function(uid,churchId,id,form,data){
                return await fetch(`${apps.server}${apps.christianGrowthData.app}&function=save`,{
                    method: "post",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `uid=${uid}&churchId=${churchId}&id=${id}&data=${data}&form=${form}`
                }).then(reponse => reponse.json());
            }
        },
        shepherdsDataForm: {
            app:"?app=shepherdsData",
            get: async function(churchId){
                return await fetch(`${apps.server}${apps.shepherdsDataForm.app}&function=get`,{
                    method: "post",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `churchId=${churchId}`
                }).then(reponse => reponse.json());
            },
            save: async function(uid,churchId,id,form,data){
                return await fetch(`${apps.server}${apps.shepherdsDataForm.app}&function=save`,{
                    method: "post",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `uid=${uid}&churchId=${churchId}&id=${id}&data=${data}&form=${form}`
                }).then(reponse => reponse.json());
            }
        },
        christianTestimonyData: {
            app:"?app=christianTestimonyData",
            get: async function(churchId){
                return await fetch(`${apps.server}${apps.christianTestimonyData.app}&function=get`,{
                    method: "post",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `churchId=${churchId}`
                }).then(reponse => reponse.json());
            },
            save: async function(uid,churchId,id,form,data){
                return await fetch(`${apps.server}${apps.christianTestimonyData.app}&function=save`,{
                    method: "post",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `uid=${uid}&churchId=${churchId}&id=${id}&data=${data}&form=${form}`
                }).then(reponse => reponse.json());
            }
        },
        patrimonyData: {
            app:"?app=patrimonyData",
            get: async function(churchId){
                return await fetch(`${apps.server}${apps.patrimonyData.app}&function=get`,{
                    method: "post",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `churchId=${churchId}`
                }).then(reponse => reponse.json());
            },
            save: async function(uid,churchId,id,form,data){
                return await fetch(`${apps.server}${apps.patrimonyData.app}&function=save`,{
                    method: "post",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `uid=${uid}&churchId=${churchId}&id=${id}&data=${data}&form=${form}`
                }).then(reponse => reponse.json());
            }
        },
        financeData: {
            app:"?app=financeData",
            get: async function(churchId){
                return await fetch(`${apps.server}${apps.financeData.app}&function=get`,{
                    method: "post",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `churchId=${churchId}`
                }).then(reponse => reponse.json());
            },
            save: async function(uid,churchId,id,form,data){
                return await fetch(`${apps.server}${apps.financeData.app}&function=save`,{
                    method: "post",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `uid=${uid}&churchId=${churchId}&id=${id}&data=${data}&form=${form}`
                }).then(reponse => reponse.json());
            }
        },
        fillingReports: {
            app:"?app=fillingReports",
            getDistrictPercentages: async function(uid,tableData){
                return await fetch(`${apps.server}${apps.fillingReports.app}&function=getDistrictPercentages`,{
                    method: "post",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `uid=${uid}&tableData=${tableData}`
                }).then(reponse => reponse.json());
            },
            getConferencePercentages: async function(uid,tableData){
                return await fetch(`${apps.server}${apps.fillingReports.app}&function=getConferencePercentages`,{
                    method: "post",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `uid=${uid}&tableData=${tableData}`
                }).then(reponse => reponse.json());
            },
            getGeneralPercentages: async function(uid,role){
                return await fetch(`${apps.server}${apps.fillingReports.app}&function=getGeneralReport`,{
                    method: "post",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `uid=${uid}&role=${role}`
                }).then(reponse => reponse.json());
            },
            save: async function(uid,churchId,id,form,data){
                return await fetch(`${apps.server}${apps.fillingReports.app}&function=save`,{
                    method: "post",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `uid=${uid}&churchId=${churchId}&id=${id}&data=${data}&form=${form}`
                }).then(reponse => reponse.json());
            }
        },
        organizationsReports: {
            
        }
    };

    return apps;
}

export default useAPI;

