import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { InputControl, SelectControl, TextareaControl } from "../../components/formControls";
import { Icon } from "../../components/icon";
import ProgressBar from "../../components/progressBar";
import { HeaderTitle } from "../../components/titles";
import useAPI from "../../connection/useAPI";
import { useAuth2 } from "../../functions/auth2Context";
import useLocalStorage from "../../functions/useLocalStorage";
import AppStructure from "../../structures/appStructure";

const FinanceForm = () => {
    const [ itemsCompleted, setItemsCompleted ] = useState(0);
    const [ loading, setLoading ] = useState(false);
    const [ formLoading, setFormLoading ] = useState(false);
    const [ financeDataId, setFinanceDataId ] = useState(null);
    const [ inputDisabled, setInputDisabled ] = useState(false);
    const [ presidentData, setPresidentData ] = useLocalStorage("FINANCEFORM_PRESIDENTDATA",{});
    const [ obligationsData, setObligationsData ] = useLocalStorage("FINANCEFORM_OBLIGATIONSDATA",{bankAccounts:[{}]});
    const [ incomeData, setIncomeData ] = useLocalStorage("FINANCEFORM_INCOMEDATA",{});
    const [ expensesData, setExpensesData ] = useLocalStorage("FINANCEFORM_EXPENSESDATA",{});
    const { currentUser } = useAuth2();
    const { financeData } = useAPI();
    const { churchId } = useParams();

    const formObjects = [presidentData,obligationsData,incomeData,expensesData];
    const valueSetters = [setPresidentData,setObligationsData,setIncomeData,setExpensesData];
    const formFields = ["presidentData","obligationsData","incomeData","expensesData"];

    useEffect(() => {
        setLoading(true);

        let cId = currentUser.uid;
        //setInputDisabled(true);
        if(churchId){
            cId = churchId;
            setInputDisabled(true);
        }
        const promises = [financeData.get(cId)];

        Promise.all(promises).then((response) => {
            fillServerData(response[0]);

            setLoading(false);
        });
    },[]);

    function fillServerData(financeObject){
        if(financeObject.id){
            let serverItemsCompleted = 0;

            formFields.forEach((field,fieldIndex) => {
                const fieldObject = JSON.parse(financeObject[field]);

                if(fieldObject){
                    valueSetters[fieldIndex](fieldObject);
                    serverItemsCompleted++;
                }
            });

            setItemsCompleted(serverItemsCompleted);
            setFinanceDataId(financeObject.id);
        }
    }

    function handleChangeField(e){
        const formIndex = e.currentTarget.getAttribute("form-index");
        let fieldValue = e.currentTarget.value;
        if(typeof fieldValue === 'string'){
            fieldValue = fieldValue.replace(/(\r\n|\n|\r|")/gi,'');
        }
        const field = e.currentTarget.getAttribute("field");
        const object = JSON.parse(JSON.stringify(formObjects[formIndex]));
        object[field] = fieldValue;

        valueSetters[formIndex](object);
    }

    function handleSubmit(e){
        e.preventDefault();
        setFormLoading(true);

        const formIndex = e.currentTarget.getAttribute("form-index");

        financeData.save(currentUser.uid,
                        currentUser.uid,
                        financeDataId,
                        formFields[formIndex],
                        JSON.stringify(formObjects[formIndex])
        ).then((response) => {
            fillServerData(response);

            setFormLoading(false);
        });
    }

    function addBankAccounts(e){
        e.preventDefault();

        const obligationsDataObject = JSON.parse(JSON.stringify(obligationsData));
        obligationsDataObject.bankAccounts.push({});

        setObligationsData(obligationsDataObject);
    }

    function totalIncomes(){
        const tithe = incomeData.tithe;
        const offerings = incomeData.offerings;
        const firstFruits = incomeData.firstFruits;
        const otherIncomes = incomeData.otherIncomes;
        let total = 0.00;

        if(tithe) total += parseFloat(tithe);
        if(offerings) total += parseFloat(offerings);
        if(firstFruits) total += parseFloat(firstFruits);
        if(otherIncomes) total += parseFloat(otherIncomes);

        return total;
    }

    function totalExpenses(){
        const salaries = expensesData.salaries;
        const district = expensesData.district;
        const conference = expensesData.conference;
        const otherExpanses = expensesData.otherExpanses;
        let total = 0.00;

        if(salaries) total += parseFloat(salaries);
        if(district) total += parseFloat(district);
        if(conference) total += parseFloat(conference);
        if(otherExpanses) total += parseFloat(otherExpanses);

        return total;
    }

    function numberFormat(number){
        
    }

    return(
        <AppStructure>
            <HeaderTitle title="Formulario de Finanzas" />
            <ProgressBar itemsToEvaluate={4} itemsCompleted={itemsCompleted} />

            {(loading) ? <p>Cargando Datos...</p> :
                <Fragment>
                    <form form-index="0" onSubmit={handleSubmit}>
                        <div className="box">
                            <div className="block">
                                <InputControl
                                    type="text"
                                    label="Presidente local del Área de Finanzas y Administración"
                                    placeholder="Nombre completo"
                                    iconLeft="person"
                                    form-index="0"
                                    field="name"
                                    disabled={inputDisabled} value={presidentData.name}
                                    onChange={handleChangeField}
                                    required
                                />
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <InputControl
                                        type="text"
                                        label="Teléfono (o Celular)"
                                        placeholder="Número de Teléfono"
                                        iconLeft="smartphone"
                                        form-index="0"
                                        field="phone"
                                        disabled={inputDisabled} value={presidentData.phone}
                                        onChange={handleChangeField}
                                    />
                                </div>
                                <div className="column">
                                    <InputControl
                                        type="email"
                                        label="Correo Electrónico"
                                        placeholder="Correo electrónico"
                                        iconLeft="mail"
                                        form-index="0"
                                        field="email"
                                        disabled={inputDisabled} value={presidentData.email}
                                        onChange={handleChangeField}
                                    />
                                </div>
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <InputControl
                                        label="¿Cuántas células de personas nuevas preside este funcionario?"
                                        type="number"
                                        placeholder="Cantidad"
                                        form-index="0"
                                        field="newPeopleGroups"
                                        disabled={inputDisabled} value={presidentData.newPeopleGroups}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                <div className="column">
                                    <InputControl
                                        label="Total de personas que asisten"
                                        type="number"
                                        placeholder="Cantidad"
                                        form-index="0"
                                        field="newPeopleGroupsAttendance"
                                        disabled={inputDisabled} value={presidentData.newPeopleGroupsAttendance}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="block">
                                {!inputDisabled && <button type="submit" className="button is-link is-fullwidth" disabled={formLoading}>
                                    <Icon name="save" />
                                    <span>Guardar</span>
                                </button>}
                            </div>
                        </div>
                    </form>

                    <HeaderTitle subtitle="Obligaciones" />
                    <form form-index="1" onSubmit={handleSubmit}>
                        <div className="box">
                            <div className="block">
                                <label className="label">Numero de empleados que cuentan con contrato laboral y/o honorarios asimilados a salarios (Pastor/es, secretaria, guardatemplo/s, músico/s, etc.)</label>
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        label="Con Contrato ante Conferencia"
                                        placeholder="Cantidad"
                                        form-index="1"
                                        field="conferenceContract"
                                        disabled={inputDisabled} value={obligationsData.conferenceContract}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        label="Sin Contrato"
                                        placeholder="Cantidad"
                                        form-index="1"
                                        field="withoutContract"
                                        disabled={inputDisabled} value={obligationsData.withoutContract}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        label="Otro (Contrato ante iglesia local)"
                                        placeholder="Cantidad"
                                        form-index="1"
                                        field="otherContract"
                                        disabled={inputDisabled} value={obligationsData.otherContract}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <SelectControl
                                        label="¿Su iglesia está al corriente con sus obligaciones y con los acuerdos financieros con el Distrito y la Conferencia?"
                                        placeholder="Seleccionar"
                                        options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                        form-index="1"
                                        field="isUpToDate"
                                        disabled={inputDisabled} value={obligationsData.isUpToDate}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                {(obligationsData.isUpToDate === "1") &&
                                    <div className="column">
                                        <TextareaControl
                                            placeholder="Especifique"
                                            form-index="1"
                                            field="isUpToDateExplain"
                                            disabled={inputDisabled} value={obligationsData.isUpToDateExplain}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                }
                                {(obligationsData.isUpToDate === "0") &&
                                    <div className="column">
                                        <TextareaControl
                                            placeholder="Si no, ¿Por qué?"
                                            form-index="1"
                                            field="isUpToDateExplain"
                                            disabled={inputDisabled} value={obligationsData.isUpToDateExplain}
                                            onChange={handleChangeField}
                                            required
                                        />
                                    </div>
                                }
                            </div>
                            <div className="block">
                                <SelectControl
                                    label="¿Envía su reporte de Ingresos y Egresos a su Conferencia mensualmente?"
                                    placeholder="Seleccionar"
                                    options={[{value:"1",label:"Sí"},{value:"0",label:"No"}]}
                                    form-index="1"
                                    field="monthlyReport"
                                    disabled={inputDisabled} value={obligationsData.monthlyReport}
                                    onChange={handleChangeField}
                                    required
                                />
                            </div>
                            <div className="block">
                                <label className="label">¿Su iglesia tiene cuenta bancaria a nombre de la IMMAR? (si tiene mas de una indicar todas)</label>
                            </div>
                            {obligationsData.bankAccounts.map((account,accountIndex) => {
                                return(
                                    <BankAccountData key={account.clientNumber} disabled={inputDisabled} value={obligationsData} setValue={setObligationsData} index={accountIndex} />
                                );
                            })}
                            <div className="block">
                                {!inputDisabled && <button type="button" className="button is-info" onClick={addBankAccounts}>
                                    <Icon name="add" />
                                    <span>Agregar Cuenta Bancaria</span>
                                </button>}
                            </div>
                            <div className="block">
                                {!inputDisabled && <button type="submit" className="button is-link is-fullwidth" disabled={formLoading}>
                                    <Icon name="save" />
                                    <span>Guardar</span>
                                </button>}
                            </div>
                        </div>
                    </form>

                    <HeaderTitle subtitle="Ingresos" />
                    <form form-index="2" onSubmit={handleSubmit}>
                        <div className="box">
                            <div className="block">
                                <label className="label">Registre de acuerdo al total de Ingresos en pesos del 1 de enero al 31 de diciembre de 2022.</label>
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        iconLeft="attach_money"
                                        label="Total de ingresos por concepto de diezmos"
                                        placeholder="0.00"
                                        step="0.01"
                                        form-index="2"
                                        field="tithe"
                                        disabled={inputDisabled} value={incomeData.tithe}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        iconLeft="attach_money"
                                        label="Total de ingresos por concepto de ofrendas"
                                        placeholder="0.00"
                                        step="0.01"
                                        form-index="2"
                                        field="offerings"
                                        disabled={inputDisabled} value={incomeData.offerings}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        iconLeft="attach_money"
                                        label="Total de ingresos por concepto de Primicias"
                                        placeholder="0.00"
                                        step="0.01"
                                        form-index="2"
                                        field="firstFruits"
                                        disabled={inputDisabled} value={incomeData.firstFruits}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        iconLeft="attach_money"
                                        label="Total de ingresos por otros Ingresos"
                                        placeholder="0.00"
                                        step="0.01"
                                        form-index="2"
                                        field="otherIncomes"
                                        disabled={inputDisabled} value={incomeData.otherIncomes}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="block">
                                <h4 className="title is-4 has-text-centered">Total de Ingresos por todos los conceptos: $ {totalIncomes()}</h4>
                            </div>
                            <div className="block">
                                {!inputDisabled && <button className="button is-link is-fullwidth" type="submit" disabled={formLoading}>
                                    <Icon name="save" />
                                    <span>Guardar</span>
                                </button>}
                            </div>
                        </div>
                    </form>

                    <HeaderTitle subtitle="Egresos" />
                    <form form-index="3" onSubmit={handleSubmit}>
                        <div className="box">
                            <div className="block">
                                <label className="label">Registre los egresos en pesos del 1 de enero al 31 de diciembre de 2022.</label>
                            </div>
                            <div className="block columns">
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        iconLeft="attach_money"
                                        label="Importe por sueldos, prestaciones e impuestos del personal de su Iglesia"
                                        placeholder="0.00"
                                        step="0.01"
                                        form-index="3"
                                        field="salaries"
                                        disabled={inputDisabled} value={expensesData.salaries}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        iconLeft="attach_money"
                                        label="Importe de aportaciones enviadas al Distrito"
                                        placeholder="0.00"
                                        step="0.01"
                                        form-index="3"
                                        field="district"
                                        disabled={inputDisabled} value={expensesData.district}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        iconLeft="attach_money"
                                        label="Importe de aportaciones enviadas a la Conferencia"
                                        placeholder="0.00"
                                        step="0.01"
                                        form-index="3"
                                        field="conference"
                                        disabled={inputDisabled} value={expensesData.conference}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                                <div className="column">
                                    <InputControl
                                        type="number"
                                        iconLeft="attach_money"
                                        label="Importe de otros egresos del 1 de enero al 31 de diciembre de 2022"
                                        placeholder="0.00"
                                        step="0.01"
                                        form-index="3"
                                        field="otherExpanses"
                                        disabled={inputDisabled} value={expensesData.otherExpanses}
                                        onChange={handleChangeField}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="block">
                                <h4 className="title is-4 has-text-centered">Total de Egresos por todos los conceptos: $ {totalExpenses()}</h4>
                            </div>
                            <div className="block">
                                {!inputDisabled && <button className="button is-link is-fullwidth" type="submit" disabled={formLoading}>
                                    <Icon name="save" />
                                    <span>Guardar</span>
                                </button>}
                            </div>
                        </div>
                    </form>
                </Fragment>
            }
        </AppStructure>
    );
};

export default FinanceForm;

const BankAccountData = ({value,setValue,index,disabled}) => {
    function handleChange(e){
        const input = e.currentTarget;
        const field = input.getAttribute("field");
        let internalValue = input.value;
        if(typeof internalValue === 'string'){
            internalValue = internalValue.replace(/(\r\n|\n|\r|")/gi,'');
        }
        const object = JSON.parse(JSON.stringify(value));
        object.bankAccounts[index][field] = internalValue;

        setValue(object);
    }

    return(
        <div className="block columns">
            <div className="column">
                <InputControl
                    type="text"
                    label="En que banco"
                    field="bank"
                    disabled={disabled} value={value.bankAccounts[index].bank}
                    onChange={handleChange}
                    required
                />
            </div>
            <div className="column">
                <InputControl
                    type="text"
                    label="Número de Cliente"
                    field="clientAccount"
                    disabled={disabled} value={value.bankAccounts[index].clientAccount}
                    onChange={handleChange}
                    required
                />
            </div>
        </div>
    );
}

