import { useEffect, useState } from "react";

const ProgressBar = ({itemsToEvaluate,itemsCompleted}) => {
    const [ progress, setProgress ] = useState(0);

    useEffect(() => {
        const evaluation = (itemsCompleted * 100) / itemsToEvaluate;

        setProgress(parseInt(evaluation));
        /*let numberOfItems = 0;
        let completedItems = 0;
        Object.entries(itemsToEvaluate).forEach(([key,value]) => {
            if(value !== null && value !== "")
                completedItems++;
            
            numberOfItems++;
        });
        const completed = parseInt((completedItems * 100) / numberOfItems);
        setProgress(completed);*/
    },[itemsToEvaluate,itemsCompleted]);

    return(
        <div className="box" style={{position:"sticky",top:"0px",zIndex:"99"}}>
            <div className="block">
                <h2>{progress}% del formulario está completado en el Servidor</h2>
            </div>
            <div className="block">
                <progress className="progress is-large" value={progress} max="100"></progress>
            </div>
        </div>
    );
};

export default ProgressBar;