import { useEffect, useState } from "react";
import { Icon } from "../../components/icon";
import { HeaderTitle } from "../../components/titles";
import useAPI from "../../connection/useAPI";
import { useAuth2 } from "../../functions/auth2Context";
import AppStructure from "../../structures/appStructure";

const History = () => {
    const { currentUser } = useAuth2();
    const { auth } = useAPI();
    const [ fileName, setFileName ] = useState("");
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        setLoading(true);

        auth.getLevel(currentUser.uid).then((result) => {
            setFileName(getFileName(result.role,result.id));
        }).finally(() => {
            setLoading(false);
        });
    },[]);

    function getFileName(role,id){
        const conference = ["cam","cancen","cano","cao","cas","case"];
        const idAux = parseInt(id);

        if(role === "3")// Conferencias
            return `general_${conference[idAux-1]}`;
        else if(role === "2"){// Distritos
            if(idAux > 0 && idAux < 4) return `general_${conference[0]}`;
            if(idAux > 3 && idAux < 9) return `general_${conference[1]}`;
            if(idAux > 8 && idAux < 11) return `general_${conference[2]}`;
            if(idAux > 10 && idAux < 23) return `general_${conference[3]}`;
            if(idAux > 22 && idAux < 25) return `general_${conference[4]}`;
            if(idAux > 24 && idAux < 28) return `general_${conference[5]}`;
        }
    }

    return(
        <AppStructure>
            <HeaderTitle title="Histórico de Concentrados" />

            { loading ? <p>Cargando datos...</p> :
                <table className="table">
                    <thead>
                        <tr>
                            <th>Año</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>2020</td>
                            <td>
                                <a
                                    href={`https://estimmar.iglesia-metodista.org.mx/reports/2020/${fileName}_2020.xlsx`}
                                    target="_blank"
                                    className="button is-success"
                                ><Icon name="download" /></a></td>
                        </tr>
                        <tr>
                            <td>2019</td>
                            <td>
                                <a
                                    href={`https://estimmar.iglesia-metodista.org.mx/reports/2019/${fileName}_2019.xlsx`}
                                    target="_blank"
                                    className="button is-success"
                                ><Icon name="download" /></a></td>
                        </tr>
                        <tr>
                            <td>2018</td>
                            <td>
                                <a
                                    href="https://estimmar.iglesia-metodista.org.mx/reports/2018/general_nacional_2018.xlsx"
                                    target="_blank"
                                    className="button is-success"
                                ><Icon name="download" /></a></td>
                        </tr>
                    </tbody>
                </table>
            }
        </AppStructure>
    );
};

export default History;