export const HeaderTitle = ({title, subtitle, description}) => {
    return(
        <section className="hero">
            <div className="hero-body">
                <h1 className="title is-1">{title}</h1>
                { subtitle && <p className="title is-3">{subtitle}</p> }
                { description && <p className="subtitle">{description}</p> }
            </div>
        </section>
    );
};