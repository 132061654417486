const useAppVersion = () => {
    const appVersion = localStorage.getItem("APPVERSION");
    const currentAppVersion = "1.0.5";

    function save(){
        localStorage.setItem("APPVERSION",currentAppVersion);
    }

    function check(){
        if(appVersion === currentAppVersion)
            return true;
        else{
            localStorage.clear();
            return false;
        }
    }

    return({
        save,
        check
    });
};

export default useAppVersion;