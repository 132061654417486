import { Navigate, useLocation } from "react-router-dom";
import { useAuth2 } from "../functions/auth2Context";

const PrivateRouter = ({ children }) => {
    const {currentUser} = useAuth2();
    const location = useLocation();

    if(!currentUser)
        return <Navigate to="/login" state={{from: location}} replace />

    return children;
};

export default PrivateRouter;