import { Fragment, useRef, useState } from "react";
import { Icon } from "./icon";

export const InputControl = ({label,iconLeft,iconRight,className,onChange,...rest}) => {
    let iconLeftClass = "";
    let iconRightClass = "";

    if(iconLeft) iconLeftClass = "has-icons-left";
    if(iconRight) iconRightClass = "has-icons-right";

    function handleChange(e){
        onChange(e);
    }

    return(
        <div className="field">
            <label className="label">{label}</label>
            <div className={`control ${iconLeftClass} ${iconRightClass}`}>
                <input className={`input ${className}`} onChange={handleChange} min="0" {...rest} />

                { iconLeft && (<span className="icon is-small is-left"><i className="material-symbols-rounded">{iconLeft}</i></span>) }
                { iconRight && (<span className="icon is-small is-right"><i className="material-symbols-rounded">{iconRight}</i></span>) }
                
            </div>
        </div>
    );
};

export const SelectControl = ({label,iconLeft,iconRight,placeholder,options=[],className,...rest}) => {
    let iconLeftClass = "";
    let iconRightClass = "";

    if(iconLeft) iconLeftClass = "has-icons-left";
    if(iconRight) iconRightClass = "has-icons-right";

    return(
        <div className="field">
            <label className="label">{label}</label>
            <div className={`control ${iconLeftClass} ${iconRightClass}`}>
                <div className={`select is-fullwidth ${className}`}>
                    <select {...rest}>
                        <option value="">{placeholder}</option>
                        {options.map((option) => {
                            /*let selected = false;
                            if(value === optionKey) selected = true;*/
                            return(<option key={option.value} value={option.value}>{option.label}</option>);
                        })}
                    </select>
                </div>
                { iconLeft && (<span className="icon is-small is-left"><i className="material-symbols-rounded">{iconLeft}</i></span>) }
                { iconRight && (<span className="icon is-small is-right"><i className="material-symbols-rounded">{iconRight}</i></span>) }
            </div>
        </div>
    );
};

export const TextareaControl = ({label,iconLeft,iconRight,className,...rest}) => {
    let iconLeftClass = "";
    let iconRightClass = "";

    if(iconLeft) iconLeftClass = "has-icons-left";
    if(iconRight) iconRightClass = "has-icons-right";

    return(
        <div className="field">
            <label className="label">{label}</label>
            <div className={`control ${iconLeftClass} ${iconRightClass}`}>
                <textarea className={`textarea ${className}`} {...rest}></textarea>

                { iconLeft && (<span className="icon is-small is-left"><i className="material-symbols-rounded">{iconLeft}</i></span>) }
                { iconRight && (<span className="icon is-small is-right"><i className="material-symbols-rounded">{iconRight}</i></span>) }
                
            </div>
        </div>
    );
};

export const FileUploader = ({description}) => {
    return(
        <div class="file is-boxed is-fullwidth has-text-centered is-info">
            <label class="file-label">
                <input class="file-input" type="file" name="resume" />
                <span class="file-cta">
                    <span class="file-icon">
                        <Icon name="upload" />
                    </span>
                    <span class="file-label">
                        {description}
                    </span>
                </span>
            </label>
        </div>
    );
};

export const CheckboxControl = ({description,disabled,...rest}) => {
    function handleChange(e){
        console.log(e.currentTarget.value);
    }
    return(
        <div className="field">
            <label class="checkbox" disabled={disabled}>
                <input type="checkbox" disabled={disabled} onChange={handleChange} {...rest} />
                <span>&nbsp;{description}</span>
            </label>
        </div>
    );
};

export const RadioControl = ({description,disabled,...rest}) => {
    return(
        <div className="control">
            <label class="radio" disabled={disabled}>
                <input type="radio" disabled={disabled} {...rest} />
                <span>&nbsp;{description}</span>
            </label>
        </div>
    );
};

export const MultipleOptionsControl = (props) => {
    const {id,children,description,columnWeight,value,onChange} = props;
    const syntheticEvent = {id,currentTarget:{value:value,getAttribute: function(attribute){
        return props[attribute];
    }}};

    function handleClick(e){
        const optionIndex = e.currentTarget.getAttribute("index");
        const options = JSON.parse(JSON.stringify(value));
        const option = options[optionIndex];
        if(!option) options[optionIndex] = true;
        else options[optionIndex] = false;
        
        syntheticEvent.currentTarget.value = options;
        onChange(syntheticEvent);
    }

    return(
        <Fragment>
            <div className="block">
                <div className="control">
                    <label className="label">{description}</label>
                </div>
            </div>
            <div className="block columns is-multiline">
                {children.map((item,itemIndex) => {
                    return(
                        <div key={item.props.children} className={`column is-${columnWeight}`}>
                            <MultipleOptionsOption selected={value[itemIndex]} index={itemIndex} onClick={handleClick}>{item.props.children}</MultipleOptionsOption>
                        </div>
                    );
                })}
            </div>
        </Fragment>
    );
};

const MultipleOptionsOption = ({children,selected,...rest}) => {
    return(
        <button className={"button is-fullwidth "+(selected && "is-info ")} type="button" option-index={0} {...rest}>
            {selected && <Icon name="check" />}
            <span>{children}</span>
        </button>
    );
};

export const Option = (props) => {
    return props;
}